import styles from "./index.module.css";

const AcademicPerformanceTable = (props) => {
  const { year } = props;

  return (
    <form className="p-2 pr-0 flex flex-col ">
      <table className={styles["academic-performance-table"]}>
        <thead>
          <tr>
            <th>Academic Performance</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
            <th>CAYm3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              X=&nbsp;&nbsp; (Mean of {year} year grade point average of all
              successful
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; students on a
              10-point scale) or (Mean of the
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; percentage of
              marks of all successful students in {year} <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; year/10)
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
          </tr>

          <tr>
            <td>Y=&nbsp; Total no. of successful students</td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
          </tr>

          <tr>
            <td>Z=&nbsp; Total no. of students appeared in the examination</td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
          </tr>

          <tr>
            <td>API = X* (Y/Z)</td>
            <td className={styles["input-col"]}>AP1</td>
            <td className={styles["input-col"]}>AP2</td>
            <td className={styles["input-col"]}>AP3</td>
          </tr>

          <tr>
            <td>Average API = (AP1 + AP2 + AP3)/3</td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
            <td className={styles["input-col"]}>
              <input required />
            </td>
          </tr>
        </tbody>
      </table>
      <button type='submit' className={styles['submit-btn']}  >
        Submit
      </button>
    </form>
  );
};

export default AcademicPerformanceTable;
