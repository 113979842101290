import "./index.css";
import { Link, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import * as jose from "jose";

const ConnectNavBar = (props) => {
  const { match, history } = props;
  const { path } = match;

  const [popActive, updatePop] = useState(false);

  const popBtn = () => {
    updatePop(!popActive);
  };

  const [claims, updateClaims] = useState({});

  useEffect(() => {
    const token = Cookies.get("jwt_token");

    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateClaims(claim);
    }
  }, []);

  const logoutClicked = () => {
    const { history } = props;

    Cookies.remove("jwt_token");
    history.replace("/login");
  };

  document.addEventListener("click", (e) => {
    if (e.target.id === "box") {
      if (popActive === false) {
        updatePop(true);
      }
    } else {
      updatePop(false);
    }
  });

  return (
    <nav className="nav-bar flex flex-row justify-between items-center">
      <span className="flex flex-row items-center">
        <ul className="tab-bar flex flex-row justify-around items-center bg-[#ECF0FF] list-style-none">
          <li
            className={`hover:text-[#3f4451] ${
              path.startsWith("/connect/dashboard")
                ? "text-[#3D65F4] font-black"
                : "text-[#606981]"
            }`}
          >
            <Link to="/connect/dashboard">Dashboard</Link>
          </li>
          <li
            className={`hover:text-[#3f4451] ${
              path.startsWith("/connect/kanban")
                ? "text-[#3D65F4] font-black"
                : "text-[#606981]"
            }`}
          >
            <Link to="/connect/kanban">Kanban</Link>
          </li>

          <li
            className={`hover:text-[#3f4451] ${
              path.startsWith("/connect/info")
                ? "text-[#3D65F4] font-black"
                : "text-[#606981]"
            }`}
          >
            <Link to="/connect/info">Info</Link>
          </li>
        </ul>

        <div className="arrows-container flex flex-row justify-between items-center ml-10">
          <div
            onClick={() => history.goBack()}
            className="arrow-bg cursor-pointer"
          >
            <img
              src="/assets/leftarrow.svg"
              width={9}
              height={9}
              alt="leftarrow"
            />
          </div>
          <div
            onClick={() => history.goForward()}
            className="arrow-bg cursor-pointer"
          >
            <img
              src="/assets/rightarrow.svg"
              width={9}
              height={9}
              alt="rightarrow"
            />
          </div>
        </div>
      </span>

      <span className="flex flex-row items-center">
        {/* <form className={styles['input-el-container']}>
          <Image
            src="/homepage/search.svg"
            width={15}
            height={15}
            alt="search"
          />
          <input
            placeholder="Search for students, teachers, etc."
            className={`${styles['input-el']} w-full h-full focus:ring-0`}
            type="search"
          />
        </form> */}

        <div className="ml-5">
          <span
            className="cursor-not-allowed 
          text-gray-500
          hover:text-gray-700
          focus:text-gray-700
          mr-4 md:mr-8
          dropdown-toggle
          hidden-arrow
          flex items-center
        "
            id="dropdownMenuButton1"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="bell flex flex-col items-center">
              <img
                src="/assets/notification.svg"
                width={20}
                height={20}
                alt="notifications"
              />
              <img
                src="/assets/bellbottom.svg"
                width={8}
                height={8}
                alt="notifications"
              />
            </span>
            <span className="count flex items-center text-white bg-orange-700 absolute border-2 border-white mb-2 ml-4 px-1 justify-center">
              0
            </span>
          </span>
        </div>

        <div className="profile ml-3 cursor-pointer" onClick={popBtn}>
          <img
            id="box"
            src="/assets/defaultImage.svg"
            className={`rounded-full`}
            width={45}
            height={45}
            alt="profile"
          />
          {popActive && (
            <span className="dropdown">
              <ul className="flex flex-col p-5 list-none">
                <Link to="/obe/account">
                  <li className="li-items">
                    <img
                      src="/assets/myAccount.svg"
                      width={18}
                      height={18}
                      alt="Account"
                    />
                    <span className="li-text">My Account</span>
                  </li>
                </Link>
                {claims["user_type"] === "PRINCIPAL" && (
                  <>
                    <Link to="/obe/hods">
                      <li className="li-items">
                        <img
                          src="/assets/hods.svg"
                          width={18}
                          height={18}
                          alt="Hods"
                        />
                        <span className="li-text">Manage HODs</span>
                      </li>
                    </Link>
                  </>
                )}
                <Link to="/obe/campus-setup">
                  <li className="li-items">
                    <img
                      src="/assets/campusSetup.svg"
                      width={18}
                      height={18}
                      alt="Campus Setup"
                    />
                    <span className="li-text">Manage Campus Setup</span>
                  </li>
                </Link>
              </ul>
              <button onClick={logoutClicked} className="logout-btn ml-6">
                Logout
              </button>
            </span>
          )}
        </div>
      </span>
    </nav>
  );
};

export default withRouter(ConnectNavBar);
