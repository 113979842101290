import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import ForgotLink from "./components/ForgotLink";
import ForgotPassword from "./components/ForgotPassword";
import DashboardPage from "../src/components/ObeModule/DashboardPage";
import CoursesPage from "./components/ObeModule/CoursesPage";
import FacultyPage from "../src/components/ObeModule/FacultyPage";
import MyAccount from "../src/components/CampusSetup/MyAccount";
import FacultyMyAccount from "../src/components/CampusSetup/FacultyMyAccount";
import ManageCampusSetup from "../src/components/CampusSetup/ManageCampusSetup";
import ManageHods from "../src/components/CampusSetup/ManageHods";
import FacultyDashboardPage from "./components/FacultyModule/FacultyDashboardPage";
import ProtectedRoute from "./components/ProtectedRoute";
import Pdf from "./components/Pdf";
import Feedback from "./components/Feedback";
import FacultyLearnPage from "./components/FacultyModule/FacultyLearnPage";
import "./App.css";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import apiClient from "./utils/apiUrls/apiClient";
import { useEffect, useState } from "react";
import OtpContext from "./context/OtpContext";
import SearchContext from "./context/SearchContext";
import FiltersContext from "./context/FiltersContext";
import ModifyWeightages from "./components/CampusSetup/ModifyWeightages";
import BulkMigration from "./components/CampusSetup/BulkMigration";
import StudentsPage from "./components/ObeModule/StudentsPage";
import Dashboard from "./components/ConnectModule/DashboardPage/Dashboard";
import DashboardCriteria from "./components/ConnectModule/DashboardPage/DashboardCriteria";
import DashboardMetrics from "./components/ConnectModule/DashboardPage/DashboardMetrics";
import SmallerDevice from "./components/SmallerDevice";
import DashboardMetricsData from "./components/ConnectModule/DashboardPage/DashboardMetricsData";
import StudentProfile from "./components/ObeModule/StudentsPage/StudentProfile";

const App = () => {
  const [context, updateContext] = useState("");
  const [header, updateHeader] = useState("");
  const [search, updateSearch] = useState("");
  const [filtersBatch, updateFiltersBatch] = useState([]);
  const [filtersBranch, updateFiltersBranch] = useState([]);
  const [filtersSem, updateFiltersSem] = useState([]);
  const [content, setContent] = useState(true);

  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateFiltersBatch(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateFiltersBranch(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateFiltersSem(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getFilters = async () => {
    if (!isLoginPage) {
      await getBatchOptions();
      await getBranchOptions();
      await getSemOptions();
    }

    setContent(false);
  };

  useEffect(() => {
    setContent(true);
    getFilters();
  }, [isLoginPage]);

  return content ? (
    <div className="flex justify-center items-center h-screen">
      <Oval
        height={80}
        width={80}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : window.innerWidth >= 1270 ? (
    <FiltersContext.Provider
      value={{
        filtersBatch,
        filtersBranch,
        filtersSem,
        updateFiltersBatch,
        updateFiltersBranch,
        updateFiltersSem,
      }}
    >
      <OtpContext.Provider
        value={{
          verified: context,
          updateVerified: updateContext,
          header: header,
          headerChange: updateHeader,
        }}
      >
        <SearchContext.Provider
          value={{
            search,
            updateSearch,
          }}
        >
          <Switch>
            <Route exact path="/login" component={LoginForm} />
            <Route exact path="/login/forgot-password" component={ForgotLink} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <ProtectedRoute exact path="/feedback" component={Feedback} />
            <ProtectedRoute
              exact
              path="/obe/dashboard"
              component={DashboardPage}
            />

            <ProtectedRoute
              exact
              path="/obe/courses/analysis"
              component={CoursesPage}
            />

            <ProtectedRoute
              exact
              path="/obe/courses/attainments"
              component={CoursesPage}
            />

            <ProtectedRoute
              exact
              path="/obe/courses/attainments/:courseId"
              component={CoursesPage}
            />
            <ProtectedRoute
              exact
              path="/obe/courses/managecourses"
              component={CoursesPage}
            />
            <ProtectedRoute exact path="/obe/faculty" component={FacultyPage} />
            <ProtectedRoute
              exact
              path="/obe/faculty/analysis"
              component={FacultyPage}
            />
            <ProtectedRoute
              exact
              path="/obe/faculty/faculty-course"
              component={FacultyPage}
            />

            <ProtectedRoute exact path="/obe/account" component={MyAccount} />
            <ProtectedRoute
              exact
              path="/obe/campus-setup"
              component={ManageCampusSetup}
            />
            <ProtectedRoute exact path="/obe/hods" component={ManageHods} />
            <ProtectedRoute
              exact
              path="/obe/modify-weightages"
              component={ModifyWeightages}
            />
            <ProtectedRoute
              exact
              path="/obe/bulk-migration"
              component={BulkMigration}
            />

            <ProtectedRoute
              exact
              path="/faculty/dashboard/managecourses"
              component={FacultyDashboardPage}
            />
            <ProtectedRoute
              exact
              path="/faculty/dashboard/analysis"
              component={FacultyDashboardPage}
            />

            <ProtectedRoute
              exact
              path="/faculty/dashboard/attainments"
              component={FacultyDashboardPage}
            />

            <ProtectedRoute
              exact
              path="/faculty/dashboard/attainments/:courseId"
              component={FacultyDashboardPage}
            />
            <ProtectedRoute
              exact
              path="/faculty/learn/mycourses"
              component={FacultyLearnPage}
            />
            <ProtectedRoute
              exact
              path="/faculty/account"
              component={FacultyMyAccount}
            />

            <ProtectedRoute
              exact
              path="/obe/students/performance-analysis"
              component={StudentsPage}
            />
            <ProtectedRoute
              exact
              path="/obe/students/student-profile/:stdId"
              component={StudentProfile}
            />
            <Route
              exact
              path="/obe/courses/managecourses/pdf/:id/:batch/:branch/:semester"
              component={Pdf}
            />
            <ProtectedRoute
              exact
              path="/connect/dashboard"
              component={Dashboard}
            />
            <ProtectedRoute
              exact
              path="/connect/dashboard/criteria/:projectId"
              component={DashboardCriteria}
            />

            <ProtectedRoute
              exact
              path="/connect/dashboard/criteria/metrics/:projectId/:criteriaNo"
              component={DashboardMetrics}
            />

            <ProtectedRoute
              exact
              path="/connect/dashboard/metrics-data/:projectId/:metricsId"
              component={DashboardMetricsData}
            />
            <Redirect to="/obe/courses/managecourses" />
          </Switch>
        </SearchContext.Provider>
      </OtpContext.Provider>
    </FiltersContext.Provider>
  ) : (
    <SmallerDevice />
  );
};

export default App;
