import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import styles from "../index.module.css";

const overlayStyle = { background: "rgba(0,0,0,0.5)" };

const MarkSheetScreen = (props) => {
  const [status, updateStatus] = useState([]);
  const [popUp, updatePopup] = useState(false);

  const { data, updateTab, listName, sec, updateSec, updateExamType } = props;

  const getStatus = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_CHECK_STATUS_POPUP +
          `?courseId=${data?.["courseId"]}&courseStructId=${data?.["courseStructId"]}&sectionList=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        const listNameData = {};
        response["data"]["result"].map(
          (each) =>
            (listNameData[each["templateName"]] =
              each["templateUploadStatus"] === "Y" ? true : false)
        );
        updateStatus(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const secChanged = (event) => {
    updateSec(event.target.value);
  };

  const renderLiView = () => {
    try {
      return listName.map((each) => (
        <li className={styles["li-item"]} key={each}>
          <span className="flex items-start">
            <span className="mr-20">
              <label className={styles.label}>Assessment Type</label>
              <p className={styles["temp-name"]}>{each}</p>
            </span>
          </span>

          <div className="flex items-center">
            <span className="mt-2 mr-1 flex flex-col justify-start items-start">
              <div className={styles["container"]}>
                <div className={styles["button-wrap"]}>
                  <button
                    id={each}
                    onClick={excelBtn}
                    type="button"
                    className={styles["button"]}
                  >
                    {sec !== "" &&
                    status.length > 0 &&
                    status.filter(
                      (i) => i.templateName === each && i.sectionName === sec
                    )[0]?.["templateUploadStatus"] === "Y"
                      ? "RE-ENTER MARKS"
                      : "ENTER MARKS"}
                  </button>

                  <p className={`${styles["file"]} flex items-center`}>
                    {sec !== "" &&
                    status.length > 0 &&
                    status.filter(
                      (i) => i.templateName === each && i.sectionName === sec
                    )[0]?.["templateUploadStatus"] === "Y" ? (
                      <span className="ml-1 flex items-center">
                        <img
                          className="mr-2"
                          src="/assets/green-tick.svg"
                          alt="Uploaded"
                          width={22}
                          height={22}
                        />
                        Marks Entered
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </li>
      ));
    } catch (err) {
      console.log(err);
    }
  };

  const renderSectionOptions = () => {
    const ans = [];

    try {
      let secOptions = [];

      secOptions = data["sectionsForCourse"].split(",");

      for (const item of secOptions) {
        ans.push(
          <option key={item} value={item.trim()}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    updatePopup(false);
  };

  const renderPopup = () => (
    <div className={`${styles["sample-excel-modal"]}`}>
      <img src="/assets/sampleQPExcel.png" alt="sample excel" />
    </div>
  );

  const excelBtn = (event) => {
    if (sec === "") {
      alert("Select the section");
    } else {
      updateExamType(event.target.id);
      updateTab("marksSheetManual");
    }
  };

  const sampleExcelBtn = () => {
    updatePopup(true);
  };

  useEffect(() => {
    data?.courseId !== undefined && getStatus();
  }, [data]);

  return (
    <>
      <span className="flex flex-row items-center mt-5 mb-5 ml-10">
        <label className={styles["select-sec-label"]}>Select Section</label>
        <select
          value={sec}
          onChange={secChanged}
          className={`${styles["filter-select-sec"]} focus:ring-0 p-2`}
        >
          <option selected value="">
            Select
          </option>
          {renderSectionOptions()}
        </select>
      </span>
      <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pt-0 pb-10 h-4/6 overflow-y-auto mt-4">
        <ul className="list-none">{renderLiView()}</ul>
      </div>

      <span className="flex flex-row justify-end items-end mt-5 mb-0 fixed right-3 bottom-1">
        <span className="flex flex-row justify-end items-end mt-5 mb-5">
          <div className={styles["container"]}>
            <Popup open={popUp} onClose={closeModal} {...{ overlayStyle }}>
              {renderPopup()}
            </Popup>
            <button
              onClick={sampleExcelBtn}
              className={styles["sample-button"]}
            >
              Sample Excel
            </button>
          </div>
        </span>
      </span>
    </>
  );
};

export default MarkSheetScreen;
