import { Component } from "react";
import ExternalProjectType from "./ExternalProjectType";
import InternalProjectType from "./InternalProjectType";
import InternalWeightages from "./InternalWeightages";
import ExternalWeightages from "./ExternalWeightages";
import styles from "./index.module.css";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";

const countInternal = {
  theory: 0,
  practical: 0,
  project: 0,
};

const countExternal = {
  theory: 0,
  practical: 0,
  project: 0,
};

const initialData = {
  typeName: "",
  att: false,
};

class NewCourseStructure extends Component {
  state = {
    data: initialData,
    typeCountInternal: countInternal,
    typeCountExternal: countExternal,
    created: false,
    radioBtn: "theoretical",
    extProjLongList: {},
    intProjLongList: {},
    extPractLongList: {},
    intPractLongList: {},
    extTheoryLongList: {},
    intTheoryLongList: {},
    extProjShortList: {},
    intProjShortList: {},
    extPractShortList: {},
    intPractShortList: {},
    extTheoryShortList: {},
    intTheoryShortList: {},
    intWeights: {},
    isLoading: false,
  };

  componentDidMount() {
    try {
      const { edit } = this.props;
      if (edit !== null) {
        let type;
        this.getTemplates(edit["courseStructId"]);

        switch (edit["courseType"]) {
          // case "both(theoretical/practical)":
          //   type = "both";
          //   break;
          case "project/others":
            type = "project";
            break;
          default:
            type = edit["courseType"];
            break;
        }

        const sample = [
          "numberOfIntTheoryTypes",
          "numberOfIntProjTypes",
          "numberOfIntPractTypes",
          "numberOfExtTheoryTypes",
          "numberOfExtProjTypes",
          "numberOfExtPractTypes",
        ];

        let its = {};
        let itl = {};
        let ips = {};
        let ipl = {};
        let ipjs = {};
        let ipjl = {};
        let ets = {};
        let etl = {};
        let eps = {};
        let epl = {};
        let epjs = {};
        let epjl = {};

        for (const each of sample) {
          if (edit[each] > 0) {
            for (let count = 1; count <= edit[each]; count++) {
              switch (each) {
                case "numberOfIntTheoryTypes":
                  if (edit["courseHaveAtt"] === "Y") {
                    if (edit[each] > 1) {
                      if (count < edit[each]) {
                        its = {
                          ...its,
                          [`${count}`]:
                            edit["intTheoryShortList"].split(",")[count - 1],
                        };
                        itl = {
                          ...itl,
                          [`${count}`]:
                            edit["intTheoryLongList"].split(",")[count - 1],
                        };
                      }
                    }
                  } else {
                    its = {
                      ...its,
                      [`${count}`]:
                        edit["intTheoryShortList"].split(",")[count - 1],
                    };
                    itl = {
                      ...itl,
                      [`${count}`]:
                        edit["intTheoryLongList"].split(",")[count - 1],
                    };
                  }
                  break;
                case "numberOfIntProjTypes":
                  ipjs = {
                    ...ipjs,
                    [`${count}`]:
                      edit["intProjShortList"].split(",")[count - 1],
                  };
                  ipjl = {
                    ...ipjl,
                    [`${count}`]: edit["intProjLongList"].split(",")[count - 1],
                  };
                  break;
                case "numberOfIntPractTypes":
                  ips = {
                    ...ips,
                    [`${count}`]:
                      edit["intPractShortList"].split(",")[count - 1],
                  };
                  ipl = {
                    ...ipl,
                    [`${count}`]:
                      edit["intPractLongList"].split(",")[count - 1],
                  };
                  break;
                case "numberOfExtTheoryTypes":
                  ets = {
                    ...ets,
                    [`${count}`]:
                      edit["extTheoryShortList"].split(",")[count - 1],
                  };
                  etl = {
                    ...etl,
                    [`${count}`]:
                      edit["extTheoryLongList"].split(",")[count - 1],
                  };
                  break;
                case "numberOfExtProjTypes":
                  epjs = {
                    ...epjs,
                    [`${count}`]:
                      edit["extProjShortList"].split(",")[count - 1],
                  };
                  epjl = {
                    ...epjl,
                    [`${count}`]: edit["extProjLongList"].split(",")[count - 1],
                  };
                  break;
                case "numberOfExtPractTypes":
                  eps = {
                    ...eps,
                    [`${count}`]:
                      edit["extPractShortList"].split(",")[count - 1],
                  };
                  epl = {
                    ...epl,
                    [`${count}`]:
                      edit["extPractLongList"].split(",")[count - 1],
                  };
                  break;

                default:
                  break;
              }
            }
          }
        }

        const updatedData = {
          data: {
            typeName: edit["courseTypeName"],
            att: edit["courseHaveAtt"] === "Y" ? true : false,
          },

          typeCountInternal: {
            theory:
              edit["courseHaveAtt"] === "Y"
                ? edit["numberOfIntTheoryTypes"] - 1
                : edit["numberOfIntTheoryTypes"],
            practical: edit["numberOfIntPractTypes"],
            project: edit["numberOfIntProjTypes"],
          },

          typeCountExternal: {
            theory: edit["numberOfExtTheoryTypes"],
            practical: edit["numberOfExtPractTypes"],
            project: edit["numberOfExtProjTypes"],
          },
          radioBtn: type,
          extProjLongList: epjl,
          intProjLongList: ipjl,
          extPractLongList: epl,
          intPractLongList: ipl,
          extTheoryLongList: etl,
          intTheoryLongList: itl,
          extProjShortList: epjs,
          intProjShortList: ipjs,
          extPractShortList: eps,
          intPractShortList: ips,
          extTheoryShortList: ets,
          intTheoryShortList: its,
        };

        this.setState({ ...updatedData });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getTemplates = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_GET_WEIGHTS_TEMPLATE +
          `?courseStructId=${id}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        let data = {};
        response["data"]["result"].forEach((each) => {
          let tempNameWeigh =
            each["assmntType"].slice(0, 3) + each["templateName"] + "Weight";
          let tempNameTh =
            each["assmntType"].slice(0, 3) + each["templateName"] + "ThValue";

          data[tempNameWeigh] = each["templateWeightage"];
          data[tempNameTh] = each["thValue"];
        });

        this.setState((prev) => ({
          intWeights: { ...prev.intWeights, ...data },
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  weightsCheck = () => {
    try {
      const {
        data,
        typeCountInternal,
        typeCountExternal,
        intTheoryShortList,
        intPractShortList,
        intProjShortList,
        extTheoryShortList,
        extProjShortList,
        extPractShortList,
      } = this.state;

      const intAns = [];
      const extAns = [];

      Object.keys(intTheoryShortList).map((each) =>
        intAns.push(intTheoryShortList[each])
      );
      Object.keys(intPractShortList).map((each) =>
        intAns.push(intPractShortList[each])
      );
      Object.keys(intProjShortList).map((each) =>
        intAns.push(intProjShortList[each])
      );

      data.att && intAns.push("AAT");

      Object.keys(extTheoryShortList).map((each) =>
        extAns.push(extTheoryShortList[each])
      );
      Object.keys(extPractShortList).map((each) =>
        extAns.push(extPractShortList[each])
      );
      Object.keys(extProjShortList).map((each) =>
        extAns.push(extProjShortList[each])
      );

      let intWeight = 0;

      let extWeight = 0;

      intAns.forEach((each) => {
        let weight = document.getElementById(`int${each}Weight`).value;
        intWeight += parseFloat(weight);
      });

      extAns.forEach((each) => {
        let weight = document.getElementById(`ext${each}Weight`).value;
        extWeight += parseFloat(weight);
      });

      const internalCount =
        typeCountInternal.theory +
        typeCountInternal.practical +
        typeCountInternal.project;

      const externalCount =
        typeCountExternal.theory +
        typeCountExternal.practical +
        typeCountExternal.project;

      if (internalCount > 0 || data.att) {
        if (intWeight !== 100) {
          alert("Sum of all the Internal Weightages should be Equal to 100");
          return false;
        }
      }

      if (externalCount > 0) {
        if (extWeight !== 100) {
          alert("Sum of all the External Weightages should be Equal to 100");
          return false;
        }
      }

      return true;
    } catch (err) {
      console.log(err);
    }
  };

  addStructure = async (event) => {
    event.preventDefault();
    try {
      if (this.weightsCheck()) {
        this.setState({ isLoading: true });

        const { edit } = this.props;

        const token = Cookies.get("jwt_token");

        const {
          data,
          typeCountInternal,
          typeCountExternal,
          radioBtn,
          extProjLongList,
          intProjLongList,
          extPractLongList,
          intPractLongList,
          extTheoryLongList,
          intTheoryLongList,
          extProjShortList,
          intProjShortList,
          extPractShortList,
          intPractShortList,
          extTheoryShortList,
          intTheoryShortList,
        } = this.state;

        let typeData;

        switch (radioBtn) {
          // case "both":
          //   typeData = "both(theoretical/practical)";

          //   break;
          case "project":
            typeData = "project/others";
            break;
          default:
            typeData = radioBtn;
            break;
        }

        const internalCount =
          typeCountInternal.theory +
          typeCountInternal.practical +
          typeCountInternal.project;

        const externalCount =
          typeCountExternal.theory +
          typeCountExternal.practical +
          typeCountExternal.project;

        const reqData = {
          courseHaveAtt: data.att ? "Y" : "N",
          courseHaveExt: externalCount > 0 ? "Y" : "N",
          courseHaveInternal: internalCount > 0 ? "Y" : "N",
          courseHaveRubrics:
            radioBtn === "project" || radioBtn === "epic" ? "Y" : "N",
          courseStructId:
            edit !== null
              ? edit["courseStructId"]
              : Math.ceil(Math.random() * 100),
          courseType: typeData,
          courseTypeName: data.typeName,
          numberOfExtPractTypes: typeCountExternal.practical,
          numberOfExtProjTypes: typeCountExternal.project,
          numberOfExtTheoryTypes: typeCountExternal.theory,
          numberOfIntPractTypes: typeCountInternal.practical,
          numberOfIntProjTypes: typeCountInternal.project,
          numberOfIntTheoryTypes: data.att
            ? parseInt(typeCountInternal.theory) + 1
            : typeCountInternal.theory,

          extPractLongList: Object.keys(extPractLongList)
            .map((each) => extPractLongList[each])
            .join(),

          extPractShortList: Object.keys(extPractShortList)
            .map((each) => extPractShortList[each])
            .join(),

          extProjLongList: Object.keys(extProjLongList)
            .map((each) => extProjLongList[each])
            .join(),

          extProjShortList: Object.keys(extProjShortList)
            .map((each) => extProjShortList[each])
            .join(),

          extTheoryLongList: Object.keys(extTheoryLongList)
            .map((each) => extTheoryLongList[each])
            .join(),

          extTheoryShortList: Object.keys(extTheoryShortList)
            .map((each) => extTheoryShortList[each])
            .join(),

          intPractLongList: Object.keys(intPractLongList)
            .map((each) => intPractLongList[each])
            .join(),

          intPractShortList: Object.keys(intPractShortList)
            .map((each) => intPractShortList[each])
            .join(),

          intProjLongList: Object.keys(intProjLongList)
            .map((each) => intProjLongList[each])
            .join(),

          intProjShortList: Object.keys(intProjShortList)
            .map((each) => intProjShortList[each])
            .join(),

          intTheoryLongList: data.att
            ? [
                Object.keys(intTheoryLongList).map(
                  (each) => intTheoryLongList[each]
                ),
                "Alternative Assessment Test",
              ]
                .join()
                .replace(/^[,]+|[,]+$/g, "")
            : Object.keys(intTheoryLongList)
                .map((each) => intTheoryLongList[each])
                .join(),

          intTheoryShortList: data.att
            ? [
                Object.keys(intTheoryShortList).map(
                  (each) => intTheoryShortList[each]
                ),
                "AAT",
              ]
                .join()
                .replace(/^[,]+|[,]+$/g, "")
            : Object.keys(intTheoryShortList)
                .map((each) => intTheoryShortList[each])
                .join(),
        };

        // console.log(reqData);

        const options = {
          method: edit !== null ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(reqData),
        };

        const response = await fetch(
          edit !== null
            ? apiClient.urls.fatcat.OBE_COURSES_UPDATE_COURSE_STRUCTURE
            : apiClient.urls.fatcat.OBE_COURSES_NEW_COURSE_STRUCTURE,
          options
        );

        const res = await response.json();

        if (res["statusCode"] === "OK") {
          if (edit !== null) {
            this.addWeightages(edit["courseStructId"]);
          } else {
            this.addWeightages(res["result"]);
          }
        } else {
          alert(res["description"]);
        }

        this.setState({ isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  addWeightages = async (structId) => {
    try {
      const {
        data,
        intTheoryShortList,
        intPractShortList,
        intProjShortList,
        extTheoryShortList,
        extProjShortList,
        extPractShortList,
      } = this.state;

      const intAns = [];
      Object.keys(intTheoryShortList).map((each) =>
        intAns.push(intTheoryShortList[each])
      );
      Object.keys(intPractShortList).map((each) =>
        intAns.push(intPractShortList[each])
      );
      Object.keys(intProjShortList).map((each) =>
        intAns.push(intProjShortList[each])
      );

      data.att && intAns.push("AAT");

      let weightReq = [];

      intAns.forEach((each) => {
        let weight = document.getElementById(`int${each}Weight`).value;
        let threshold = document.getElementById(`int${each}ThValue`).value;

        weightReq.push({
          assmntType: "internal",
          courseStructId: structId,
          templateName: each,
          templateWeightage: weight,
          thValue: threshold,
        });
      });

      const extAns = [];
      Object.keys(extTheoryShortList).map((each) =>
        extAns.push(extTheoryShortList[each])
      );
      Object.keys(extPractShortList).map((each) =>
        extAns.push(extPractShortList[each])
      );
      Object.keys(extProjShortList).map((each) =>
        extAns.push(extProjShortList[each])
      );

      extAns.forEach((each) => {
        let weight = document.getElementById(`ext${each}Weight`).value;
        let threshold = document.getElementById(`ext${each}ThValue`).value;

        weightReq.push({
          assmntType: "external",
          courseStructId: structId,
          templateName: each,
          templateWeightage: weight,
          thValue: threshold,
        });
      });

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(weightReq),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_UPDATE_WEIGHTS_TEMPLATE,
        options
      );

      if (response.ok === true) {
        const data = await response.json();
        if (data["result"] === "OK") {
          this.setState({ created: true });
        } else {
          alert(data["description"]);
        }
      } else {
        alert("Weightages are not inserted");
      }
    } catch (err) {
      console.log(err);
    }
  };

  okBtn = () => {
    const { itemUpdate } = this.props;
    this.setState(
      {
        data: initialData,
        typeCountInternal: countInternal,
        typeCountExternal: countExternal,
        created: false,
        radioBtn: "theoretical",
        extProjLongList: [],
        intProjLongList: [],
        extPractLongList: [],
        intPractLongList: [],
        extTheoryLongList: [],
        intTheoryLongList: [],
        extProjShortList: [],
        intProjShortList: [],
        extPractShortList: [],
        intPractShortList: [],
        extTheoryShortList: [],
        intTheoryShortList: [],
        intWeights: {},
      },
      itemUpdate(null)
    );
  };

  internalCountChange = (event) => {
    const { radioBtn } = this.state;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        this.setState((prev) => ({
          typeCountInternal: {
            ...prev.typeCountInternal,
            theory: event.target.value,
          },
          intTheoryLongList: {},
          intTheoryShortList: {},
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          typeCountInternal: {
            ...prev.typeCountInternal,
            practical: event.target.value,
          },
          intPractLongList: {},
          intPractShortList: {},
        }));
        break;
      case "project":
        this.setState((prev) => ({
          typeCountInternal: {
            ...prev.typeCountInternal,
            project: event.target.value,
          },
          intProjLongList: {},
          intProjShortList: {},
        }));
        break;

      default:
        break;
    }
  };

  externalCountChange = (event) => {
    const { radioBtn } = this.state;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        this.setState((prev) => ({
          typeCountExternal: {
            ...prev.typeCountExternal,
            theory: event.target.value,
          },
          extTheoryLongList: {},
          extTheoryShortList: {},
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          typeCountExternal: {
            ...prev.typeCountExternal,
            practical: event.target.value,
          },
          extPractLongList: {},
          extPractShortList: {},
        }));
        break;
      case "project":
        this.setState((prev) => ({
          typeCountExternal: {
            ...prev.typeCountExternal,
            project: event.target.value,
          },
          extProjLongList: {},
          extProjShortList: {},
        }));
        break;

      default:
        break;
    }
  };

  dataChanged = (event) => {
    switch (event.target.id) {
      case "courseTypeName":
        this.setState((prev) => ({
          data: { ...prev.data, typeName: event.target.value },
        }));
        break;

      case "att":
        this.setState((prev) => ({
          data: { ...prev.data, att: !prev.data.att },
        }));
        break;
      case "internalCount":
        this.internalCountChange(event);
        break;
      case "internalTheoryCount":
        this.setState((prev) => ({
          typeCountInternal: {
            ...prev.typeCountInternal,
            theory: event.target.value,
          },
          intTheoryLongList: {},
          intTheoryShortList: {},
        }));
        break;
      case "internalPractCount":
        this.setState((prev) => ({
          typeCountInternal: {
            ...prev.typeCountInternal,
            practical: event.target.value,
          },
          intPractLongList: {},
          intPractShortList: {},
        }));
        break;
      case "externalCount":
        this.externalCountChange(event);
        break;
      case "externalTheoryCount":
        this.setState((prev) => ({
          typeCountExternal: {
            ...prev.typeCountExternal,
            theory: event.target.value,
          },
          extTheoryLongList: {},
          extTheoryShortList: {},
        }));
        break;
      case "externalPractCount":
        this.setState((prev) => ({
          typeCountExternal: {
            ...prev.typeCountExternal,
            practical: event.target.value,
          },
          extPractLongList: {},
          extPractShortList: {},
        }));
        break;

      default:
        break;
    }
  };

  radioChanged = (event) => {
    this.setState({
      radioBtn: event.target.value,
      typeCountInternal: countInternal,
      typeCountExternal: countExternal,
      extProjLongList: {},
      intProjLongList: {},
      extPractLongList: {},
      intPractLongList: {},
      extTheoryLongList: {},
      intTheoryLongList: {},
      extProjShortList: {},
      intProjShortList: {},
      extPractShortList: {},
      intPractShortList: {},
      extTheoryShortList: {},
      intTheoryShortList: {},
    });
  };

  renderInternalData = () => {
    const { radioBtn, typeCountInternal } = this.state;

    switch (radioBtn) {
      case "both":
        return (
          <>
            <label className={`${styles["project-type-label"]}`}>
              How many internals (theoretical) does this course structure has?
            </label>

            <input
              required
              value={typeCountInternal.theory}
              id="internalTheoryCount"
              onChange={this.dataChanged}
              type="text"
              className={`${styles["filter-select"]} focus:ring-0 mr-2 w-4/6 p-2`}
            />

            <label className={`${styles["project-type-label"]}`}>
              How many internals (practical) does this course structure has?
            </label>

            <input
              required
              value={typeCountInternal.practical}
              id="internalPractCount"
              onChange={this.dataChanged}
              type="text"
              className={`${styles["filter-select"]} focus:ring-0 mr-2 w-4/6 p-2`}
            />
          </>
        );

      default:
        let val;
        switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
          case "theoretical":
            val = "theory";
            break;
          case "practical":
            val = "practical";
            break;
          case "project":
            val = "project";
            break;

          default:
            break;
        }
        return (
          <>
            <label className={`${styles["project-type-label"]}`}>
              How many internals does this course structure has?
            </label>

            <input
              required
              value={typeCountInternal[val]}
              id="internalCount"
              onChange={this.dataChanged}
              type="text"
              className={`${styles["filter-select"]} focus:ring-0 mr-2 w-4/6 p-2`}
            />
          </>
        );
    }
  };

  renderExternalData = () => {
    const { radioBtn, typeCountExternal } = this.state;

    switch (radioBtn) {
      case "both":
        return (
          <>
            <label className={`${styles["project-type-label"]}`}>
              How many externals (theoretical) does this course structure has?
            </label>

            <input
              value={typeCountExternal.theory}
              required
              id="externalTheoryCount"
              onChange={this.dataChanged}
              type="text"
              className={`${styles["filter-select"]} focus:ring-0 mr-2 w-4/6 p-2`}
            />

            <label className={`${styles["project-type-label"]}`}>
              How many externals (practical) does this course structure has?
            </label>

            <input
              required
              value={typeCountExternal.practical}
              id="externalPractCount"
              onChange={this.dataChanged}
              type="text"
              className={`${styles["filter-select"]} focus:ring-0 mr-2 w-4/6 p-2`}
            />
          </>
        );

      default:
        let val;
        switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
          case "theoretical":
            val = "theory";
            break;
          case "practical":
            val = "practical";
            break;
          case "project":
            val = "project";
            break;

          default:
            break;
        }
        return (
          <>
            <label className={`${styles["project-type-label"]}`}>
              How many externals does this course structure has?
            </label>

            <input
              required
              value={typeCountExternal[val]}
              id="externalCount"
              onChange={this.dataChanged}
              type="text"
              className={`${styles["filter-select"]} focus:ring-0 mr-2 w-4/6 p-2`}
            />
          </>
        );
    }
  };

  intShortChanged = (event) => {
    const { radioBtn } = this.state;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        this.setState((prev) => ({
          intTheoryShortList: {
            ...prev.intTheoryShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          intPractShortList: {
            ...prev.intPractShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "project":
        this.setState((prev) => ({
          intProjShortList: {
            ...prev.intProjShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  intLongChanged = (event) => {
    const { radioBtn } = this.state;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        this.setState((prev) => ({
          intTheoryLongList: {
            ...prev.intTheoryLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          intPractLongList: {
            ...prev.intPractLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "project":
        this.setState((prev) => ({
          intProjLongList: {
            ...prev.intProjLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  bothIntShortChanged = (event) => {
    switch (event.target.name) {
      case "theoretical":
        this.setState((prev) => ({
          intTheoryShortList: {
            ...prev.intTheoryShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          intPractShortList: {
            ...prev.intPractShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      default:
        break;
    }
  };

  bothIntLongChanged = (event) => {
    switch (event.target.name) {
      case "theoretical":
        this.setState((prev) => ({
          intTheoryLongList: {
            ...prev.intTheoryLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          intPractLongList: {
            ...prev.intPractLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  renderInternalTypes = () => {
    const { radioBtn, typeCountInternal } = this.state;

    let count;
    let bothTheory;
    let bothPractical;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        count = typeCountInternal.theory;
        break;
      case "practical":
        count = typeCountInternal.practical;
        break;
      case "project":
        count = typeCountInternal.project;
        break;
      case "both":
        bothTheory = typeCountInternal.theory;
        bothPractical = typeCountInternal.practical;
        break;
      default:
        break;
    }

    let ans = [];

    switch (radioBtn) {
      case "both":
        for (let index = 1; index <= bothTheory; index++) {
          ans.push(
            <InternalProjectType
              key={`bothTheory${index}`}
              number={index}
              {...this.state}
              radioBtn="theoretical"
              showRadio
              intShortChanged={this.bothIntShortChanged}
              intLongChanged={this.bothIntLongChanged}
            />
          );
        }

        for (let index = 1; index <= bothPractical; index++) {
          ans.push(
            <InternalProjectType
              key={`bothPractical${index}`}
              number={index}
              {...this.state}
              radioBtn="practical"
              showRadio
              intShortChanged={this.bothIntShortChanged}
              intLongChanged={this.bothIntLongChanged}
            />
          );
        }
        break;

      default:
        for (let index = 1; index <= count; index++) {
          ans.push(
            <InternalProjectType
              key={`${radioBtn}${index}`}
              number={index}
              {...this.state}
              radioBtn={radioBtn}
              intShortChanged={this.intShortChanged}
              intLongChanged={this.intLongChanged}
            />
          );
        }
        break;
    }

    return ans;
  };

  extShortChanged = (event) => {
    const { radioBtn } = this.state;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        this.setState((prev) => ({
          extTheoryShortList: {
            ...prev.extTheoryShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          extPractShortList: {
            ...prev.extPractShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "project":
        this.setState((prev) => ({
          extProjShortList: {
            ...prev.extProjShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  extLongChanged = (event) => {
    const { radioBtn } = this.state;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        this.setState((prev) => ({
          extTheoryLongList: {
            ...prev.extTheoryLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          extPractLongList: {
            ...prev.extPractLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "project":
        this.setState((prev) => ({
          extProjLongList: {
            ...prev.extProjLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  bothExtShortChanged = (event) => {
    switch (event.target.name) {
      case "theoretical":
        this.setState((prev) => ({
          extTheoryShortList: {
            ...prev.extTheoryShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          extPractShortList: {
            ...prev.extPractShortList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      default:
        break;
    }
  };

  bothExtLongChanged = (event) => {
    switch (event.target.name) {
      case "theoretical":
        this.setState((prev) => ({
          extTheoryLongList: {
            ...prev.extTheoryLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;
      case "practical":
        this.setState((prev) => ({
          extPractLongList: {
            ...prev.extPractLongList,
            [event.target.id]: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  renderExternalTypes = () => {
    const { radioBtn, typeCountExternal } = this.state;

    let count;
    let bothTheory;
    let bothPractical;

    switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
      case "theoretical":
        count = typeCountExternal.theory;
        break;
      case "practical":
        count = typeCountExternal.practical;
        break;
      case "project":
        count = typeCountExternal.project;
        break;
      case "both":
        bothTheory = typeCountExternal.theory;
        bothPractical = typeCountExternal.practical;
        break;
      default:
        break;
    }

    let ans = [];

    switch (radioBtn) {
      case "both":
        for (let index = 1; index <= bothTheory; index++) {
          ans.push(
            <ExternalProjectType
              key={`bothExtTheory${index}`}
              number={index}
              {...this.state}
              radioBtn="theoretical"
              showRadio
              extShortChanged={this.bothExtShortChanged}
              extLongChanged={this.bothExtLongChanged}
            />
          );
        }

        for (let index = 1; index <= bothPractical; index++) {
          ans.push(
            <ExternalProjectType
              key={`bothExtPract${index}`}
              number={index}
              {...this.state}
              radioBtn="practical"
              showRadio
              extShortChanged={this.bothExtShortChanged}
              extLongChanged={this.bothExtLongChanged}
            />
          );
        }
        break;

      default:
        for (let index = 1; index <= count; index++) {
          ans.push(
            <ExternalProjectType
              number={index}
              {...this.state}
              radioBtn={radioBtn}
              extShortChanged={this.extShortChanged}
              extLongChanged={this.extLongChanged}
            />
          );
        }
        break;
    }

    return ans;
  };

  intWeightsChanged = (event) => {
    this.setState((prev) => ({
      intWeights: { ...prev.intWeights, [event.target.id]: event.target.value },
    }));
  };

  renderInternalWeightages = () => {
    const {
      intWeights,
      data,
      intTheoryShortList,
      intPractShortList,
      intProjShortList,
    } = this.state;

    const items = [];

    const ans = [];
    Object.keys(intTheoryShortList).map((each) =>
      ans.push(intTheoryShortList[each])
    );
    Object.keys(intPractShortList).map((each) =>
      ans.push(intPractShortList[each])
    );
    Object.keys(intProjShortList).map((each) =>
      ans.push(intProjShortList[each])
    );

    data.att && ans.push("AAT");

    ans.forEach((i) => {
      const liItem = (
        <InternalWeightages
          intWeights={intWeights}
          intWeightsChanged={this.intWeightsChanged}
          key={i}
          name={i}
        />
      );
      items.push(liItem);
    });

    return items;
  };

  renderExternalWeightages = () => {
    const {
      intWeights,
      extTheoryShortList,
      extPractShortList,
      extProjShortList,
    } = this.state;
    const items = [];

    const ans = [];
    Object.keys(extTheoryShortList).map((each) =>
      ans.push(extTheoryShortList[each])
    );
    Object.keys(extPractShortList).map((each) =>
      ans.push(extPractShortList[each])
    );
    Object.keys(extProjShortList).map((each) =>
      ans.push(extProjShortList[each])
    );

    ans.forEach((i) => {
      const liItem = (
        <ExternalWeightages
          intWeights={intWeights}
          intWeightsChanged={this.intWeightsChanged}
          key={i}
          name={i}
        />
      );
      items.push(liItem);
    });

    return items;
  };

  render() {
    const {
      data,
      typeCountInternal,
      typeCountExternal,
      intPractShortList,
      intTheoryShortList,
      intProjShortList,
      extTheoryShortList,
      extPractShortList,
      extProjShortList,
      radioBtn,
      created,
      isLoading,
    } = this.state;

    const { edit } = this.props;

    return (
      <>
        {created ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              {edit !== null
                ? "Course Structure Updated successfully!"
                : "New Course Structure created successfully!"}
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : (
          <form autoComplete="off" onSubmit={this.addStructure}>
            <div className="flex flex-col flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5">
              <label className={`${styles.label} pt-5 pl-5`}>
                Select Course Structure Type
              </label>

              <div className="flex flex-row flex-wrap justify-between items-center w-full mt-4 mb-2 pl-5 pr-5">
                <div className="mt-1 mr-1 mb-4 flex flex-row">
                  <input
                    type="radio"
                    name="type"
                    checked={radioBtn === "theoretical"}
                    value="theoretical"
                    onChange={this.radioChanged}
                    className="mr-2 focus:ring-0 border-blue-600 w-4"
                  />
                  <label className={styles["course-type-label"]}>
                    Theoretical
                  </label>
                </div>
                <div className="mt-1 mr-1 mb-4 flex flex-row">
                  <input
                    type="radio"
                    name="type"
                    checked={radioBtn === "practical"}
                    value="practical"
                    onChange={this.radioChanged}
                    className="mr-2 focus:ring-0 border-blue-600 w-4"
                  />
                  <label className={styles["course-type-label"]}>
                    Practical
                  </label>
                </div>
                <div className="mt-1 mr-1 mb-4 flex flex-row">
                  <input
                    type="radio"
                    name="type"
                    checked={radioBtn === "both"}
                    value="both"
                    onChange={this.radioChanged}
                    className="mr-2 focus:ring-0 border-blue-600 w-4"
                  />
                  <label className={styles["course-type-label"]}>
                    Both(Theoretical & Practical)
                  </label>
                </div>
                <div className="mt-1 mr-1 mb-4 flex flex-row">
                  <input
                    type="radio"
                    name="type"
                    checked={radioBtn === "project"}
                    value="project"
                    onChange={this.radioChanged}
                    className="mr-2 focus:ring-0 border-blue-600 w-4"
                  />
                  <label className={styles["course-type-label"]}>
                    Project (Rubrics based)
                  </label>
                </div>

                <div className="mt-1 mr-1 mb-4 flex flex-row">
                  <input
                    type="radio"
                    name="type"
                    checked={radioBtn === "epic"}
                    value="epic"
                    onChange={this.radioChanged}
                    className="mr-2 focus:ring-0 border-blue-600 w-4"
                  />
                  <label className={styles["course-type-label"]}>
                    Epic (Rubrics based)
                  </label>
                </div>
              </div>

              <hr className={styles["hr-line"]} />

              <div className="flex flex-col justify-between w-full pl-5 pr-5">
                <span className="flex flex-col mt-5">
                  {this.renderInternalData()}
                </span>
                <ul className="mt-6 ml-4">{this.renderInternalTypes()}</ul>
              </div>

              <hr className={styles["hr-line"]} />

              <div className="pl-5 pr-5 mt-3">
                <label className={styles.label}>
                  Does the course structure have Assignment?
                </label>

                <span className="flex flex-row items-center mt-3 mb-3">
                  <input
                    id="att"
                    name="assignment"
                    checked={data.att}
                    onChange={this.dataChanged}
                    type="radio"
                    className="mr-2 focus:ring-0 mb-2"
                  />
                  <label className={styles.label}>Yes</label>

                  <input
                    id="att"
                    name="assignment"
                    checked={!data.att}
                    onChange={this.dataChanged}
                    type="radio"
                    className="mr-2 focus:ring-0 mb-2 ml-10"
                  />
                  <label className={styles.label}>No</label>
                </span>
              </div>

              <hr className={styles["hr-line"]} />

              <div className="flex flex-col justify-between w-full pl-5 pr-5">
                <span className="flex flex-col mt-6">
                  {this.renderExternalData()}
                </span>

                <ul className="mt-6 ml-4">{this.renderExternalTypes()}</ul>
              </div>

              {(typeCountInternal.theory > 0 ||
                typeCountInternal.practical > 0 ||
                typeCountInternal.project > 0 ||
                typeCountExternal.theory > 0 ||
                typeCountExternal.practical > 0 ||
                typeCountExternal.project > 0 ||
                data.att) && (
                <>
                  <hr className={styles["hr-line"]} />

                  <span className="pl-5 pr-5 text-[#000]">
                    <p
                      className={
                        Object.keys(intPractShortList).length > 0 ||
                        Object.keys(intTheoryShortList).length > 0 ||
                        Object.keys(intProjShortList).length > 0 ||
                        Object.keys(extPractShortList).length > 0 ||
                        Object.keys(extTheoryShortList).length > 0 ||
                        Object.keys(extProjShortList).length > 0 ||
                        data.att
                          ? "flex flex-col w-full mt-8 mb-4 font-semibold"
                          : "hidden"
                      }
                    >
                      Weightages <br />
                      <span className={styles["weightages-info"]}>
                        ( This weightages are required to calculate Averages in
                        attainment table and threshold values are for student
                        targets)
                      </span>
                    </p>

                    <p
                      className={
                        Object.keys(intPractShortList).length > 0 ||
                        Object.keys(intTheoryShortList).length > 0 ||
                        Object.keys(intProjShortList).length > 0 ||
                        data.att
                          ? styles["course-type-label"]
                          : "hidden"
                      }
                    >
                      Internal
                    </p>

                    <div
                      className={
                        Object.keys(intPractShortList).length > 0 ||
                        Object.keys(intTheoryShortList).length > 0 ||
                        Object.keys(intProjShortList).length > 0 ||
                        data.att
                          ? "flex flex-row w-full flex-wrap"
                          : "hidden"
                      }
                    >
                      <ul className="mt-3 ml-4 flex flex-wrap">
                        {this.renderInternalWeightages()}
                      </ul>
                    </div>

                    <p
                      className={
                        Object.keys(extPractShortList).length > 0 ||
                        Object.keys(extTheoryShortList).length > 0 ||
                        Object.keys(extProjShortList).length > 0
                          ? `${styles["course-type-label"]} w-full`
                          : "hidden"
                      }
                    >
                      External
                    </p>

                    <div className="flex flex-row w-full flex-wrap">
                      <ul className="mt-3 ml-4 flex flex-wrap">
                        {this.renderExternalWeightages()}
                      </ul>
                    </div>
                  </span>
                </>
              )}

              <hr className={styles["hr-line"]} />

              <span className="mt-5 mb-3 flex flex-col pl-5 pr-5">
                <label className={styles.label}>
                  To re-use this structure with multiple courses, enter the
                  structure name
                </label>
                <input
                  required
                  value={data.typeName}
                  onChange={this.dataChanged}
                  id="courseTypeName"
                  type="text"
                  className={`${styles["filter-select"]} w-2/5 focus:ring-0 p-2`}
                  placeholder="Ex: Theory"
                />
              </span>
            </div>

            <div className="flex flex-row justify-end">
              <button
                disabled={isLoading}
                className={`${
                  isLoading && "cursor-not-allowed"
                } font-Lato-normal text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mb-8 mt-4`}
                type="submit"
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Save Structure"
                )}
              </button>
            </div>
          </form>
        )}
      </>
    );
  }
}

export default NewCourseStructure;
