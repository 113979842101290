import axios from "axios";
import * as apiUrls from "../apiUrls";
const RestAxiosService = (
  url,
  method = "GET",
  body = null,
  headers = {},
  config = {}
) => {
  const extraConfig = typeof config === "object" ? config : {};
  const options = {
    url,
    method: method,
    data: body,
    headers: headers,
  };
  if (options.data === null || method.toLowerCase() === "get") {
    delete options.data;
  }
  return new Promise((resolve, reject) => {
    axios.interceptors.request.use((x) => {
      x.meta = x.meta || {};
      x.meta.requestStartedAt = new Date().getTime();
      return x;
    });

    axios.interceptors.response.use(
      (x) => {
        return x;
      },
      (x) => {
        throw x;
      }
    );
    return axios({ withCredentials: false, ...options, ...extraConfig })
      .then(function (res) {
        resolve({ headers: res.headers, status: res.status, data: res.data });
      })
      .catch(function (e) {
        if (typeof e === "object" && typeof e.response === "object") {
          reject({
            status: e.response.status,
            headers: e.response.headers,
            data: e.response.data,
          });
        } else {
          reject({
            status: 532,
            headers: {},
            data: "Internet Connection Failure or Server is Down",
          });
        }
      });
  });
};

function HttpGet(url, headers = {}, config = {}) {
  return new Promise((resolve, reject) => {
    return RestAxiosService(url, "GET", null, headers, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function HttpPost(url, body, headers = {}, config = {}) {
  return new Promise((resolve, reject) => {
    return RestAxiosService(url, "POST", body, headers, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function HttpPut(url, body, headers = {}, config = {}) {
  return new Promise((resolve, reject) => {
    return RestAxiosService(url, "PUT", body, headers, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function HttpPatch(url, body, headers = {}, config = {}) {
  return new Promise((resolve, reject) => {
    return RestAxiosService(url, "PUT", body, headers, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function HttpDelete(url, headers = {}, config = {}) {
  return new Promise((resolve, reject) => {
    return RestAxiosService(url, "DELETE", null, headers, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

const urls = {
  fatcat: apiUrls,
};

const apiClient = {
  urls,
  get: HttpGet,
  post: HttpPost,
  put: HttpPut,
  patch: HttpPatch,
  delete: HttpDelete,
};

export default apiClient;
