import { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import FiltersContext from "../../../../.././../context/FiltersContext";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const PoPsoCoursesMapping = (props) => {
  const {
    uploadedStatus,
    branch,
    academicYear,
    statusCode,
    metricInfo,
    metricsId,
    template,
  } = props;

  const { filtersBatch } = useContext(FiltersContext);
  const [artData, updateArtData] = useState({});
  const [poData, updatePoData] = useState([]);
  const [psoData, updatePsoData] = useState([]);
  const [metricsUpdatedData, setMetricsUpdatedData] = useState({});

  const submitted = async () => {
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      for (let each of [...poData, ...psoData]) {
        reqBody.push({
          coursesPosMappingId: document.getElementById(
            `${each["po"]}-coursesMappingList`
          ).name,
          coursesList: document.getElementById(
            `${each["po"]}-coursesMappingList`
          ).value,
          poName: each["po"],
        });
      }

      const options = {
        method:
          metricInfo["po-pso-courses-list-mapping-table"].length === 0
            ? "POST"
            : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsoData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoArtDetails = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const batch = filtersBatch.filter(
        (each) => each?.["optionDesc"] === academicYear
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.DASHBOARD_PROGRAM_ART_MATRIX +
          `?batchId=${batch[0]?.["optionValue"]}&branch=${branch}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        const data = response["data"]["result"];

        const mapData = {};

        const yearKeys = Object.keys(data).sort();

        for (let year of yearKeys) {
          const courseKeys = Object.keys(data[year]);

          for (let course of courseKeys) {
            if (
              !course.startsWith("totalCoursesMappedForAY") &&
              !course.startsWith("mappingAvgForAY")
            ) {
              for (let item of Object.entries(
                data?.[year]?.[course]?.["poArticulationMatrixForCourse"]
              )) {
                if (parseFloat(item[1]) > 0) {
                  if (mapData[item[0]]) {
                    mapData[item[0]] = [
                      ...mapData[item[0]],
                      data?.[year]?.[course]?.["courseShortName"],
                    ];
                  } else {
                    mapData[item[0]] = [
                      data?.[year]?.[course]?.["courseShortName"],
                    ];
                  }
                }
              }
            }
          }
        }

        updateArtData(mapData);
      }
    } catch (err) {
      console.log(err);
    }

    uploadedStatus("success");
  };

  const renderPoTable = () => {
    let result = [];

    try {
      for (let each of poData) {
        result.push(
          <tr key={each["po"]}>
            <th className="text-center w-1/12">{each["po"]}</th>
            <td>
              {statusCode === "303" ? (
                [...(artData?.[each["po"]] ?? [])].join(", ") ?? ""
              ) : (
                <input
                  defaultValue={metricsUpdatedData?.[each["po"]]?.["val"] ?? ""}
                  type="text"
                  name={metricsUpdatedData?.[each["po"]]?.["coPoMapId"] ?? ""}
                  id={`${each["po"]}-coursesMappingList`}
                />
              )}
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderPsoTable = () => {
    let result = [];

    try {
      for (let each of psoData) {
        result.push(
          <tr key={each["po"]}>
            <th className="text-center w-1/12">{each["po"]}</th>
            <td>
              {statusCode === "303" ? (
                [...(artData?.[each["po"]] ?? [])].join(", ") ?? ""
              ) : (
                <input
                  defaultValue={metricsUpdatedData?.[each["po"]]?.["val"] ?? ""}
                  type="text"
                  name={metricsUpdatedData?.[each["po"]]?.["coPoMapId"] ?? ""}
                  id={`${each["po"]}-coursesMappingList`}
                />
              )}
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  useEffect(() => {
    getPoData();
  }, []);

  useEffect(() => {
    branch !== undefined && getPsoData();
    branch !== undefined &&
      academicYear !== undefined &&
      statusCode === "303" &&
      getPoArtDetails();

    if (statusCode !== "303") {
      const UpdatedData = metricInfo?.[
        "po-pso-courses-list-mapping-table"
      ]?.reduce((acc, item) => {
        const po = item?.["po_name"];

        if (!acc[po]) {
          acc[po] = { val: "", coPoMapId: "" };
        }

        acc[po] = {
          val: item?.["courses_list"],
          coPoMapId: item?.["courses_pos_mapping_id"] ?? "",
        };

        return acc;
      }, {});

      setMetricsUpdatedData(UpdatedData);
    }
  }, [branch, academicYear, statusCode]);

  return (
    <div className="p-2 pr-0 flex flex-col">
      <table className={styles["po-course-table"]}>
        <tr className="bg-[#eff3ff]">
          <th className="text-center w-1/12">PO Number</th>
          <th>List of Courses</th>
        </tr>

        {renderPoTable()}
        {renderPsoTable()}
      </table>

      {statusCode !== "303" && (
        <button
          onClick={submitted}
          className={styles["submit-btn"]}
          type="button"
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default PoPsoCoursesMapping;
