import styles from "./index.module.css";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Oval } from "react-loader-spinner";
import FiltersContext from "../../../../../context/FiltersContext";

const dummyData = {
  regulationId: "",
  batchId: "",
  branch: "",
  semester: "",
  cieW: "",
  seeW: "",
  coTargetAtt: "",
  directCoW: "",
  indirectCoW: "",
  thValue: "",
  lvl1W: "",
  lvl2W: "",
  lvl3w: "",
  directPoW: "",
  indirectPoW: "",
  poTargetAtt: "",
};

const dummyPoFilters = {
  branch: "",
  batch: "",
  regulation: "",
  assessment: "",
  count: 0,
};

let posOptions = [];

const ModifyWeightages = () => {
  const { filtersBatch, filtersBranch, filtersSem } =
    useContext(FiltersContext);

  const [data, updateData] = useState(dummyData);
  const [batchOptions] = useState(filtersBatch);
  const [branchOptions] = useState(filtersBranch);
  const [semOptions] = useState(filtersSem);
  const [regOptions, updateRegOptions] = useState([]);
  const [assOptions, updateAssOptions] = useState([]);
  const [created, updateCreated] = useState(false);
  const [activeTab, updateActive] = useState(2);
  const [generate, updateGen] = useState(false);
  const [poFilters, updatePoFilters] = useState(dummyPoFilters);
  const [poData, updatePoData] = useState([]);
  const [assCreated, updateAssCreated] = useState(false);
  const [dropdownsSelected, setDropdownsSelected] = useState([]);
  const [modifyLoading, updateModifyLoading] = useState(false);
  const [poLoading, updatePoLoading] = useState(false);

  const handleDropdownChange = (index, selectedList) => {
    const updatedDropdowns = [...dropdownsSelected];
    updatedDropdowns[index] = selectedList;
    setDropdownsSelected(updatedDropdowns);
  };

  const modifyData = async () => {
    const token = Cookies.get("jwt_token");
    updateModifyLoading(true);
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_ADMIN_COURSES_MODIFY_WEIGHTAGES,
        options
      );

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updateCreated(true);
        } else {
          alert(res["description"]);
        }
      } else {
        alert("Weightages are Not Modified");
      }
      updateModifyLoading(false);
    } catch (err) {
      console.log(err);
    }

    // console.log(response);
  };

  const submitData = async () => {
    const token = Cookies.get("jwt_token");
    updatePoLoading(true);

    try {
      const pairList = [];

      if (poFilters.assessment === "Student Exit Survey") {
        for (let each of poData) {
          const surveyPct = document.getElementById(`${each["po"]}-survey`);
          pairList.push({ idaPoPct: surveyPct.value, poList: each["po"] });
        }
      } else {
        for (let i = 1; i <= poFilters.count; i++) {
          const activityPct = document.getElementById(
            `${poFilters.assessment}-${i}`
          );

          const activityPosList = [];

          for (let each of dropdownsSelected[i - 1]) {
            activityPosList.push(each.name);
          }

          const updated = activityPosList.join();
          const stringWithoutLeadingCommas = updated.replace(/^,*/, "");

          pairList.push({
            idaPoPct: activityPct.value,
            poList: stringWithoutLeadingCommas,
          });
        }
      }

      const reqBody = {
        regulation: poFilters.regulation,
        batch: poFilters.batch,
        branch: poFilters.branch,
        idaAssmntType: poFilters.assessment,
        noOfIdaActivities:
          poFilters.assessment === "Student Exit Survey"
            ? poData.length
            : poFilters.count,
        poPctPairList: pairList,
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_COURSES_IDA_PO_ASSESSMENT,
        options
      );

      if (response["ok"] === true) {
        const res = await response.json();

        if (res["result"] === "OK") {
          updateAssCreated(true);
        } else {
          alert(res["description"]);
        }
      } else {
        alert("Data Not Stored!");
      }

      updatePoLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const saveBtn = (event) => {
    event.preventDefault();
    modifyData();
  };

  const okBtn = () => {
    updateData(dummyData);
    updateCreated(false);
  };

  const okAssBtn = () => {
    updatePoFilters(dummyPoFilters);
    updateGen(false);
    updateAssCreated(false);
  };

  const regulationChanged = (event) => {
    updateData({ ...data, regulationId: event.target.value });
  };

  const batchChanged = (event) => {
    updateData({ ...data, batchId: event.target.value });
  };

  const branchChanged = (event) => {
    updateData({ ...data, branch: event.target.value });
  };

  const semesterChanged = (event) => {
    updateData({ ...data, semester: event.target.value });
  };

  const poRegulationChanged = (event) => {
    updatePoFilters({ ...poFilters, regulation: event.target.value });
  };

  const poBatchChanged = (event) => {
    updatePoFilters({ ...poFilters, batch: event.target.value });
  };

  const poBranchChanged = (event) => {
    updatePoFilters({ ...poFilters, branch: event.target.value });
  };

  const poAssessmentChanged = (event) => {
    updatePoFilters({ ...poFilters, assessment: event.target.value, count: 0 });
    updateGen(false);
  };

  const poCountChanged = (event) => {
    let ans = [];
    for (let i = 0; i < event.target.value; i++) {
      ans.push([]);
    }
    setDropdownsSelected(ans);
    updatePoFilters({ ...poFilters, count: event.target.value });
    updateGen(false);
  };

  const cieWChange = (event) => {
    updateData({ ...data, cieW: event.target.value });
  };

  const seeWChange = (event) => {
    updateData({ ...data, seeW: event.target.value });
  };

  const coTargetAttainmentChanged = (event) => {
    updateData({ ...data, coTargetAtt: event.target.value });
  };

  const directCoWChange = (event) => {
    updateData({ ...data, directCoW: event.target.value });
  };

  const indirectCoWChange = (event) => {
    updateData({ ...data, indirectCoW: event.target.value });
  };

  const poTargetAttainmentChanged = (event) => {
    updateData({ ...data, poTargetAtt: event.target.value });
  };

  const directPoWChange = (event) => {
    updateData({ ...data, directPoW: event.target.value });
  };

  const indirectPoWChange = (event) => {
    updateData({ ...data, indirectPoW: event.target.value });
  };

  const thValueChanged = (event) => {
    updateData({ ...data, thValue: event.target.value });
  };

  const level1RangeChange = (event) => {
    updateData({ ...data, lvl1W: event.target.value });
  };

  const level2RangeChange = (event) => {
    updateData({ ...data, lvl2W: event.target.value });
  };

  const level3RangeChange = (event) => {
    updateData({ ...data, lvl3w: event.target.value });
  };

  const getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateRegOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAssOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=IDA_ASSMNT_TYPE&screenName=OBE_IDA_PO`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateAssOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOptions = async () => {
    try {
      await getRegOptions();

      await getAssOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      for (const item of semOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderRegOptions = () => {
    const ans = [];
    try {
      for (const item of regOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoBatchOptions = () => {
    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionDesc"]} value={item["optionDesc"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoBranchOptions = () => {
    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoRegOptions = () => {
    const ans = [];
    try {
      for (const item of regOptions) {
        ans.push(
          <option key={item["optionDesc"]} value={item["optionDesc"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderAssessmentOptions = () => {
    const ans = [];
    try {
      for (const item of assOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  // const editWeightagesTab = () => {
  //   updateActive(1);
  // };

  const poTab = () => {
    updateActive(2);
  };

  const studentExitSurveyView = () => (
    <div className="flex flex-col">{renderPosDesc()}</div>
  );

  const activitiesView = () => {
    try {
      let ans = [];

      for (let i = 1; i <= poFilters.count; i++) {
        ans.push(
          <div className="flex items-center">
            <span className="w-5/12 mt-1 mr-12 flex flex-col ">
              <label className={styles.label}>
                {poFilters.assessment}-{i}
              </label>
              <input
                required
                id={`${poFilters.assessment}-${i}`}
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 40%"
              />
            </span>
            <span className="w-3/12 mr-1 flex flex-col ">
              <label className={`${styles.label}`}>Select POs</label>
              <Multiselect
                key={`${poFilters.assessment}-${i}-pos`}
                className={styles["react-select"]}
                showCheckbox
                hideSelectedList
                avoidHighlightFirstOption={true}
                options={posOptions} // Options to display in the dropdown
                selectedValues={dropdownsSelected[i - 1]} // Preselected value to persist in dropdown
                onSelect={(selectedList) =>
                  handleDropdownChange(i - 1, selectedList)
                }
                onRemove={(selectedList) =>
                  handleDropdownChange(i - 1, selectedList)
                }
                displayValue="name" // Property name to display in the dropdown options
              />
              {/* <select
                multiple
                size={1}
                id={`${poFilters.assessment}-${i}-pos`}
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderPos()}
              </select> */}
            </span>
          </div>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const generateResultBtn = (event) => {
    event.preventDefault();
    updateGen(true);
  };

  const renderEditWeightagesView = () => (
    <>
      {created ? (
        <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
          <img src="/assets/tick.svg" alt="new course" width={34} height={34} />
          <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
            Weightages Modified successfully!
          </h1>
          <button
            onClick={okBtn}
            className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
            type="button"
          >
            Ok
          </button>
        </div>
      ) : (
        <form onSubmit={saveBtn}>
          <div className="flex flex-row justify-between flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5">
            <span className="w-2/5 mt-1 mr-1 flex flex-col">
              <label className={styles.label}>Regulation</label>
              <select
                value={data.regulationId}
                onChange={regulationChanged}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderRegOptions()}
              </select>
            </span>
            <span className="w-2/5 mt-1 mr-1 flex flex-col">
              <label className={styles.label}>Batch</label>
              <select
                value={data.batchId}
                onChange={batchChanged}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderBatchOptions()}
              </select>
            </span>
            <span className="w-2/5 mt-1 mr-1 flex flex-col">
              <label className={styles.label}>Branch</label>
              <select
                value={data.branch}
                required
                onChange={branchChanged}
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderBranchOptions()}
              </select>
            </span>
            <span className="w-2/5 mt-1 mr-1 flex flex-col">
              <label className={styles.label}>Semester</label>
              <select
                value={data.semester}
                onChange={semesterChanged}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderSemOptions()}
              </select>
            </span>

            <span className="w-3/12 mt-1 mr-1 flex flex-col ">
              <label className={styles.label}> CIE Weightage (%)</label>
              <input
                value={data.cieW}
                onChange={cieWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> SEE Weightage (%)</label>
              <input
                value={data.seeW}
                onChange={seeWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>

            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Thresold Value</label>
              <input
                value={data.thValue}
                onChange={thValueChanged}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 60"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Direct CO Weightage</label>
              <input
                value={data.directCoW}
                onChange={directCoWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 60"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Indirect CO Weightage</label>
              <input
                value={data.indirectCoW}
                onChange={indirectCoWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 60"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}>CO Target Attainment</label>
              <input
                value={data.coTargetAtt}
                onChange={coTargetAttainmentChanged}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Direct PO Weightage (%)</label>
              <input
                value={data.directPoW}
                onChange={directPoWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Indirect PO Weightage (%)</label>
              <input
                value={data.indirectPoW}
                onChange={indirectPoWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}>PO Target Attainment</label>
              <input
                value={data.poTargetAtt}
                onChange={poTargetAttainmentChanged}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>

            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Level 1 Max Range</label>
              <input
                value={data.lvl1W}
                onChange={level1RangeChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Level 2 Max Range</label>
              <input
                value={data.lvl2W}
                onChange={level2RangeChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/12 mt-1 mr-1 flex flex-col">
              <label className={styles.label}> Level 3 Max Range</label>
              <input
                value={data.lvl3w}
                onChange={level3RangeChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
          </div>
          <div className="flex flex-row justify-end">
            <button
              disabled={modifyLoading}
              className={`${
                modifyLoading && "cursor-not-allowed"
              } font-Lato-normal text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mb-8 mt-4`}
              type="submit"
            >
              {modifyLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#FFF"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </form>
      )}
    </>
  );

  const renderPOView = () => (
    <>
      {assCreated ? (
        <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
          <img src="/assets/tick.svg" alt="new course" width={34} height={34} />
          <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
            Data Stored successfully!
          </h1>
          <button
            onClick={okAssBtn}
            className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
            type="button"
          >
            Ok
          </button>
        </div>
      ) : (
        <>
          <form onSubmit={generateResultBtn}>
            <div className="flex flex-row justify-between flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5">
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.label}>Regulation</label>
                <select
                  value={poFilters.regulation}
                  onChange={poRegulationChanged}
                  required
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {renderPoRegOptions()}
                </select>
              </span>
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.label}>Batch</label>
                <select
                  value={poFilters.batch}
                  onChange={poBatchChanged}
                  required
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {renderPoBatchOptions()}
                </select>
              </span>
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.label}>Branch</label>
                <select
                  onChange={poBranchChanged}
                  value={poFilters.branch}
                  required
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {renderPoBranchOptions()}
                </select>
              </span>
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.label}>Indirect Assessment Type</label>
                <select
                  value={poFilters.assessment}
                  onChange={poAssessmentChanged}
                  required
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>

                  {renderAssessmentOptions()}
                </select>
              </span>

              {poFilters.assessment !== "" &&
                poFilters.assessment !== "Student Exit Survey" && (
                  <span className="w-2/5 mt-1 mr-1 flex flex-col ">
                    <label className={styles.label}>
                      Number of {poFilters.assessment}
                    </label>
                    <input
                      value={poFilters.count}
                      onChange={poCountChanged}
                      required
                      type="text"
                      className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                      placeholder="EX: 3"
                    />
                  </span>
                )}
            </div>

            <div className="flex flex-row justify-end">
              <button
                className="font-Lato-normal text-white bg-[#456BF1] rounded-2xl text-base font-medium px-8 py-2 mr-5 mb-8 mt-4"
                type="submit"
              >
                Generate IDA PO
              </button>
            </div>
          </form>

          {generate && (
            <div className="text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5">
              <h1 className={styles["course-analysis-title"]}>Result</h1>

              {poFilters.assessment !== "" &&
              poFilters.assessment === "Student Exit Survey"
                ? studentExitSurveyView()
                : activitiesView()}

              <div className="flex flex-row justify-end">
                <button
                  disabled={poLoading}
                  onClick={submitData}
                  className={`${
                    poLoading && "cursor-not-allowed"
                  } font-Lato-normal text-white bg-[#456BF1] rounded-2xl text-base font-medium px-8 py-1.5 mr-5 mb-4 mt-8`}
                  type="button"
                >
                  {poLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#FFFFFF"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#FFF"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  const renderPosDesc = () => {
    try {
      let ans = [];

      for (let each of poData) {
        ans.push(
          <div
            className={`${styles["existing-pso-box"]} text-ellipsis overflow-hidden`}
          >
            <p className={styles["pso-btn"]}>{each["po"]}</p>
            <p className="text-ellipsis overflow-hidden">{each["poDesc"]}</p>
            <input
              required
              type="text"
              id={`${each["po"]}-survey`}
              className={`${styles["pso-filter-txt"]} focus:ring-0 p-2`}
              placeholder="EX: 40%"
            />
          </div>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPos = () => {
    try {
      posOptions = [];
      for (let each of poData) {
        posOptions.push({ name: each["po"], id: each["po"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      getOptions();
      getPoData();
    }
  }, []);

  useEffect(() => {
    try {
      renderPos();
    } catch (err) {
      console.log(err);
    }
  }, [poData]);

  return (
    <>
      <div className="bg-[#FFFFFF] p-5 mt-4">
        <span className="flex flex-row items-center">
          {/* <button
            onClick={editWeightagesTab}
            type="button"
            className={`hover:text-[#3f4451] ${
              activeTab === 1
                ? styles["overlay-tab-active"]
                : styles["overlay-tab"]
            } mb-1 mr-16`}
          >
            Edit Weightages
          </button> */}
          <button
            onClick={poTab}
            type="button"
            className={`hover:text-[#3f4451] ${
              activeTab === 2
                ? styles["overlay-tab-active"]
                : styles["overlay-tab"]
            } mb-1`}
          >
            Indirect PO Assessment
          </button>
        </span>
      </div>

      {activeTab === 1 ? renderEditWeightagesView() : renderPOView()}
    </>
  );
};

export default ModifyWeightages;
