import styles from "./index.module.css";

const StudentEnrolmentRatioTable = () => (
  <form className="p-2 pr-0 flex flex-col">
    <table className={styles["student-enroll-table"]}>
      <thead>
        <tr>
          <th>Item</th>
          <th>CAY4</th>
          <th>CAYm1</th>
          <th>CAYm2</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            N=&nbsp; Sanctioned intake of the program in the 1st year (as per
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AICTE/Competent
            authority)
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            N1= Total no. of students admitted in the 1st year minus the <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; no. of
            students, who migrated to other programs/ <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            institutions plus no. of students, who migrated to this <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; program
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            N4= Total no. of students admitted in the 1st year via all
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            supernumerary quotas
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>Enrolment Ratio (ER)=(N1+N4)/N</td>
          <td className={styles["input-col"]}>ER_1</td>
          <td className={styles["input-col"]}>ER_2</td>
          <td className={styles["input-col"]}>ER_3</td>
        </tr>

        <tr>
          <td>Average ER = (ER_1+ ER_2+ ER_3)/3</td>
          <td className={styles["input-col"]} colSpan={3}>
            <input required />
          </td>
        </tr>
      </tbody>
    </table>

    <button className={styles["submit-btn"]} type="submit">
      Submit
    </button>
  </form>
);

export default StudentEnrolmentRatioTable;
