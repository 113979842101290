import { Component } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import InitialSetup from "../../../InitialSetup";
import SideBar from "../../../layouts/SideBar";
import { Oval } from "react-loader-spinner";
import "./index.css";
import "../../../LoginForm/index.css";
import ConnectNavBar from "../../../layouts/ConnectNavBar";
import MetricsView from "./MetricsView";

class DashboardMetricsData extends Component {
  state = { setUp: "loading" };

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_DASHBOARD`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          this.setState({ setUp: true });
        } else {
          this.setState({ setUp: false });
        }
      } else {
        this.setState({ setUp: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    this.getBatchOptions();
  }

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          return (
            <div className="flex flex-col min-h-full overflow-hidden overflow-y-scroll p-0 m-0 pb-5">
              <MetricsView />
            </div>
          );
        case false:
          return <InitialSetup />;
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div className="home flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]">
          <SideBar />
          <div className="flex flex-col">
            <ConnectNavBar />
            {this.renderView()}
          </div>
        </div>
      </span>
    );
  }
}

export default DashboardMetricsData;
