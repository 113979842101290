import React, { Component } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class Input extends Component {
  state = { content: "", loading: true };

  quillRef = React.createRef();

  componentDidMount() {
    const { metricInfo } = this.props;
    this.setState({
      content: metricInfo?.data ?? "",
      loading: false,
    });
  }

  handleEditorChange = (data) => {
    this.setState({ content: data });
  };

  handleImageUpload = async () => {
    const { metricsId } = this.props;

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file, file.name);
      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA_IMG_UPLOAD +
          `/${metricsId}`,
        options
      );

      if (response.ok) {
        const url = await response.json();
        const quill = this.quillRef.current.getEditor();
        const range = quill.getSelection();
        if (range) {
          quill.insertEmbed(range.index, "image", url);
        }
      }
    };
  };

  submitted = async () => {
    const { content } = this.state;
    const { metricsId, metricInfo, template } = this.props;

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const reqBody = { data: content };

    const options = {
      method: metricInfo === undefined ? "POST" : "PUT",
      headers,
      body: JSON.stringify(reqBody),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        const { uploadedStatus } = this.props;
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      console.log(err);
      alert("Failed to upload data");
    }
  };

  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        [{ background: [] }],
        [{ color: [] }],
        [{ align: [] }],

        [
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "link",
          "image",
          "code",
        ],

        [{ list: "ordered" }],
        [{ list: "bullet" }],
        [{ indent: "-1" }],
        [{ indent: "+1" }],
      ],
      handlers: {
        image: this.handleImageUpload,
      },
    },
  };

  render() {
    const { content, loading } = this.state;

    return loading ? (
      <div className="flex justify-center items-center w-full h-80">
        <Oval
          height={50}
          width={50}
          color="#3D65F4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3D65F4"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    ) : (
      <div className="flex flex-col p-2 pr-0 z-0">
        <ReactQuill
          ref={this.quillRef}
          theme="snow"
          value={content}
          onChange={this.handleEditorChange}
          modules={this.modules}
          placeholder="Enter the metric data"
        />

        <button
          className={styles["submit-btn"]}
          type="button"
          onClick={this.submitted}
        >
          Submit
        </button>
      </div>
    );
  }
}

export default Input;
