import "./index.css";
import { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import Cookies from "js-cookie";
import apiClient from "../../../utils/apiUrls/apiClient";

const CoPoMapping = (props) => {
  const [isLoading, updateLoading] = useState(false);
  const [apiReq, updateApiReq] = useState(true);
  const [details, updateDetails] = useState({});
  const [cosDetails, updateCosDetails] = useState([]);
  const [activeCo, updateActiveCo] = useState(null);
  const [activeCoDesc, updateActiveCoDesc] = useState(null);
  const [pi, updatePi] = useState(null);
  const [activePo, updateActivePo] = useState(null);
  const [poKeys, updatePoKeys] = useState([]);
  const [dept, updateDept] = useState(null);
  const [matchedData, updateMatchedData] = useState({});
  const [reqData, updateReqData] = useState({});
  const [poData, updatePoData] = useState([]);
  const [pisSubmitted, updatePisSubmitted] = useState(false);
  const [err, updateErr] = useState(false);

  let interval;

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDeptData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.CAMPUS_SETUP_DEPT_INFO,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updateDept(response["data"]["result"]);
      } else {
        updateApiReq(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeBtn = () => {
    const { clsBtn, updateMatrix } = props;
    try {
      if (pi !== null) {
        const piKeys = Object.keys(pi);

        const piData = {};

        for (let each = 1; each <= piKeys.length; each++) {
          piData[`CO${each}`] = pi[`${piKeys[each - 1]}`];
        }

        pisSubmitted && updateMatrix(piData);
      }

      clsBtn();
    } catch (err) {
      console.log(err);
    }
  };

  const updateMatchedPi = async () => {
    updateLoading(true);
    const token = Cookies.get("jwt_token");
    try {
      const req = [];

      for (let item in reqData) {
        req.push({
          piTblId: item,
          updatedPIVal: reqData[item]["value"],
          coStatementName: reqData[item]["co"],
        });
      }

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(req),
      };

      const response = await fetch(
        apiClient.urls.fatcat.FACULTY_JUSTIFICATION_UPDATE +
          `?courseId=${details["courseId"]}`,
        options
      );
      // console.log(response['data']['result']);

      const ans = await response.json();

      if (ans["result"] === "OK") {
        updateLoading(false);
        updatePisSubmitted(true);
        getPi();
      } else {
        updateLoading(false);
        alert("Changes not Updated!!!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderErrorScreen = () => (
    <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
      <img src="/assets/errorsScreen.svg" alt="Oops" width={160} height={160} />
      <h1 className="text-[#606981] font-medium font-Lato-normal mt-4 text-xl">
        Something went wrong. Please try again later.
      </h1>
    </div>
  );

  const getPi = async () => {
    updateApiReq(true);
    const token = Cookies.get("jwt_token");
    const cosData = [];
    try {
      for (let each of cosDetails) {
        cosData.push({
          coStatement: each["coDesc"],
          coStatementName: each["coName"],
        });
      }

      const deptCode = dept.filter(
        (each) => each["deptName"] === details["branch"]
      );

      const req = {
        courseId: details["courseId"],
        piDeptCode: deptCode[0]["piDeptCode"],
        picoStatements: cosData,
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(req),
      };

      const response = await fetch(
        apiClient.urls.fatcat.FACULTY_JUSTIFICATION,
        options
      );
      if (response.ok) {
        const ans = await response.json();

        if (ans["statusCode"] === "OK") {
          updatePi(ans["result"]);
          updateApiReq(false);
          clearInterval(interval);
        } else {
          updatePi(null);
        }
      } else {
        if (`${response.status}`.startsWith("5")) {
          updateLoading(false);
          updateErr(true);
        } else {
          updatePi(null);
        }
      }
    } catch (err) {
      console.log(err);
      updateLoading(false);
      updateErr(true);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      try {
        const { info, cos } = props;
        updateDetails(info);
        updateCosDetails(cos);

        if (cos.length > 0) {
          updateActiveCo(cos?.[0]?.["coName"]);
          updateActiveCoDesc(cos?.[0]?.["coDesc"]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        getDeptData();
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        const keys = Object.keys(pi[activeCo]);
        const filteredKeys = keys.filter((each) => each.startsWith("PO"));
        updatePoKeys(filteredKeys);
        updateActivePo(filteredKeys[0]);
      } catch (err) {
        console.log(err);
      }
    }
  }, [pi, activeCo]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        if (activeCo !== null && activeCoDesc !== null && dept !== null) {
          getPi();
          interval = setInterval(() => {
            apiReq && getPi();
          }, 10000);

          if (!apiReq) {
            clearInterval(interval);
          }

          getPoData();
        }
      } catch (err) {
        console.log(err);
      }
    }

    return () => clearInterval(interval);
  }, [dept]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        const a = {};

        for (let m of Object.keys(pi[activeCo]).filter((item) =>
          item.startsWith("PO")
        )) {
          for (let i of Object.keys(pi[activeCo][m]).filter((item) =>
            item.startsWith("CA")
          )) {
            for (let j of Object.keys(pi[activeCo][m][i]).filter((item) =>
              item.startsWith("PI")
            )) {
              a[pi[activeCo][m][i][j]["piTblId"]] =
                pi[activeCo][m][i][j]["isMatched"];
            }
          }
        }
        updateMatchedData(a);
        updateReqData([]);
      } catch (err) {
        console.log(err);
      }
    }
  }, [activeCo, pi]);

  const renderCoOptions = () => {
    const ans = [];
    try {
      for (const item of cosDetails) {
        ans.push(
          <option key={item["coId"]} value={item["coName"]}>
            {item["coDesc"]}
          </option>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const activeCoChanged = (event) => {
    updateActiveCoDesc(
      event.target.options[event.target.selectedIndex].textContent
    );
    updateActiveCo(event.target.value);
  };

  const activePoChanged = (event) => {
    updateActivePo(event.target.id);
  };

  const renderPoKeys = () => {
    let ans = [];

    try {
      for (let each of poKeys) {
        ans.push(
          <li
            key={`${activeCo}${each}`}
            onClick={activePoChanged}
            id={each}
            className={`${
              each === activePo
                ? `bg-[#456BF1] text-[#F5FAFE]`
                : `bg-[#F5FAFE] text-[#456BF1]`
            } po-btn`}
          >
            {each}
            <span
              key={`${activeCo}${each}`}
              id={each}
              onClick={activePoChanged}
              className="po-btn-count"
            >
              &nbsp;(
              {pi[activeCo][each]["poWeight"]})
            </span>
          </li>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const indicatorUpdateClicked = (event) => {
    try {
      updateMatchedData({
        ...matchedData,
        [event.target.id]:
          event.target.getAttribute("data-value") === "true" ? false : true,
      });

      updateReqData({
        ...reqData,
        [event.target.id]: {
          value:
            event.target.getAttribute("data-value") === "true" ? false : true,
          co: activeCo,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const renderCAsInd = (i) => {
    let ans = [];

    try {
      for (let j of Object.keys(pi[activeCo][activePo][i]).filter((item) =>
        item.startsWith("PI")
      )) {
        ans.push(
          <li key={pi[activeCo][activePo][i][j]["piTblId"]} className="ind-li">
            <span
              onClick={indicatorUpdateClicked}
              key={pi[activeCo][activePo][i][j]["piTblId"]}
              id={pi[activeCo][activePo][i][j]["piTblId"]}
              data-value={matchedData[pi[activeCo][activePo][i][j]["piTblId"]]}
              className={`${
                matchedData[pi[activeCo][activePo][i][j]["piTblId"]]
                  ? "green-indicators-button"
                  : "indicators-button"
              } mr-5 cursor-pointer`}
            >
              {j.slice(3)}
              {matchedData[pi[activeCo][activePo][i][j]["piTblId"]] && (
                <img
                  data-value={
                    matchedData[pi[activeCo][activePo][i][j]["piTblId"]]
                  }
                  key={pi[activeCo][activePo][i][j]["piTblId"]}
                  id={pi[activeCo][activePo][i][j]["piTblId"]}
                  src="/assets/circle-tick.svg"
                  alt="tick"
                  className="tick"
                />
              )}
            </span>
            {pi[activeCo][activePo][i][j]["piDesc"]}
          </li>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const renderTable = () => {
    let ans = [];
    try {
      for (let i of Object.keys(pi[activeCo][activePo]).filter((item) =>
        item.startsWith("CA")
      )) {
        ans.push(
          <div className="table-item flex">
            <p className="competency text-left border border-0 border-r-2 border-[#B8C6F0]">
              <span
                className={`${
                  pi[activeCo][activePo][i]["isMatched"]
                    ? "green-indicators-button"
                    : "indicators-button"
                }  mb-2`}
              >
                {i.slice(3)}{" "}
                {pi[activeCo][activePo][i]["isMatched"] && (
                  <img
                    src="/assets/circle-tick.svg"
                    alt="tick"
                    className="tick"
                  />
                )}
              </span>
              {pi[activeCo][activePo][i]["competencyName"]}
            </p>

            <ul className="list-none indicators flex flex-col m-0 p-0">
              {renderCAsInd(i)}
            </ul>
          </div>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const renderView = () => {
    try {
      return (
        <div className="table-container mt-6">
          <div className="flex">
            <span className="w-3/4 pr-4 border border-0 border-r-2 border-[rgba(184, 198, 240, 0.5)]">
              <p className="statement-title ml-5 pt-4">
                Program Outcome {activePo.slice(2)} Statement
              </p>
              <p className="po-statement pb-5">
                <span className="po-statement-title">
                  {
                    poData
                      .filter((each) => each["po"] === activePo)[0]
                      ["poDesc"].split(":")[0]
                  }
                  :{" "}
                </span>
                {
                  poData
                    .filter((each) => each["po"] === activePo)[0]
                    ["poDesc"].split(":")[1]
                }
              </p>
            </span>
            <div className="flex flex-col w-1/4">
              <div className="flex items-center ml-5 mt-2 w-full">
                <p className="po-metrics-values">
                  <span className="po-metrics">Level</span>
                  <br />
                  {pi[activeCo][activePo]["poWeight"]}
                </p>

                <p className="po-metrics-values">
                  <span className="po-metrics">Percentage</span>
                  <br />
                  {pi[activeCo][activePo]["piPct"]}%
                </p>
              </div>

              <div className="flex items-center ml-5 mt-2 w-full">
                <p className="po-metrics-values">
                  <span className="po-metrics">MPI</span>
                  <br /> {pi[activeCo][activePo]["mpi"]}
                </p>

                <p className="po-metrics-values">
                  <span className="po-metrics">TPI</span>
                  <br />
                  {pi[activeCo][activePo]["tpi"]}
                </p>
              </div>
            </div>
          </div>
          <table className="table">
            <tr className="w-full flex flex-row items-center">
              <th className="comp-header text-left border border-0 border-r-2 border-[#B8C6F0]">
                Competency
              </th>
              <th className="ind-header text-left">Indicators</th>
            </tr>
          </table>
          <div className="content">{renderTable()}</div>
        </div>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const renderCoTitle = () => {
    try {
      return activeCo.split(" ")[0].slice(2);
    } catch (err) {
      console.log(err);
    }
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className="empty-view">
        No Data Found For The Selected Filters To View Justification
      </p>
    </div>
  );

  const poscrollTbClickedLeft = () => {
    const container = document.getElementById("poScroll");
    sideScroll(container, "left", 25, 100, 400);
  };

  const poscrollTbClicked = () => {
    const container = document.getElementById("poScroll");
    sideScroll(container, "right", 25, 100, 400);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <span className="flex items-center justify-between">
        <span className="flex items-center">
          <h1 className="overlay-title mb-2 mr-8">CO-PO Mapping</h1>
          <h2 className="overlay-subtitle">
            {details["courseShortName"]} &nbsp; {`(${details["courseName"]})`}
            &nbsp; {`${details["courseCode"]}`}
          </h2>
        </span>

        <h2 className="overlay-branch-text">
          {details["branch"]}&nbsp; {`(${details["batch"]})`}
        </h2>
      </span>

      <div className="statements-filter mt-5 pt-3">
        <p className="statement-title ml-3">
          Statement &nbsp; {renderCoTitle()}
        </p>
        <select
          onChange={activeCoChanged}
          value={activeCo}
          className="filter-select focus:ring-0 mt-2 text-ellipsis overflow-hidden"
        >
          {renderCoOptions()}
        </select>
      </div>

      <div className="mt-4">
        <ul
          id="poScroll"
          className="list-none flex items-center overflow-x-auto"
        >
          {renderPoKeys()}
        </ul>
        {toggleButtons("poScroll") && (
          <span className="flex justify-end pb-5">
            <span
              onClick={poscrollTbClickedLeft}
              className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={poscrollTbClicked}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>
        )}
      </div>
      {err ? (
        renderErrorScreen()
      ) : apiReq ? (
        <span className="flex flex-row justify-center items-center mt-10 mb-5">
          <Oval
            height={50}
            width={50}
            color="#456bf1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#9fb2f6"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </span>
      ) : pi === null ? (
        renderEmptyView()
      ) : (
        renderView()
      )}
      <span className="flex flex-row justify-end items-end mt-10 mb-5">
        <button
          onClick={closeBtn}
          className={`justification-btn self-end bg-[#E2E9FF] rounded-2xl px-8 py-1.5 mr-5 mt-4`}
          type="button"
        >
          Close
        </button>
        {pi !== null && !apiReq && (
          <button
            onClick={updateMatchedPi}
            disabled={isLoading || apiReq}
            className={`${
              (isLoading || apiReq) && "cursor-not-allowed"
            } font-Lato-normal self-end text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mt-4`}
            type="button"
          >
            {isLoading ? (
              <Oval
                height={20}
                width={20}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#FFF"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Submit Updates"
            )}
          </button>
        )}
      </span>
    </>
  );
};

export default CoPoMapping;
