import styles from "./index.module.css";

const SmallerDevice = () => (
  <div className={styles["small-devices"]}>
    <div
      className={`${styles["fatcat-logo-container"]} flex flex-row items-center`}
    >
      <img src="/assets/logo.svg" alt="Fatcat" height={36} width={39} />
      <img
        className="ml-3"
        src="/assets/fatcatTitle.svg"
        alt="Fatcat"
        height={18}
        width={81}
      />
    </div>
    <h1>
      Please zoom out and refresh the page to continue using our software.
      <br />
      It looks like you're using an older system or a smaller screen device.
    </h1>
  </div>
);

export default SmallerDevice;
