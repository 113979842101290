import { Redirect } from "react-router-dom";
import { useState, useEffect } from "react";
import { HiLockClosed } from "react-icons/hi";
import apiClient from "../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import OtpContext from "../../context/OtpContext";
import CryptoJS from "crypto-js";
import "../LoginForm/index.css";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

const userData = {
  new: "",
  confirm: "",
};

const ForgotPassword = (props) => {
  const [userInput, updateUserInput] = useState(userData);
  const [username, updateUsername] = useState("");
  const [disable, updateDisable] = useState(true);
  const [credentials, updateCredentials] = useState(false);
  const [oHeader, updateOHeader] = useState("");
  const [pwdHidden, updatePwdHidden] = useState(true);

  useEffect(() => {
    validDetails();
  }, [userInput]);

  useEffect(() => {
    const token = Cookies.get("jwt_token");
    const { history } = props;
    if (token !== undefined) {
      history.replace("/obe/dashboard");
    }
  }, []);

  const validDetails = () => {
    if (userInput.new !== "" && userInput.confirm !== "") {
      updateDisable(false);
    } else {
      updateDisable(true);
    }
  };

  const valueChanged = (event) => {
    const data = event.target.value;
    const inputName = event.target.name;

    switch (inputName) {
      case "new":
        updateUserInput({ ...userInput, new: data });
        break;
      case "confirm":
        updateUserInput({ ...userInput, confirm: data });
        break;
      default:
        break;
    }
  };

  const formSubmitted = async (event) => {
    event.preventDefault();

    try {
      const { history } = props;
      if (userInput.new !== userInput.confirm) {
        updateCredentials(true);
      } else {
        updateCredentials(false);
        updateDisable(true);
        const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;
        const encrypted = CryptoJS.AES.encrypt(userInput.new, key).toString();
        // console.log(oHeader);

        const response = await apiClient.get(
          apiClient.urls.fatcat.RESET_PASSWORD +
            `?username=${username}&newPassword=${encrypted}`,
          { Authorization: oHeader }
        );

        if (response["data"]["statusCode"] === "OK") {
          <OtpContext.Consumer>
            {(value) => {
              const { headerChange, updateVerified } = value;
              updateVerified("");
              headerChange("");
            }}
          </OtpContext.Consumer>;
          alert(response["data"]["description"]);
          history.replace("/login");
        } else {
          alert(response["data"]["description"]);
          history.replace("/login");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loginBtn = disable ? "login-btn-disabled" : "login-btn";

  return (
    <OtpContext.Consumer>
      {(value) => {
        const { verified, header } = value;
        updateUsername(verified);
        updateOHeader(header);

        return verified !== "" ? (
          <div className="login-page">
            <div className="form-container">
              <div className="logo">
                <img
                  alt="logo"
                  src="/assets/logo.svg"
                  height={270}
                  width={300}
                  layout="intrinsic"
                  priority
                />
              </div>
              <span className="text">
                <img
                  src="/assets/fatcat-title.svg"
                  width={200}
                  height={45}
                  alt=""
                />
                <p className="para">Empowering Educational Technology.</p>
                <button type="button" className="read-more-btn">
                  Read More
                </button>
              </span>

              <span className="ellip">
                <img
                  src="/assets/ellipse.svg"
                  width={200}
                  height={150}
                  alt="ellipse"
                />
              </span>
            </div>

            <form className="login-form" onSubmit={formSubmitted}>
              <p className="paragraph">Update Password</p>

              <div className="input-bg">
                <HiLockClosed className="icons" />
                <input
                  autoComplete="off"
                  name="new"
                  onChange={valueChanged}
                  value={userInput.new}
                  className="input focus:ring-0"
                  type={pwdHidden ? "password" : "text"}
                  placeholder="New Password"
                />
                <button
                  onClick={() => {
                    updatePwdHidden(!pwdHidden);
                  }}
                  type="button"
                >
                  {pwdHidden ? (
                    <IoEyeSharp className="hide-icon" />
                  ) : (
                    <IoEyeOffSharp className="hide-icon" />
                  )}
                </button>
              </div>

              <div className="input-bg">
                <HiLockClosed className="icons" />
                <input
                  name="confirm"
                  value={userInput.confirm}
                  onChange={valueChanged}
                  className="input focus:ring-0"
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>
              {credentials && (
                <p className="error text-red-500">
                  Both the passwords must be same
                </p>
              )}
              <button disabled={disable} type="submit" className={loginBtn}>
                Update
              </button>
            </form>
          </div>
        ) : (
          <Redirect to="/login" />
        );
      }}
    </OtpContext.Consumer>
  );
};

export default ForgotPassword;
