import { Link, withRouter } from "react-router-dom";
import * as jose from "jose";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import "./index.css";

const SideBar = (props) => {
  const { match } = props;
  const { path } = match;

  const [claims, updateClaims] = useState({});

  useEffect(() => {
    const token = Cookies.get("jwt_token");

    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateClaims(claim);
    }
  }, []);

  return (
    <div className="side-bar bg-white">
      <div className="fatcat-logo-container flex flex-row justify-around items-center ml-2 mr-2">
        <img src="/assets/logo.svg" alt="Fatcat" height={20} width={30} />
        <img
          src="/assets/fatcatTitle.svg"
          alt="Fatcat"
          className="fatcat-title"
        />
      </div>
      <ul className="list-style-none">
        {claims["user_type"] !== "FACULTY" && (
          <Link to="/obe/dashboard">
            <li
              className={`${
                path.startsWith("/obe") ? "sidebar-item-active" : "sidebar-item"
              } flex py-3 pl-4 text-white mt-1`}
            >
              <img
                src={
                  path.startsWith("/obe")
                    ? "/assets/obe.svg"
                    : "/assets/ObeInActive.svg"
                }
                alt="obe"
                height={18}
                width={16}
              />
              <span
                className={`${
                  path.startsWith("/obe")
                    ? "sidebar-text-active"
                    : "sidebar-text"
                } ml-3`}
              >
                OBE
              </span>
            </li>
          </Link>
        )}
        {claims["user_type"] === "FACULTY" && (
          <Link to="/faculty/dashboard/managecourses">
            <li
              className={`${
                path.startsWith("/faculty")
                  ? "sidebar-item-active"
                  : "sidebar-item"
              } flex py-3 pl-4 text-white mt-3`}
            >
              <img
                src={
                  path.startsWith("/faculty")
                    ? "/assets/facActive.svg"
                    : "/assets/facInactive.svg"
                }
                alt="faculty"
                height={18}
                width={18}
              />
              <span
                className={`${
                  path.startsWith("/faculty")
                    ? "sidebar-text-active"
                    : "sidebar-text"
                } ml-3 mt-1 `}
              >
                Home
              </span>
            </li>
          </Link>
        )}
        {/* <Link to="/connect/dashboard">
          <li
            className={`${
              path.startsWith("/connect")
                ? "sidebar-item-active"
                : "sidebar-item"
            } flex py-3 pl-4 text-white mt-1`}
          >
            <img
              src={
                path.startsWith("/connect")
                  ? "/assets/connectOutline.svg"
                  : "/assets/connectFill.svg"
              }
              alt="Connect"
              height={18}
              width={16}
            />
            <span
              className={`${
                path.startsWith("/connect")
                  ? "sidebar-text-active"
                  : "sidebar-text"
              } ml-3`}
            >
              Connect
            </span>
          </li>
        </Link> */}
      </ul>
      <Link
        to="/feedback"
        className={`${
          path.startsWith("/feedback") && "feed-container-active"
        } feed-container`}
      >
        <span className="feed-back-icon-container">
          <img src="/assets/feedback.svg" alt="Feedback" />
        </span>
        <p className="fb-text">Feedback & Support</p>
      </Link>
    </div>
  );
};

export default withRouter(SideBar);
