import styles from "./index.module.css";
import MappingCoursesList from "./MappingCoursesList";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";
import FiltersContext from "../../../../../context/FiltersContext";
import roles from "../../../../roles";

const FacultyMappingDetails = (props) => {
  const { filtersBatch, filtersBranch, filtersSem } =
    useContext(FiltersContext);

  const [filters, updateFilters] = useState({
    batch: "",
    branch: "",
    semester: "",
    section: "",
  });

  const { closeButton, id, fetchData, facNumId } = props;

  const [data, updateData] = useState([]);
  const [content, updateContent] = useState("loading");
  const [dropBatchOptions] = useState(filtersBatch);
  const [dropBranchOptions] = useState(filtersBranch);
  const [dropSemOptions] = useState(filtersSem);
  const [dropSectionOptions, UpdateSectionOptions] = useState([]);
  const [loader, updateLoader] = useState(true);

  const [userType, updateType] = useState("");

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batch = dropBatchOptions.filter(
        (each) => `${each.optionValue}` === `${filters.batch}`
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${batch?.[0]?.["optionDesc"] ?? ""}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateSectionOptions(response["data"]["result"]);
      } else {
        UpdateSectionOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSectionOptions();
  }, [filters.batch, filters.branch]);

  const getOptions = async () => {
    try {
      if (userType === "FRESHMAN") {
        updateFilters({
          ...filters,
          semester: filtersSem[0]["optionValue"],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourses = async () => {
    updateLoader(true);
    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_MANAGE_COURSES_LIST +
          `?batch=${filters.batch}&branch=${filters.branch}&regulation=&semester=${filters.semester}&section=${filters.section}&username=${id}`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        updateData(response["data"]["result"]["courseDetailsResponses"]);
        updateContent(true);
      } else {
        updateData([]);
        updateContent(false);
      }
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim["user_type"]);
        if (claim["user_type"] === "HOD") {
          updateFilters({ ...filters, branch: claim["dept_name"] });
        } else {
          updateFilters({ ...filters, branch: "" });
        }
        getOptions();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        getCourses();
      }
    }
  }, [filters]);

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value, section: "" });
  };

  const batchChanged = (event) => {
    const val = event.target.value;
    updateFilters({
      ...filters,
      batch: `${filters.batch}` === `${val}` ? "" : val,
      section: "",
    });
  };

  const semesterChanged = (event) => {
    const val = event.target.value;

    updateFilters({
      ...filters,
      semester: `${filters.semester}` === `${val}` ? "" : val,
    });
  };

  const sectionChanged = (event) => {
    const val = event.target.id;

    updateFilters({
      ...filters,
      section: `${filters.section}` === `${val}` ? "" : val,
    });
  };

  const renderEmptyCourses = () => (
    <div className="relative flex flex-col top-1/4 items-center mt-20">
      <img
        src="/assets/cos.svg"
        width={361}
        height={38}
        alt="COURSE OUTCOMES"
      />
      <p className={styles["no-courses-text"]}>
        You Don&apos;t Have Any Courses Created For This Faculty
      </p>
    </div>
  );

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <li
            className={
              `${filters.batch}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            value={item["optionValue"]}
            onClick={batchChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      if (userType["user_type"] === "FRESHMAN") {
        ans.push(
          <li
            className={
              `${filters.semester}` === `${dropSemOptions[0]["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[0]["optionValue"]}
            value={dropSemOptions[0]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[0]["optionDesc"]}
          </li>
        );

        ans.push(
          <li
            className={
              `${filters.semester}` === `${dropSemOptions[1]["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[1]["optionValue"]}
            value={dropSemOptions[1]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[1]["optionDesc"]}
          </li>
        );
      } else {
        for (const item of dropSemOptions) {
          ans.push(
            <li
              className={
                `${filters.semester}` === `${item["optionValue"]}`
                  ? styles["batches-ul-active"]
                  : "bg-[#fff] text-[#606981]"
              }
              key={item["optionValue"]}
              value={item["optionValue"]}
              onClick={semesterChanged}
            >
              {item["optionDesc"]}
            </li>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSectionOptions = () => {
    const ans = [];
    try {
      for (const item of dropSectionOptions) {
        ans.push(
          <li
            className={
              `${filters.section}` === `${item}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item}
            id={item}
            onClick={sectionChanged}
          >
            {item}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMapping = async (courseId) => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.DELETE_COURSE_MAPPING +
          `?courseId=${courseId}&facultyId=${facNumId}`,
        options
      );

      if (response.ok === true) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          await getCourses();
          await fetchData();
          alert("Course Mapping deleted successfully");
        } else {
          alert("Oops! Course Mapping not deleted");
        }
      } else {
        alert("Oops! Course Mapping not deleted");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderInitialView = () => {
    try {
      switch (content) {
        case true:
          return data.map((each) => (
            <MappingCoursesList
              item={each}
              key={each.courseId}
              deleteMapping={deleteMapping}
            />
          ));
        case false:
          return renderEmptyCourses();
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const batchTabLeft = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "left", 25, 150, 20);
  };

  const batchTab = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "right", 25, 150, 20);
  };

  const semTabLeft = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "left", 25, 100, 20);
  };

  const semTab = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "right", 25, 100, 20);
  };

  const secTabLeft = () => {
    const container = document.getElementById("sec-tabs");
    sideScroll(container, "left", 25, 80, 20);
  };

  const secTab = () => {
    const container = document.getElementById("sec-tabs");
    sideScroll(container, "right", 25, 80, 20);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <div className="overflow-x-hidden m-0">
      <div className={`${styles["students-bg"]} mb-2 pl-1`}>
        {!(userType === roles.hod) && (
          <div className={`${styles["students-filters"]} w-32 ml-2`}>
            <select
              value={filters.branch}
              className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mt-7 mb-1.5 pr-5`}
              onChange={branchChanged}
            >
              <option value="">Branch</option>
              {renderBranchOptions()}
            </select>
          </div>
        )}

        <div className={`${styles["students-filters"]} ml-1 w-5/12`}>
          <h1 className={`${styles["filters-title"]}`}>Choose Batch</h1>
          <div className="flex relative">
            <button onClick={batchTabLeft} className={styles["arrow-buttons"]}>
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <ul id="batch-tabs" className={styles["batches-ul"]}>
              {renderBatchOptions()}
            </ul>

            <button
              onClick={batchTab}
              className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
            >
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>

        <div className={`${styles["students-filters"]} ml-1 w-3/12`}>
          <h1 className={`${styles["filters-title"]}`}>Semester</h1>

          <div className="flex relative">
            <button onClick={semTabLeft} className={styles["arrow-buttons"]}>
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <ul
              id="sem-tabs"
              className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
            >
              {renderSemOptions()}
            </ul>

            <button
              onClick={semTab}
              className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
            >
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>

        {dropSectionOptions.length > 0 && (
          <div className={`${styles["students-filters"]} ml-1 w-36`}>
            <h1 className={`${styles["filters-title"]}`}>Section</h1>

            <div className="flex relative">
              <button onClick={secTabLeft} className={styles["arrow-buttons"]}>
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul
                id="sec-tabs"
                className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
              >
                {renderSectionOptions()}
              </ul>

              <button
                onClick={secTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-row justify-between items-center mr-0 mb-5">
        <h1 className={styles["total-courses"]}>
          TOTAL COURSES : {content ? data.length : 0}
        </h1>
      </div>

      {loader ? (
        <div className="flex justify-center mt-10">
          <Oval
            height={50}
            width={50}
            color="#3d65f4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#3d65f4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <ul
          className={`${
            !content ? "justify-center" : "justify-between"
          } list-none w-full flex flex-row flex-wrap`}
        >
          {renderInitialView()}
        </ul>
      )}

      <div className="flex justify-end">
        <button
          onClick={closeButton}
          className="font-Lato-normal text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-14 py-1.5 mr-5 mt-4"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default FacultyMappingDetails;
