import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import roles from "../../../../roles";

const ProjectListItems = (props) => {
  const [popUp, updatePopup] = useState(false);
  const [popUpDelete, updatePopUpDelete] = useState(false);
  const { item, editBtn, fetchProjects, userdata } = props;

  const { id, name, branch, endDate, priority, description } = item;

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const courseEdit = () => {
    editBtn(item);
    popUpBtn();
  };

  const deleteCourseItem = async () => {
    const options = {
      method: "DELETE",
      headers,
    };

    try {
      const deleteProject = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_PROJECTS + `/${id}`,
        options
      );

      if (deleteProject.ok) {
        fetchProjects();
        alert("Project Deleted!");
        closeDeleteModal();
        popUpBtn();
      } else {
        alert("Oops! Project not deleted");
      }
    } catch (err) {
      alert("Oops! Project not deleted");
      console.log(err);
    }
  };

  const renderDeletePopup = () => (
    <div className={`${styles["modal"]} rounded-lg flex flex-col`}>
      <p className={`${styles["course-title"]} text-center mb-10`}>
        {`Do you want to delete the ${name} project?`}
      </p>
      <span>
        <button
          onClick={closeDeleteModal}
          className="mr-9 cursor-pointer text-blue-600 font-medium bg-[#F4F9FF] rounded px-6 py-2 border border-blue-600"
        >
          No
        </button>
        <button
          onClick={deleteCourseItem}
          className="cursor-pointer text-blue-600 font-medium bg-red-600 text-white rounded px-6 py-2 border"
        >
          Yes
        </button>
      </span>
    </div>
  );

  const closeDeleteModal = () => {
    updatePopUpDelete(false);
  };

  const popUpBtn = () => {
    updatePopup(!popUp);
  };

  const popUpDeleteBtn = () => {
    updatePopUpDelete(!popUpDelete);
  };

  const getPriority = () => {
    if (priority === 1) {
      return `\uD83D\uDC4D Low Priority`;
    } else if (priority === 2) {
      return `\uD83D\uDD25 Medium Priority`;
    } else {
      return `\uD83D\uDD25 High Priority`;
    }
  };

  const progressStyle = {
    width: `${60}%`,
  };

  const overlayStyle = { background: "rgba(0,0,0,0.5)", position: "absolute" };

  const priorityStyle = priority === 1 ? "priority-low" : "priority-high";

  return (
    <li className={styles["project-list-item"]}>
      <div className="flex justify-between items-start">
        <h1>
          {name} - {branch}
        </h1>
        {(userdata?.user_type === roles.principal ||
          userdata?.user_type === roles.hod) && (
          <button onClick={popUpBtn} type="button">
            <img src="/assets/dots.svg" alt="dots" className="rotate-90" />
          </button>
        )}
        {popUp && (
          <div className={`${styles["course-edit-popup"]}`}>
            <button
              onClick={courseEdit}
              className={`flex items-center cursor-pointer`}
            >
              <span>
                <RiPencilFill className="text-blue-600 mr-3" size={18} />
              </span>
              Edit
            </button>

            <button
              onClick={popUpDeleteBtn}
              className={`flex items-center cursor-pointer`}
            >
              <span>
                <MdDeleteOutline className="text-red-600 mr-3" size={18} />
              </span>
              Delete
            </button>

            <Popup
              open={popUpDelete}
              onClose={closeDeleteModal}
              {...{ overlayStyle }}
            >
              {renderDeletePopup()}
            </Popup>
          </div>
        )}
      </div>

      <div className={`${styles["priority-box"]} ${styles[priorityStyle]} `}>
        <p>{getPriority()}</p>
      </div>

      <p>{description}</p>

      <div className={styles["task-done"]}>
        <img src="/assets/networkLink.svg" alt="link" className="mr-4" />
        <p>Task Done - 17/20</p>
      </div>

      <div className={styles["progress-bar-container"]}>
        <div className={styles["progress-bar"]} style={progressStyle}>
          &nbsp;
        </div>
      </div>

      <div className={styles["profile-dp-container"]}>
        <img
          src="/assets/profileDp.svg"
          alt="dp"
          className={styles["profile-dp"]}
        />
        <img
          src="/assets/profileDp.svg"
          alt="dp"
          className={styles["profile-dp"]}
        />
        <img
          src="/assets/profileDp.svg"
          alt="dp"
          className={styles["profile-dp"]}
        />

        <span className={styles["profile-count"]}>+3</span>
      </div>

      <div className="flex items-center mt-5">
        <img src="/assets/timer.svg" alt="timer" className="mr-2" />
        <p className={styles["end-date"]}>End date : {endDate}</p>
      </div>

      <Link
        to={`/connect/dashboard/criteria/${id}`}
        className={styles["criteria-btn"]}
      >
        View all criteria
      </Link>
    </li>
  );
};

export default ProjectListItems;
