import { Component } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.css";

class WeightsAndTargets extends Component {
  state = {
    data: null,
    internalData: [],
    externalData: [],
    seeWeightages: {},
    loading: false,
    content: false,
    intWeight: "",
    extWeight: "",
    directCoW: "",
    indirectCoW: "",
    intTotAtt: [],
    thValues: [],
  };

  abortController = null;
  AbortControllerIntTotAtt = null;
  AbortControllerThValues = null;

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }

    if (this.AbortControllerIntTotAtt) {
      this.AbortControllerIntTotAtt.abort();
    }

    if (this.AbortControllerThValues) {
      this.AbortControllerThValues.abort();
    }
  }

  componentDidUpdate(prevProps) {
    const { courseCode, sec, activeCourse } = this.props;
    const {
      cieW = "",
      seeW = "",
      directCoW = "",
      indirectCoW = "",
    } = activeCourse;

    if (prevProps.courseCode !== courseCode || prevProps.sec !== sec) {
      this.getData();
      this.getThValues();
      this.getTotalAttData();
    }

    if (prevProps.activeCourse !== activeCourse) {
      this.setState({
        intWeight: cieW,
        extWeight: seeW,
        directCoW: directCoW,
        indirectCoW: indirectCoW,
      });
    }
  }

  componentDidMount() {
    if (navigator.onLine) {
      const { data } = this.state;
      const { activeCourse } = this.props;
      const {
        cieW = "",
        seeW = "",
        directCoW = "",
        indirectCoW = "",
      } = activeCourse;

      if (Cookies.get("jwt_token") !== undefined) {
        this.getData();
        this.fetchWeightages();
        this.getTotalAttData();
        this.getThValues();

        data !== null && this.tableStruct();
        this.setState({
          intWeight: cieW,
          extWeight: seeW,
          directCoW: directCoW,
          indirectCoW: indirectCoW,
        });
      }
    }
  }

  getData = async () => {
    try {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();
      this.setState({
        data: null,
        content: false,
        internalData: [],
        externalData: [],
        seeWeightages: {},
      });

      const { courseCode, sec, secOptions } = this.props;
      const joinedSec = secOptions.join();

      const sectionsVal = sec === "" ? joinedSec : sec;

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS +
            `?courseId=${courseCode}&sectionList=${sectionsVal}`,
          { Authorization: token },
          {
            signal: this.abortController.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ data: response["data"]["result"] }, this.tableStruct);
        } else {
          this.setState({ data: null, content: true });
        }
      } else {
        this.setState({ data: null, content: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getTotalAttData = async () => {
    try {
      if (this.AbortControllerIntTotAtt) {
        this.AbortControllerIntTotAtt.abort();
      }

      this.AbortControllerIntTotAtt = new AbortController();
      this.setState({
        intTotAtt: [],
      });

      const { courseCode, sec, secOptions } = this.props;
      const joinedSec = secOptions.join();

      const sectionsVal = sec === "" ? joinedSec : sec;

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_ATTAINMENTS_INTERNAL_COURSE_ATT_DETAILS +
            `?courseId=${courseCode}&sectionList=${sectionsVal}`,
          { Authorization: token },
          {
            signal: this.AbortControllerIntTotAtt.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ intTotAtt: response["data"]["result"] });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  tableStruct = () => {
    try {
      const { data } = this.state;

      const { courseStructure } = data;
      const item = courseStructure;

      const intTheory =
        item.intTheoryShortList === null
          ? ""
          : item.intTheoryShortList.split(",");

      const intPract =
        item.intPractShortList === null
          ? ""
          : item.intPractShortList.split(",");

      const intProj =
        item.intProjShortList === null ? "" : item.intProjShortList.split(",");

      const internal = intTheory.concat(intPract).concat(intProj);

      const internalValues = [];

      for (const each of internal) {
        if (each !== "") {
          internalValues.push(each);
        }
      }

      const extTheory =
        item.extTheoryShortList === null
          ? ""
          : item.extTheoryShortList.split(",");

      const extPract =
        item.extPractShortList === null
          ? ""
          : item.extPractShortList.split(",");
      const extProj =
        item.extProjShortList === null ? "" : item.extProjShortList.split(",");

      const external = extTheory.concat(extPract).concat(extProj);

      const externalValues = [];

      for (const each of external) {
        if (each !== "") {
          externalValues.push(each);
        }
      }

      this.setState({
        internalData: internalValues,
        externalData: externalValues,
        content: true,
      });
    } catch (err) {
      console.log(err);
    }
    this.fetchWeightages();
  };

  getIntTd = () => {
    const { internalData } = this.state;
    const ans = [];
    try {
      for (const each of internalData) {
        ans.push(
          <th className="border border-blue-500 p-2 text-[#040C58] bg-[#EFF3FF] max-w-1/6">
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getExtTd = () => {
    const { externalData } = this.state;

    const ans = [];

    try {
      for (const each of externalData) {
        ans.push(
          <th className="border border-blue-500 p-2 text-[#040C58] bg-[#EFF3FF] max-w-1/6">
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderIntTotalAttData = () => {
    const { data, internalData, intTotAtt, thValues } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2 max-w-1/6">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
            </th>

            <>
              {internalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
                >
                  {data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]
                    ? `${
                        data?.["coAttData"]?.[`CO${index + 1}`]?.[
                          "daData"
                        ]?.map((each) => {
                          if (
                            each["assmntCat"] === `Internal` &&
                            each["templateName"] === e
                          ) {
                            return each["pctGtThValue"];
                          }
                          return "";
                        }) ?? ""
                      }`.replaceAll(",", "")
                    : ""}
                </td>
              ))}
            </>

            <td
              className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
            >
              <input
                id={`CO${index + 1}-thVal`}
                type="text"
                className={styles["weightage-input"]}
                defaultValue={thValues?.[index]?.["thresholdPct"] ?? ""}
              />
              %
            </td>
            <td
              className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
            >
              {intTotAtt?.[index]?.["coAttPct"] ?? ""}
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  renderIntSeeWeightagesData = () => {
    const { data, internalData, seeWeightages } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2 max-w-1/6">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
            </th>

            <>
              {internalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
                >
                  <input
                    id={`CO${index + 1}-${e}`}
                    type="text"
                    className={styles["weightage-input"]}
                    onChange={this.changeWeightages}
                    value={seeWeightages?.[`CO${index + 1}`]?.[e] ?? ""}
                  />
                  %
                  <span className="text-[#BBBBBB] ml-5">
                    {data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]
                      ? `${
                          data?.["coAttData"]?.[`CO${index + 1}`]?.[
                            "daData"
                          ]?.map((each) => {
                            if (
                              each["assmntCat"] === `Internal` &&
                              each["templateName"] === e
                            ) {
                              return each["pctGtThValue"];
                            }
                            return "";
                          }) ?? ""
                        }`.replaceAll(",", "")
                      : ""}
                  </span>
                </td>
              ))}
            </>
            <td
              className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "avgCie"
              ] ?? ""}
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  renderExtSeeWeightagesData = () => {
    const { data, externalData, seeWeightages } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2 max-w-1/6">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
            </th>

            <>
              {externalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
                >
                  <input
                    className={styles["weightage-input"]}
                    onChange={this.changeWeightages}
                    id={`CO${index + 1}-${e}`}
                    type="text"
                    value={seeWeightages?.[`CO${index + 1}`]?.[e] ?? ""}
                  />
                  %
                  <span className="text-[#BBBBBB] ml-5">
                    {data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]
                      ? `${
                          data?.["coAttData"]?.[`CO${index + 1}`]?.[
                            "daData"
                          ]?.map((each) => {
                            if (
                              each["assmntCat"] === `External` &&
                              each["templateName"] === e
                            ) {
                              return each["pctGtThValue"];
                            }
                            return "";
                          }) ?? ""
                        }`.replaceAll(",", "")
                      : ""}
                  </span>
                </td>
              ))}
            </>

            <td
              className={`${styles["table-td"]} max-w-1/6 border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "avgSee"
              ] ?? ""}
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  renderTargets = () => {
    const { data } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        const inputVal =
          data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
            "targetAtt"
          ] ?? "";

        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2 max-w-1/6">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
            </th>

            <td
              key={"co" + index}
              className={`${styles["table-td"]} max-w-1/6 text-center border border-blue-500 p-2 text-[#606981]`}
            >
              <input
                defaultValue={inputVal}
                className={styles["weightage-input"]}
                onChange={this.changeWeightages}
                id={`CO${index + 1}-target`}
              />
              %
            </td>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  changeWeightages = (event) => {
    const { seeWeightages } = this.state;
    const id = event.target.id;

    const [co, template] = id.split("-");
    const data = {
      ...seeWeightages[co],
      [`${template}`]: event.target.value,
    };

    this.setState({ seeWeightages: { ...seeWeightages, [`${co}`]: data } });
  };

  storeAttCal = async () => {
    const { intWeight, extWeight, directCoW, indirectCoW } = this.state;

    const { courseCode, activeCourse } = this.props;

    try {
      const token = Cookies.get("jwt_token");
      if (parseFloat(intWeight) + parseFloat(extWeight) === parseFloat(100)) {
        if (
          parseFloat(directCoW) + parseFloat(indirectCoW) ===
          parseFloat(100)
        ) {
          this.setState({ coLoading: true });

          const reqBody = {
            cieW: intWeight,
            directCoW: directCoW,
            indirectCoW: indirectCoW,
            seeW: extWeight,
          };

          const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reqBody),
          };

          const response = await fetch(
            apiClient.urls.fatcat.UPDATE_CO_WEIGHTAGES +
              `?batchId=${activeCourse?.batchId ?? ""}&branch=${
                activeCourse?.branch ?? ""
              }&regulationId=${activeCourse?.regulationId ?? ""}&semester=${
                activeCourse?.semester ?? ""
              }&courseIdList=${courseCode}`,
            options
          );

          if (response.ok === true) {
            const res = await response.json();
            if (res["statusCode"] === "OK") {
              alert("CO weightages are updated successfully");
            } else {
              alert("CO weightages are not updated");
            }
          } else {
            alert("CO weightages are not updated");
          }

          this.setState({ coLoading: false });
        } else {
          alert(
            "Sum of both Direct CO and Indirect CO weightages should be equal to 100"
          );
        }
      } else {
        alert(
          "Sum of both Internal and External weightages should be equal to 100"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  storeTargets = async () => {
    const { seeWeightages } = this.state;
    const { courseCode } = this.props;

    const token = Cookies.get("jwt_token");

    const reqBody = [];

    Object.keys(seeWeightages).forEach((each) => {
      const val = document.getElementById(`${each}-target`).value;
      reqBody.push({
        co: each,
        coTargetW: val,
        courseId: courseCode,
      });
    });

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqBody),
    };

    try {
      const res = await fetch(
        apiClient.urls.fatcat
          .OBE_ATTAINMENTS_CO_SPECIFIC_TARGET_WEIGHTAGE_UPDATE,
        options
      );

      if (res.ok) {
        const response = await res.json();
        if (response["result"] === "OK") {
          alert("Targets Updated Successfully!");
          await this.fetchWeightages();
        } else {
          alert("Changes are not updated!");
        }
      } else {
        alert("Changes are not updated!");
      }
    } catch (err) {
      alert("Changes are not updated!");
      console.log(err);
    }
  };

  storeWeightages = async (event) => {
    const { seeWeightages, internalData, externalData } = this.state;
    const { courseCode } = this.props;

    const token = Cookies.get("jwt_token");

    const reqBody = [];

    const type = event.target.name === "internal" ? internalData : externalData;
    let terminate = false;

    for (let each of Object.keys(seeWeightages)) {
      let totalSum = 0;
      if (!terminate) {
        type.forEach((item) => {
          totalSum = totalSum + parseFloat(seeWeightages?.[each]?.[item] ?? 0);

          reqBody.push({
            co: each,
            coTemplateWeightage: seeWeightages?.[each]?.[item],
            courseId: courseCode,
            templateName: item,
          });
        });

        if (totalSum !== 100) {
          alert(
            `Sum of ${event.target.name} weightages of each CO should be equal to 100`
          );
          terminate = true;

          return;
        }
      } else {
        return;
      }
    }

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqBody),
    };

    try {
      const res = await fetch(
        apiClient.urls.fatcat.STORE_COURSE_WEIGHTAGES,
        options
      );

      if (res.ok) {
        const response = await res.json();
        if (response["result"] === "OK") {
          alert("Weightages Updated Successfully!");
          await this.fetchWeightages();
        } else {
          alert("Changes are not updated!");
        }
      } else {
        alert("Changes are not updated!");
      }
    } catch (err) {
      alert("Changes are not updated!");
      console.log(err);
    }
  };

  getThValues = async () => {
    try {
      if (this.AbortControllerThValues) {
        this.AbortControllerThValues.abort();
      }

      this.AbortControllerThValues = new AbortController();
      this.setState({
        thValues: [],
        loading: true,
      });

      const { courseCode } = this.props;

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_ATTAINMENTS_CO_SPECIFIC_TH_VALUE +
            `?courseId=${courseCode}`,
          { Authorization: token },
          {
            signal: this.AbortControllerThValues.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ thValues: response["data"]["result"] });
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({
      loading: false,
    });
  };

  fetchWeightages = async () => {
    const { data, internalData, externalData } = this.state;
    const { courseCode } = this.props;

    this.setState({ loading: true, seeWeightages: {} });
    const token = Cookies.get("jwt_token");
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const weightagesData = await fetch(
        apiClient.urls.fatcat.COURSE_WEIGHTAGES + `?courseId=${courseCode}`,
        options
      );

      const res = await weightagesData.json();
      if (res["statusCode"] === "OK") {
        const updatedData = {};

        for (
          let index = 1;
          index <= Object.keys(data["coAttData"]).length;
          index++
        ) {
          const dummy = {};
          internalData.map((each) => (dummy[each] = ""));
          externalData.map((each) => (dummy[each] = ""));
          updatedData[`CO${index}`] = dummy;

          res?.["result"]?.[`CO${index}`]?.forEach((item) => {
            updatedData[`CO${index}`][`${item["templateName"]}`] =
              item?.["coTemplateWeightage"];
          });
        }

        this.setState({ seeWeightages: updatedData });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>No Data Found To View</p>
    </div>
  );

  intWeightChange = (event) => {
    this.setState({ intWeight: event.target.value });
  };

  extWeightChange = (event) => {
    this.setState({ extWeight: event.target.value });
  };

  directCoWChange = (event) => {
    this.setState({ directCoW: event.target.value });
  };

  indirectCoWChange = (event) => {
    this.setState({ indirectCoW: event.target.value });
  };

  render() {
    const {
      data,
      internalData,
      externalData,
      loading,
      content,
      intWeight,
      extWeight,
      directCoW,
      indirectCoW,
    } = this.state;
    return !content || loading ? (
      <div className="flex justify-center items-center mt-20 mb-20">
        <Oval
          height={40}
          width={40}
          color="#3D65F4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3D65F4"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    ) : (
      <>
        <div className={styles["bg-containers"]}>
          <p className={styles["art-matrix-title"]}>
            Weightages Of Internals To Calculate Average
          </p>
          {Object?.keys(data?.coAttData ?? {})?.length > 0 ? (
            <>
              <table className="mb-2 mt-4 border-solid border-blue-600 border-1">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th className="border border-blue-500 p-2" rowSpan={2}>
                      Course Outcome
                    </th>
                    {internalData.length !== 0 && (
                      <th
                        className="border border-blue-500 p-2"
                        colSpan={internalData.length}
                      >
                        Internal
                      </th>
                    )}
                    <th className="border border-blue-500 p-2" rowSpan={2}>
                      Average <br />
                      of <br /> Internals
                    </th>
                  </tr>
                  <tr className="text-center text-sm">
                    {internalData.length !== 0 && this.getIntTd()}
                  </tr>
                  {this.renderIntSeeWeightagesData()}
                </tbody>
              </table>

              {/* <div className="flex justify-center">
                <button
                  name="internal"
                  onClick={this.storeWeightages}
                  className={styles["update-changes-btn"]}
                  type="button"
                >
                  Update changes
                </button>
              </div> */}
            </>
          ) : (
            this.renderEmptyView()
          )}
        </div>

        <div className={styles["bg-containers"]}>
          <p className={styles["art-matrix-title"]}>
            Total Attainment Of Internals
          </p>
          {Object?.keys(data?.coAttData ?? {})?.length > 0 ? (
            <table className="mb-2 mt-4 border-solid border-blue-600 border-1">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-2" rowSpan={2}>
                    Course Outcome
                  </th>
                  {internalData.length !== 0 && (
                    <th
                      className="border border-blue-500 p-2"
                      colSpan={internalData.length}
                    >
                      Internal
                    </th>
                  )}

                  <th className="border border-blue-500 p-2" rowSpan={2}>
                    Threshold
                  </th>
                  <th className="border border-blue-500 p-2" rowSpan={2}>
                    Total <br />
                    Attainment
                  </th>
                </tr>
                <tr className="text-center text-sm">
                  {internalData.length !== 0 && this.getIntTd()}
                </tr>
                {this.renderIntTotalAttData()}
              </tbody>
            </table>
          ) : (
            this.renderEmptyView()
          )}
        </div>

        <div className={styles["bg-containers"]}>
          <p className={styles["art-matrix-title"]}>
            Weightages Of Externals To Calculate Average
          </p>
          {Object?.keys(data?.coAttData ?? {})?.length > 0 ? (
            <>
              <table className="mb-2 mt-4 border-solid border-blue-600 border-1">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th className="border border-blue-500 p-2" rowSpan={2}>
                      Course Outcome
                    </th>

                    {externalData.length !== 0 && (
                      <th
                        className="border border-blue-500 p-2"
                        colSpan={externalData.length}
                      >
                        External
                      </th>
                    )}

                    <th className="border border-blue-500 p-2" rowSpan={2}>
                      Average <br />
                      of <br /> Externals
                    </th>
                  </tr>
                  <tr className="text-center text-sm">
                    {externalData.length !== 0 && this.getExtTd()}
                  </tr>
                  {this.renderExtSeeWeightagesData()}
                </tbody>
              </table>

              {/* <div className="flex justify-center">
                <button
                  name="external"
                  onClick={this.storeWeightages}
                  className={styles["update-changes-btn"]}
                  type="button"
                >
                  Update changes
                </button>
              </div> */}
            </>
          ) : (
            this.renderEmptyView()
          )}
        </div>

        <div className={styles["bg-containers"]}>
          <p className={styles["art-matrix-title"]}>
            Weightages For Attainment Calculation
          </p>

          <div className="flex flex-row flex-wrap w-11/12 text-[#636363] mb-1 p-5 pt-2 pb-2">
            <span className="w-3/6 mt-1 flex flex-col">
              <label className={styles["label"]}> Internal Weightage (%)</label>
              <input
                value={intWeight}
                onChange={this.intWeightChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/6 mt-1 flex flex-col">
              <label className={styles["label"]}> External Weightage (%)</label>
              <input
                value={extWeight}
                onChange={this.extWeightChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>

            <span className="w-3/6 mt-1 flex flex-col">
              <label className={styles["label"]}> Direct CO Weightage</label>
              <input
                value={directCoW}
                onChange={this.directCoWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
            <span className="w-3/6 mt-1 flex flex-col">
              <label className={styles["label"]}> Indirect CO Weightage</label>
              <input
                value={indirectCoW}
                onChange={this.indirectCoWChange}
                required
                type="text"
                className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                placeholder="EX: 50"
              />
            </span>
          </div>

          {/* <div className="flex justify-center">
            <button
              onClick={this.storeAttCal}
              className={styles["update-changes-btn"]}
              type="button"
            >
              Update changes
            </button>
          </div> */}
        </div>

        <div className={styles["bg-containers"]}>
          <p className={styles["art-matrix-title"]}>Edit Targets</p>
          {Object?.keys(data?.coAttData ?? {})?.length > 0 ? (
            <>
              <table className="mb-2 mt-4 border-solid border-blue-600 border-1">
                <tbody>
                  <tr
                    className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                  >
                    <th className="border border-blue-500 p-2">
                      Course Outcome
                    </th>

                    <th className="border border-blue-500 p-2">Targets</th>
                  </tr>

                  {this.renderTargets()}
                </tbody>
              </table>

              <div className="flex justify-center">
                <button
                  onClick={this.storeTargets}
                  className={styles["update-changes-btn"]}
                  type="button"
                >
                  Update changes
                </button>
              </div>
            </>
          ) : (
            this.renderEmptyView()
          )}
        </div>
      </>
    );
  }
}

export default WeightsAndTargets;
