import React from "react";

const OtpContext = React.createContext({
  verified: "",
  updateVerified: () => {},
  header: "",
  headerChange: () => {},
});

export default OtpContext;
