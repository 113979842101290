import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";

const AttainmentTable = (props) => {
  const [loader, updateLoader] = useState(true);
  const [data, updateData] = useState({});
  const [internalData, updateInternalData] = useState([]);
  const [externalData, updateExternalData] = useState([]);

  const { item, stdId } = props;

  const { courseId, courseStructId } = item;

  const getStudentCourseAttainmentTable = async () => {
    const token = Cookies.get("jwt_token");

    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_STD_COURSES_CO_ATTAINMENTS +
          `?stdId=${stdId}&courseId=${courseId}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        updateData(data);
      }
    } catch (err) {
      console.log(err);
    }
    updateLoader(false);
  };

  const getStructure = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.COURSE_STRUCTURES +
          `?courseStructId=${courseStructId}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        const res = response["data"]["result"];

        const internal = [
          ...res?.["intTheoryShortList"].split(","),
          ...res?.["intPractShortList"].split(","),
          ...res?.["intProjShortList"].split(","),
        ];

        const external = [
          ...res?.["extTheoryShortList"].split(","),
          ...res?.["extPractShortList"].split(","),
          ...res?.["extProjShortList"].split(","),
        ];

        const int = internal.filter((each) => each !== "");
        const ext = external.filter((each) => each !== "");

        updateInternalData(int);
        updateExternalData(ext);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderAttTable = () => (
    <div className="w-10/12 overflow-x-auto pr-2">
      <table
        className={`${styles["art-matrix-table"]} text-center border border-blue-500 mt-5`}
      >
        <tr className="border border-blue-500 p-0 w-full">
          <th
            className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 w-28 "
            rowSpan="2"
          >
            Course Outcome
          </th>
          {internalData.length > 0 && (
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan={internalData.length}
            >
              Internal(%)
            </th>
          )}
          {externalData.length > 0 && (
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 w-24"
              colSpan={externalData.length}
            >
              External(%)
            </th>
          )}
        </tr>

        <tr className="border border-blue-500 p-0 w-full">
          {internalData.map((each) => (
            <th className="bg-[#EFF3FF] border border-blue-500 p-1">{each}</th>
          ))}
          {externalData.map((each) => (
            <th className="bg-[#EFF3FF] border border-blue-500 p-1">{each}</th>
          ))}
        </tr>

        {Object.keys(data).map((item) => (
          <tr className="border border-blue-500 p-0 w-full">
            <td className="border border-blue-500 p-0 py-1">{item}</td>

            {internalData.map((each) => (
              <td className="border border-blue-500 p-0 py-1 text-[#606981]">
                {data?.[item]?.[each]}
              </td>
            ))}

            {externalData.map((each) => (
              <td className="border border-blue-500 p-0 py-1 text-[#606981]">
                {data?.[item]?.[each]}
              </td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5 w-full">
      <p className={styles["empty-view"]}>
        No student attainment data found for this course
      </p>
    </div>
  );

  useEffect(() => {
    getStructure();
    getStudentCourseAttainmentTable();
  }, []);

  return loader ? (
    <div className="w-full flex justify-center mb-10 mt-10">
      <Oval
        height={50}
        width={50}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : (internalData.length === 0 && externalData.length === 0) ||
    Object.keys(data).length === 0 ? (
    renderEmptyView()
  ) : (
    renderAttTable()
  );
};

export default AttainmentTable;
