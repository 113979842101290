import styles from "./index.module.css";

const RecentActivity = () => (
  <>
    <div className={styles["activity-container"]}>
      <h1>Recent Activity</h1>
      <ul className="list-none">
        <li className="flex items-center mb-5">
          <img src="/assets/timer.svg" alt="activity" />
          <p className={styles["activity-desc"]}>
            <span className={styles["activity-desc-name"]}>Karen</span> leave
            some comments on Konsep Ilustrasi
            <br />
            <span className={styles["time"]}>20mins ago</span>
          </p>
        </li>
        <li className="flex items-center mb-5">
          <img src="/assets/timer.svg" alt="activity" />
          <p className={styles["activity-desc"]}>
            <span className={styles["activity-desc-name"]}>Karen</span> leave
            some comments on Konsep Ilustrasi
            <br />
            <span className={styles["time"]}>20mins ago</span>
          </p>
        </li>
        <li className="flex items-center mb-5">
          <img src="/assets/timer.svg" alt="activity" />
          <p className={styles["activity-desc"]}>
            <span className={styles["activity-desc-name"]}>Karen</span> leave
            some comments on Konsep Ilustrasi
            <br />
            <span className={styles["time"]}>20mins ago</span>
          </p>
        </li>
      </ul>
    </div>
  </>
);

export default RecentActivity;
