import Cookies from "js-cookie";
import { Component } from "react";
import "./index.css";
import apiClient from "../../../utils/apiUrls/apiClient";
import SideBar from "../../layouts/SideBar";
import CampusNavBar from "../../layouts/CampusNavBar";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import "../../LoginForm/index.css";
import NavLink from "../NavLink";

class BulkMigration extends Component {
  state = {
    branchOptions: [],
    batchOptions: [],
    semOptions: [],
    toBatch: "",
    fromBatch: "",
    fromBranch: "",
    branch: "",
    courses: [],
    isLoading: false,
    selectionStatus: {},
    coursesLoader: true,
    claims: {},
  };

  componentDidMount() {
    const token = Cookies.get("jwt_token");
    const { history } = this.props;
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      claim["user_type"] !== "PRINCIPAL" && history.replace("/");
      this.setState({ claims: claim });
    }

    this.getBatchOptions();
    this.getBranchOptions();
    this.getSemOptions();
  }

  migrateCourses = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { toBatch, branch, courses } = this.state;
    try {
      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const ids = courses
        .filter((each) => each.isSelected === true)
        .map((each) => each.courseId);

      ids.sort(function (a, b) {
        return a - b;
      });

      const coursesIds = ids.join();

      if (coursesIds !== "") {
        const response = await fetch(
          apiClient.urls.fatcat.BULK_MIGRATION +
            `?batchId=${toBatch}&courseIdList=${coursesIds}&branch=${branch}`,
          options
        );

        if (response["ok"] === true) {
          const result = await response.json();
          if (result["statusCode"] === "OK") {
            alert("Courses migrated Successfully!");
          } else {
            alert("Courses are not migrated!");
          }
        } else {
          alert("Courses are not migrated!");
        }
      } else {
        alert("Select atleast one course");
      }
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
      alert("Something went wrong. Please try again");
    }
  };

  getCourses = async () => {
    this.setState({ coursesLoader: true });
    const token = Cookies.get("jwt_token");
    const { fromBatch, semOptions, fromBranch } = this.state;

    const semValues = semOptions
      .filter((each) => each.isSelected === true)
      .map((each) => each.optionValue);

    const joinedSemValues = semValues.join();

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=${fromBatch}&branch=${fromBranch}&regulation=&semester=${joinedSemValues}&section=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        const updatedCourses = response["data"]["result"][
          "courseDetailsResponses"
        ].map((each) => ({
          ...each,
          isSelected: false,
        }));

        this.setState({
          courses: updatedCourses,
        });
      } else {
        this.setState({ courses: [] });
      }

      // await getCrossCourses();
    } catch (err) {
      console.log(err);
    }

    this.setState({ coursesLoader: false });
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ branchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        let updatedSemData = [];
        updatedSemData = response["data"]["result"].map((each) => ({
          ...each,
          isSelected: false,
        }));

        let updatedSelection = {};

        response["data"]["result"].forEach((each) => {
          updatedSelection[each.optionValue] = false;
        });

        this.setState({
          semOptions: updatedSemData,
          selectionStatus: updatedSelection,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ batchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fromBatchValChanged = (event) => {
    this.setState({ fromBatch: event.target.value }, this.getCourses);
  };

  toBatchValChanged = (event) => {
    this.setState({ toBatch: event.target.value });
  };

  fromBranchValChanged = (event) => {
    this.setState({ fromBranch: event.target.value }, this.getCourses);
  };

  branchValChanged = (event) => {
    this.setState({ branch: event.target.value });
  };

  semValChanged = (event) => {
    const { semOptions } = this.state;
    const semData = semOptions.map((each) => {
      if (event.target.id === each.optionValue) {
        return { ...each, isSelected: !each.isSelected };
      } else {
        return each;
      }
    });

    this.setState({ semOptions: semData }, this.getCourses);
  };

  renderBatchOptions = () => {
    const { batchOptions } = this.state;
    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { semOptions } = this.state;

    const ans = [];
    try {
      for (const item of semOptions) {
        ans.push(
          <li
            key={item["optionValue"]}
            className="flex items-center justify-center mr-3 mb-2 mt-2"
          >
            <input
              checked={item.isSelected}
              id={item["optionValue"]}
              onChange={this.semValChanged}
              value={item["optionValue"]}
              type="checkbox"
              className="sec-check mr-1"
            />
            <label className="sec-check-label">{item["optionDesc"]}</label>
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  courseSelChanged = (event) => {
    const { courses } = this.state;

    const selUpdated = courses.map((each) => {
      if (each.courseId === parseInt(event.target.id)) {
        return { ...each, isSelected: !each.isSelected };
      } else {
        return each;
      }
    });

    this.setState({ courses: selUpdated });
  };

  renderCoursesList = (data) => {
    const { courses } = this.state;

    const filteredCourses = courses.filter(
      (courseItem) => courseItem.semester === parseInt(data.optionValue)
    );

    const res = filteredCourses.map((item) => (
      <span className="w-6/12 flex items-end">
        <input
          id={item.courseId}
          checked={item.isSelected}
          type="checkbox"
          onChange={this.courseSelChanged}
          className="sec-check mr-3 mb-0.5"
        />
        <label className="label label-sem">
          {item.courseShortName} ({item.courseName}) - {item.courseCode}
        </label>
      </span>
    ));

    return res;
  };

  allSelect = (event) => {
    const { courses, selectionStatus } = this.state;
    const val = event.target.id;

    const selUpdated = courses.map((each) => {
      if (each.semester === parseInt(val)) {
        return { ...each, isSelected: true };
      } else {
        return each;
      }
    });

    this.setState({
      courses: selUpdated,
      selectionStatus: {
        ...selectionStatus,
        [val]: true,
      },
    });
  };

  deSelect = (event) => {
    const { courses, selectionStatus } = this.state;
    const val = event.target.id;

    const selUpdated = courses.map((each) => {
      if (each.semester === parseInt(val)) {
        return { ...each, isSelected: false };
      } else {
        return each;
      }
    });

    this.setState({
      courses: selUpdated,
      selectionStatus: {
        ...selectionStatus,
        [val]: false,
      },
    });
  };

  renderCourseView = () => {
    const { semOptions, selectionStatus } = this.state;
    return (
      <span className="mt-0 flex flex-col p-5 pt-0">
        <span className="mb-8">
          {semOptions.map(
            (each) =>
              each.isSelected && (
                <span key={each.optionValue}>
                  <span className="flex justify-between items-center flex-wrap mb-8">
                    <p className="label label-sem">{each.optionDesc} Sem</p>
                    <button
                      key={each.optionValue}
                      type="button"
                      onClick={
                        selectionStatus[each.optionValue] === false
                          ? this.allSelect
                          : this.deSelect
                      }
                      id={each.optionValue}
                      className="label label-select"
                    >
                      {selectionStatus[each.optionValue]
                        ? "Deselect All"
                        : "Select All"}
                    </button>
                  </span>

                  <span className="flex justify-between flex-wrap mb-8">
                    {this.renderCoursesList(each)}
                  </span>
                </span>
              )
          )}
        </span>
      </span>
    );
  };

  renderView = () => {
    const { isLoading, fromBatch, fromBranch, branch, toBatch, coursesLoader } =
      this.state;
    return (
      <form onSubmit={this.migrateCourses} className="flex flex-col">
        <h1 className="migrate-title mb-5">
          Migrate Course Data Between Batches
        </h1>
        <div className="flex w-10/12">
          <div className="filter-container flex flex-col flex-wrap text-[#636363] pl-5">
            <span className="filter-el mt-1 flex flex-col">
              <label className="label">Select From Batch</label>
              <select
                onChange={this.fromBatchValChanged}
                required
                className={`filter-select focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>

                {this.renderBatchOptions()}
              </select>
            </span>
          </div>

          <span className="arrow mt-12">
            <img src="/assets/bulk-migration-arrow.svg" alt="arrow" />
          </span>

          <div className="filter-container flex flex-col w-5/12 flex-wrap text-[#636363] pl-5">
            <span className="filter-el mt-1 flex flex-col">
              <label className="label">Select To Batch</label>
              <select
                onChange={this.toBatchValChanged}
                required
                className={`filter-select focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {this.renderBatchOptions()}
              </select>
            </span>
          </div>
        </div>

        <div className="flex w-10/12">
          <div className="filter-container flex flex-col flex-wrap text-[#636363] pl-5">
            <span className="filter-el mt-1 flex flex-col">
              <label className="label">Select From Branch</label>
              <select
                value={fromBranch}
                onChange={this.fromBranchValChanged}
                required
                className={`filter-select focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>

                {this.renderBranchOptions()}
              </select>
            </span>
          </div>

          <span className="arrow mt-12">
            <img src="/assets/bulk-migration-arrow.svg" alt="arrow" />
          </span>

          <div className="filter-container flex flex-col w-5/12 flex-wrap text-[#636363] pl-5">
            <span className="filter-el mt-1 flex flex-col">
              <label className="label">Select To Branch</label>
              <select
                value={branch}
                onChange={this.branchValChanged}
                required
                className={`filter-select focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>

                {this.renderBranchOptions()}
              </select>
            </span>
          </div>
        </div>

        <div className="filter-container flex flex-col flex-wrap text-[#636363] pl-5">
          <span className="filter-el-sem mt-1 flex flex-col">
            <label className="label">Select Semester</label>

            <ul className="flex items-center flex-wrap">
              {this.renderSemOptions()}
            </ul>
          </span>
        </div>

        {fromBatch !== "" &&
          toBatch !== "" &&
          branch !== "" &&
          fromBranch !== "" && (
            <>
              <p className="label ml-5 pb-5">Select Courses to migrate</p>

              <div className="sem-courses-container">
                {coursesLoader ? (
                  <div className="flex justify-center mt-10">
                    <Oval
                      height={50}
                      width={50}
                      color="#3d65f4"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#3d65f4"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : (
                  this.renderCourseView()
                )}
              </div>
              <button
                disabled={isLoading}
                className={`${
                  isLoading && "cursor-not-allowed"
                } text-white bg-[#456BF1] self-end rounded-3xl text-md font-medium px-12 py-2 mr-5 mb-8 mt-10`}
                type="submit"
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Migrate"
                )}
              </button>
            </>
          )}
      </form>
    );
  };

  render() {
    const { match } = this.props;
    const { path } = match;
    const { claims } = this.state;

    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div
          className={`flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]`}
        >
          <SideBar />
          <div className="flex flex-col">
            <CampusNavBar />
            <div className="flex flex-col overflow-y-auto overflow-x-hidden">
              <NavLink path={path} claims={claims} />
              <div className="account-container">
                <div className="info-container">{this.renderView()}</div>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

export default BulkMigration;
