import { Link, withRouter } from "react-router-dom";
import CoursesList from "./CoursesList";
import styles from "./index.module.css";
import { Component } from "react";
import CourseOutcome from "../../CourseOutcome";
import FeedBack from "../../FeedBack";
import QuestionPaperMapping from "../../QuestionPaperMapping";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import SearchContext from "../../../../context/SearchContext";

class ManageCourses extends Component {
  state = {
    isLoading: true,
    data: [],
    content: false,
    isOpened: false,
    active: null,
    id: null,
    filters: {
      batch: "",
      branch: "",
      semester: "",
      section: "",
    },
    dropBatchOptions: [],
    dropBranchOptions: [],
    dropSemOptions: [],
    dropSectionOptions: [],
  };

  static contextType = SearchContext;

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropBatchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropBranchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropSemOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getOptions = async () => {
    try {
      await this.getBatchOptions();
      await this.getSemOptions();
      await this.getBranchOptions();
    } catch (err) {
      console.log(err);
    }
  };

  branchChanged = (event) => {
    const { filters } = this.state;
    this.setState(
      { filters: { ...filters, branch: event.target.value, section: "" } },
      this.getCourses
    );
  };

  batchChanged = (event) => {
    const { filters } = this.state;
    const val = event.target.value;

    this.setState(
      {
        filters: {
          ...filters,
          batch: `${filters.batch}` === `${val}` ? "" : val,
          section: "",
        },
      },
      this.getCourses
    );
  };

  semesterChanged = (event) => {
    const { filters } = this.state;
    const val = event.target.value;

    this.setState(
      {
        filters: {
          ...filters,
          semester: `${filters.semester}` === `${val}` ? "" : val,
        },
      },
      this.getCourses
    );
  };

  sectionChanged = (event) => {
    const val = event.target.id;

    this.setState(
      (prev) => ({
        filters: {
          ...prev.filters,
          section: `${prev.filters.section}` === `${val}` ? "" : val,
        },
      }),
      this.getCourses
    );
  };

  getCourses = async () => {
    this.setState({ isLoading: true });
    try {
      const token = Cookies.get("jwt_token");
      const { filters } = this.state;

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_MANAGE_COURSES_LIST +
          `?batch=${filters.batch}&branch=${filters.branch}&regulation=&semester=${filters.semester}&section=${filters.section}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          data: response["data"]["result"]["courseDetailsResponses"],
          content: true,
        });
      } else {
        this.setState({
          data: [],
          content: false,
        });
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  };

  getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { dropBatchOptions, filters } = this.state;

      let batch = dropBatchOptions.filter(
        (each) => `${each.optionValue}` === `${filters.batch}`
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${batch?.[0]?.["optionDesc"] ?? ""}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropSectionOptions: response["data"]["result"] });
      } else {
        this.setState({ dropSectionOptions: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        this.getCourses();
        this.getOptions();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filters.batch !== this.state.filters.batch ||
      prevState.filters.branch !== this.state.filters.branch
    ) {
      this.getSectionOptions();
    }
  }

  closeBtn = () => {
    this.setState({
      active: null,
      isOpened: false,
    });
  };

  openBtn = (id) => {
    this.setState({
      id: id,
      isOpened: true,
    });
  };

  reload = () => {
    this.getCourses();
  };

  renderView = () => {
    try {
      const { id, data, active } = this.state;
      const ans = data.filter((each) => each.courseId === id);

      switch (active) {
        case 1:
          return (
            <CourseOutcome
              item={ans}
              cls={this.closeBtn}
              reload={this.reload}
            />
          );
        case 2:
          return (
            <QuestionPaperMapping
              cls={this.closeBtn}
              item={ans}
              reload={this.reload}
            />
          );

        case 3:
          return (
            <FeedBack item={ans} cls={this.closeBtn} reload={this.reload} />
          );

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  preventBubbling = (event) => {
    event.stopPropagation();
  };

  coBtn = async (id) => {
    this.setState(
      {
        active: 1,
      },
      this.openBtn(id)
    );
  };

  quesPaperBtn = async (id) => {
    this.setState(
      {
        active: 2,
      },
      this.openBtn(id)
    );
  };

  feedbackBtn = async (id) => {
    this.setState(
      {
        active: 3,
      },
      this.openBtn(id)
    );
  };

  renderBatchOptions = () => {
    const { filters, dropBatchOptions } = this.state;
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <li
            className={
              `${filters.batch}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            value={item["optionValue"]}
            onClick={this.batchChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { dropBranchOptions } = this.state;

    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { filters, dropSemOptions } = this.state;

    const ans = [];
    try {
      for (const item of dropSemOptions) {
        ans.push(
          <li
            className={
              `${filters.semester}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            value={item["optionValue"]}
            onClick={this.semesterChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSectionOptions = () => {
    const { filters, dropSectionOptions } = this.state;

    const ans = [];
    try {
      for (const item of dropSectionOptions) {
        ans.push(
          <li
            className={
              `${filters.section}` === `${item}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item}
            id={item}
            onClick={this.sectionChanged}
          >
            {item}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderCourses = () => {
    const { data } = this.state;

    const { search } = this.context;
    const filteredCourses = data.filter(
      (item) =>
        item.courseName !== null &&
        item.courseShortName !== null &&
        (item.courseName.toLowerCase().includes(search.toLowerCase()) ||
          item.courseShortName.toLowerCase().includes(search.toLowerCase()))
    );

    return filteredCourses.map((each) => (
      <CoursesList
        item={each}
        key={each.courseId}
        coBtn={this.coBtn}
        quesPaperBtn={this.quesPaperBtn}
        feedbackBtn={this.feedbackBtn}
      />
    ));
  };

  batchTabLeft = () => {
    const container = document.getElementById("batch-tabs");
    this.sideScroll(container, "left", 25, 250, 20);
  };

  batchTab = () => {
    const container = document.getElementById("batch-tabs");
    this.sideScroll(container, "right", 25, 250, 20);
  };

  semTabLeft = () => {
    const container = document.getElementById("sem-tabs");
    this.sideScroll(container, "left", 25, 150, 20);
  };

  semTab = () => {
    const container = document.getElementById("sem-tabs");
    this.sideScroll(container, "right", 25, 150, 20);
  };

  secTabLeft = () => {
    const container = document.getElementById("sec-tabs");
    this.sideScroll(container, "left", 25, 80, 20);
  };

  secTab = () => {
    const container = document.getElementById("sec-tabs");
    this.sideScroll(container, "right", 25, 80, 20);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  render() {
    const { match } = this.props;
    const { path } = match;
    const {
      isOpened,
      active,
      content,
      data,
      filters,
      isLoading,
      dropSectionOptions,
    } = this.state;

    return (
      <>
        <div
          onClick={this.closeBtn}
          className={isOpened ? styles.overlay : "hidden"}
        >
          <div
            onClick={this.preventBubbling}
            className={styles["overlay-card"]}
          >
            {active !== null && this.renderView()}
          </div>
        </div>

        <div className="ml-7">
          <div className="flex flex-row items-center mt-24">
            <Link to="/faculty/dashboard/managecourses">
              <h1
                className={`${
                  path === "/faculty/dashboard/managecourses"
                    ? "font-bold underline"
                    : "font-medium"
                } ml-4 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
              >
                MANAGE COURSES
              </h1>
            </Link>

            <Link to="/faculty/dashboard/attainments">
              <h1
                className={`hover:font-bold hover:[#464D90] ${
                  path === "/faculty/dashboard/attainments"
                    ? "font-bold underline"
                    : "font-medium"
                } ml-6 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
              >
                ATTAINMENTS
              </h1>
            </Link>
          </div>
        </div>

        <div className={`${styles["students-bg"]} mb-2 pl-5`}>
          <div className={`${styles["students-filters"]} w-32 ml-2`}>
            <select
              value={filters.branch}
              className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mt-7 mb-1.5 pr-5`}
              onChange={this.branchChanged}
            >
              <option value="">Branch</option>
              {this.renderBranchOptions()}
            </select>
          </div>

          <div className={`${styles["students-filters"]} ml-1 w-5/12`}>
            <h1 className={`${styles["filters-title"]}`}>Choose Batch</h1>
            <div className="flex relative">
              <button
                onClick={this.batchTabLeft}
                className={styles["arrow-buttons"]}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul id="batch-tabs" className={styles["batches-ul"]}>
                {this.renderBatchOptions()}
              </ul>

              <button
                onClick={this.batchTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>

          <div className={`${styles["students-filters"]} ml-4 w-3/12`}>
            <h1 className={`${styles["filters-title"]}`}>Semester</h1>

            <div className="flex relative">
              <button
                onClick={this.semTabLeft}
                className={styles["arrow-buttons"]}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul
                id="sem-tabs"
                className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
              >
                {this.renderSemOptions()}
              </ul>

              <button
                onClick={this.semTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>

          {dropSectionOptions.length > 0 && (
            <div className={`${styles["students-filters"]} ml-4 w-2/12`}>
              <h1 className={`${styles["filters-title"]}`}>Section</h1>

              <div className="flex relative">
                <button
                  onClick={this.secTabLeft}
                  className={styles["arrow-buttons"]}
                >
                  <img
                    src="/assets/left-arr-outline.svg"
                    alt="arrow"
                    width={8}
                    height={8}
                  />
                </button>
                <ul
                  id="sec-tabs"
                  className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
                >
                  {this.renderSectionOptions()}
                </ul>

                <button
                  onClick={this.secTab}
                  className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
                >
                  <img
                    src="/assets/left-arr-outline.svg"
                    alt="arrow"
                    width={8}
                    height={8}
                  />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="ml-7">
          <h1 className={styles["total-courses"]}>
            TOTAL COURSES : {content ? data.length : 0}
          </h1>
        </div>

        <div className={styles["dashboard-periodwisebar-bg"]}>
          {isLoading ? (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <ul
              className={`${
                !content && "justify-center"
              } list-none w-full m-0 p-0 flex flex-row flex-wrap ml-1`}
            >
              {content ? (
                this.renderCourses()
              ) : (
                <p className="text-center fixed text-red-400">No Data Found</p>
              )}
            </ul>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(ManageCourses);
