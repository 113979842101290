import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import styles from "./index.module.css";
import Popup from "reactjs-popup";
import { useState } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";

const FacultyList = (props) => {
  const { data, edit, facDel, mapDetails } = props;

  const [popUp, updatePop] = useState(false);

  const {
    facultyId,
    facultyUsername,
    facultyName,
    facultyDesignation,
    facultyContact,
    numCoursesAssigned,
  } = data;

  const facultyDelete = async () => {
    const token = Cookies.get("jwt_token");

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
        // Accept: 'application/json',
      },
    };
    try {
      const response = await fetch(
        apiClient.urls.fatcat.DELETE_FACULTY + `?facultyId=${facultyId}`,
        options
      );

      if (response["ok"] === true) {
        closeModal();
        facDel();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const viewMappingDetails = () => {
    mapDetails(facultyUsername, facultyName, facultyId);
  };

  const updateFac = () => {
    edit(facultyId);
  };

  const openModal = async () => {
    updatePop(true);
  };

  const closeModal = async () => {
    updatePop(false);
  };

  const renderPopup = () => {
    return (
      <div className={`${styles["modal"]}`}>
        <p className={`${styles.text} text-center mb-10`}>
          Do you want to delete the faculty{" "}
          <span className="capitalize font-black">{facultyName}</span>?
        </p>

        <span>
          <button
            onClick={closeModal}
            className="mr-9 text-blue-600 font-medium bg-[#F4F9FF] rounded px-5 py-1 border"
          >
            No
          </button>
          <button
            onClick={facultyDelete}
            className="text-blue-600 font-medium bg-red-600 text-white rounded px-5 py-1 border"
          >
            Yes
          </button>
        </span>
      </div>
    );
  };

  const overlayStyle = { background: "rgba(0,0,0,0.5)" };

  return (
    <li className={`${styles.labels} flex flex-col justify-center m-0 mb-4`}>
      <div
        className={`flex flex-row items-center text-[#606981] p-2 pr-0 pl-6`}
      >
        <p className="text-nowrap truncate w-2/12 text-base flex flex-row justify-start items-center">
          {facultyUsername}
        </p>
        <p className="text-nowrap truncate w-2/12 ml-1 text-xs md:text-base flex flex-row justify-start items-center">
          <img
            className="rounded-xl"
            src="/assets/defaultImage.svg"
            alt="dp"
            width={26}
            height={26}
          />
          <span className="flex ml-2">{facultyName}</span>
        </p>
        <p className="text-nowrap truncate w-1/12 ml-3 text-xs md:text-base flex flex-row justify-start">
          {facultyDesignation}
        </p>
        <p className="w-1/4 md:w-2/12 ml-3 hidden sm:flex text-xs md:text-base flex flex-row items-center">
          {facultyContact}
          <span className="hidden md:flex rounded-xl ml-2 bg-[#E2E9FF] py-0.5 px-1">
            <img
              className="rounded-xl"
              src="/assets/contact.svg"
              alt="contact"
              width={16}
              height={16}
            />
          </span>
        </p>
        <p className="w-1/12 text-xs md:text-base flex flex-row items-center ml-11">
          {numCoursesAssigned}
        </p>

        <button
          onClick={viewMappingDetails}
          type="button"
          className="text-blue-600 ml-4 flex flex-row items-center bg-transparent border border-[#E2E9FF] rounded p-2"
        >
          Mapping Details
        </button>

        <span className="hidden md:flex flex-row flex flex-row items-center ml-5">
          <RiPencilFill
            onClick={updateFac}
            className="text-blue-600 cursor-pointer"
            size={20}
          />
          <Popup open={popUp} onClose={closeModal} {...{ overlayStyle }}>
            {renderPopup()}
          </Popup>
          <MdDeleteOutline
            onClick={openModal}
            className="text-red-600 ml-5 cursor-pointer"
            size={20}
          />
        </span>
      </div>
    </li>
  );
};

export default FacultyList;
