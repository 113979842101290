import { Link } from "react-router-dom";
import styles from "./index.module.css";

const CriteriaList = (props) => {
  const { item, projectId } = props;

  const { criteriaNo, criteriaStatement, endDate } = item;

  const progressStyle = {
    width: `${60}%`,
  };

  const differenceInMilliseconds = new Date(endDate) - new Date();

  const daysLeft = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  return (
    <li>
      <h1>
        {criteriaNo}. {criteriaStatement}
      </h1>
      <p className="flex justify-between">
        90% metrics completed(100 Marks) <span>{daysLeft} days left</span>
      </p>

      <div className={styles["progress-bar-container"]}>
        <div className={styles["progress-bar"]} style={progressStyle}>
          &nbsp;
        </div>
      </div>

      <div className="flex items-center mt-8">
        <p className={styles["metric-progress-desc"]}>
          <span className={styles["metric-progress-percent"]}>20%</span>
          In Draft
        </p>
        <p className={styles["metric-progress-desc"]}>
          <span className={styles["metric-progress-percent"]}>35%</span>
          Submitted
        </p>
      </div>
      <Link
        to={`/connect/dashboard/criteria/metrics/${projectId}/${criteriaNo}`}
        className={styles["metrics-btn"]}
      >
        <button className={styles["metrics-btn"]}>View all metrics (10)</button>
      </Link>
    </li>
  );
};

export default CriteriaList;
