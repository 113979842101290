import { Component } from "react";
import styles from "./index.module.css";
import AttainmentTable from "../AttainmentTable";

class StudentCoursesList extends Component {
  state = {
    subjectClick: false,
    toggleBtn: false,
  };

  onClickArrow = () => {
    const { toggleBtn } = this.state;
    if (toggleBtn === false) {
      this.setState((prevState) => ({
        subjectClick: !prevState.subjectClick,
        toggleBtn: !prevState.toggleBtn,
      }));
    }
  };

  toggleBtnClick = () => {
    this.setState((prevState) => ({
      toggleBtn: !prevState.toggleBtn,
      subjectClick: !prevState.subjectClick,
    }));
  };

  render() {
    const { subjectClick } = this.state;
    const { item, stdId } = this.props;
    const progressLength = { width: `${item?.progress}%` };
    return (
      <>
        <tr
          className={styles["sub-in-container"]}
          onClick={this.onClickArrow}
          key={item.courseId}
        >
          <td className={`${styles["sub-name-progress"]}`}>
            <p className={styles["sub"]}>{item.courseName}</p>
            <div className={styles["sub-name"]}>
              <div className={styles["progress-bar"]}>
                <div
                  className={`${styles["progress"]}`}
                  style={progressLength}
                ></div>
              </div>
              <span
                className={`${styles["progress-per"]} ${
                  item?.avgAtt >= 50 ? "text-[#27AE7A]" : "text-[#E35050]"
                }`}
              >
                {item.totalCosAttained}/{item.totalCos}
              </span>
            </div>
          </td>

          <td className={`${styles["avg-marks"]}`}>{item?.avgAtt}</td>

          <td className={styles["marks-container"]}>
            <span
              className={`${styles["marks"]} ${
                item?.avgAtt >= 50
                  ? "text-[#27AE7A] bg-[#E5FAE3]"
                  : "text-[#E35050] bg-[#FFEEEE]"
              }`}
            >
              {item?.internal}
            </span>
          </td>

          <td className={styles["marks-container"]}>
            <span
              className={`${styles["marks"]} ${
                item?.avgAtt >= 50
                  ? "text-[#27AE7A] bg-[#E5FAE3]"
                  : "text-[#E35050] bg-[#FFEEEE]"
              }`}
            >
              {item?.external}
            </span>
          </td>

          <td className={styles["teacher-name"]}>{item?.facultyName}</td>
        </tr>

        {subjectClick && (
          <div className={styles["attainment-table-container"]}>
            <AttainmentTable key={item.courseId} stdId={stdId} item={item} />
            <button
              className={styles["toggle-btn"]}
              onClick={this.toggleBtnClick}
            >
              <img src="/studentassests/Arrow.svg" alt="arrow" />
            </button>
          </div>
        )}

        <div className={styles["hr-line"]}></div>
      </>
    );
  }
}

export default StudentCoursesList;
