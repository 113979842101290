import { Component } from "react";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import CourseStructureList from "./CourseStructureList";
import styles from "./index.module.css";
import { Oval } from "react-loader-spinner";

class CourseStructure extends Component {
  state = { data: [], content: false, loading: false };

  getStructures = async () => {
    this.setState({ loading: true });
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.COURSE_STRUCTURES,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          data: response["data"]["result"],
          content: true,
          loading: false,
        });
      } else {
        this.setState({ content: false, loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        this.getStructures();
      }
    }
  }

  reloadStruct = () => {
    this.getStructures();
  };

  editStructure = (item) => {
    const { itemUpdate } = this.props;
    itemUpdate(item);
  };

  render() {
    const { data, content, loading } = this.state;

    return (
      <ul className="list-none">
        {loading ? (
          <div className="flex flex-row justify-center mt-16">
            <Oval
              height={60}
              width={60}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : content ? (
          data.map((each) => (
            <CourseStructureList
              key={each.courseStructId}
              item={each}
              reload={this.getStructures}
              editStructure={this.editStructure}
            />
          ))
        ) : (
          <div className="text-center mt-16">
            <p className={styles["no-course-structure-text"]}>
              You Don&apos;t Have Any Courses Structures Created
            </p>
          </div>
        )}
      </ul>
    );
  }
}

export default CourseStructure;
