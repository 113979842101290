import styles from "./index.module.css";
import { Oval } from "react-loader-spinner";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const Observations = (props) => {
  const [loader, updateLoader] = useState(false);
  const [observation, updateObservation] = useState("");

  const { courseCode } = props;

  const getObservation = async () => {
    updateLoader(true);
    updateObservation("");

    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_ATTAINMENTS_GET_ATT_OBSERVATION +
          `?courseId=${courseCode}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateObservation(response["data"]["result"]["observationTxt"]);
      }
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  const storeObservation = async () => {
    updateLoader(true);

    const token = Cookies.get("jwt_token");

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        courseId: courseCode,
        observationText: observation,
      }),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_ATTAINMENTS_STORE_ATT_OBSERVATION +
          `?courseId=${courseCode}`,
        options
      );
      const res = await response.json();
      if (res["statusCode"] === "OK") {
        getObservation();
      } else {
        alert("Observation not saved");
      }
    } catch (err) {
      alert("Observation not saved");
      console.log(err);
    }

    updateLoader(false);
  };

  const obsChanged = (event) => {
    updateObservation(event.target.value);
  };

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  useEffect(() => {
    getObservation();
  }, [courseCode]);

  return loader ? (
    renderLoader()
  ) : (
    <div className={styles["bg-containers"]}>
      <h1 className={styles["obs-title"]}>Write Your Observations</h1>

      <textarea
        value={observation}
        onChange={obsChanged}
        className={styles["obs-textarea"]}
        placeholder="Describe your observation."
      ></textarea>

      <button
        type="button"
        onClick={storeObservation}
        className={styles["obs-button"]}
      >
        Submit
      </button>
    </div>
  );
};

export default Observations;
