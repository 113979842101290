import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { RiPencilLine } from "react-icons/ri";
import apiClient from "../../../utils/apiUrls/apiClient";
import * as jose from "jose";
import Popup from "reactjs-popup";
import SideBar from "../../layouts/SideBar";
import CampusNavBar from "../../layouts/CampusNavBar";
import CryptoJS from "crypto-js";
import { Oval } from "react-loader-spinner";
import "../../LoginForm/index.css";
import NavLink from "../NavLink";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

const dummyEdit = {
  designation: "",
  fileName: "",
  firstName: "",
  identificationNo: "",
  lastName: "",
  mobileNo: "",
  password: "",
  personalEmail: "",
  workEmail: "",
};

const MyAccount = (props) => {
  const { match } = props;
  const { path } = match;

  const [data, updateData] = useState({});
  const [editData, updateEditData] = useState(dummyEdit);
  const [edit, updateEdit] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [file, updateFile] = useState();
  const [popUp, updatePop] = useState(false);
  const [pwdScreen, updatePwdScreen] = useState(false);
  const [pwd, updatePwd] = useState({ new: "", confirm: "" });
  const [pwdLoading, updatePwdLoading] = useState(false);
  const [pwdHidden, updatePwdHidden] = useState(true);

  const [claims, updateClaims] = useState({});

  useEffect(() => {
    const token = Cookies.get("jwt_token");
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);

      updateClaims(claim);
    }
  }, []);

  const logoutClicked = () => {
    const { history } = props;
    Cookies.remove("jwt_token");
    history.replace("/login");
  };

  const editChanged = () => {
    updateEdit(true);
  };

  const saveChanged = () => {
    try {
      storeData();
      updateLoading(true);
    } catch (err) {
      console.log(err);
    }
  };

  const getInfo = async () => {
    const token = Cookies.get("jwt_token");

    let claim = null;

    claim = jose.decodeJwt(token);
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MY_ACCOUNT_INFO +
          `?identificationNo=${claim["identification_no"]}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updateData(response["data"]["result"][0]);

        const ans = {
          designation: response["data"]["result"][0]["designation"],
          fileName: response["data"]["result"][0]["fileName"],
          firstName: response["data"]["result"][0]["firstName"],
          identificationNo: response["data"]["result"][0]["identificationNo"],
          lastName: response["data"]["result"][0]["lastName"],
          mobileNo: response["data"]["result"][0]["mobileNo"],
          password: response["data"]["result"][0]["password"],
          personalEmail: response["data"]["result"][0]["personalMail"],
          workEmail: response["data"]["result"][0]["workMail"],
        };

        updateEditData(ans);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderInfo = () => {
    try {
      return edit ? (
        <span className="flex items-center">
          <input type="file" accept="image/*" onChange={fileUploaded} />

          <div className="flex flex-col ml-10">
            <p className={styles["profile-name"]}>
              {data["firstName"]} {data["lastName"]}
            </p>
            <p className={styles["profile-designation"]}>
              {data["designation"]}
            </p>
            <p className={styles["profile-id"]}>{data["identificationNo"]}</p>
          </div>
        </span>
      ) : (
        <span className="flex items-center">
          <img
            src={
              data["imageUrl"] !== ""
                ? data["imageUrl"]
                : "/assets/defaultImage.svg"
            }
            className={styles["account-dp"]}
            alt="profile"
          />

          <div className="flex flex-col ml-10">
            <p className={styles["profile-name"]}>
              {data["firstName"]} {data["lastName"]}
            </p>
            <p className={styles["profile-designation"]}>
              {data["designation"]}
            </p>
            <p className={styles["profile-id"]}>{data["identificationNo"]}</p>
          </div>
        </span>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fileUploaded = (event) => {
    const fileData = event.target.files[0];
    updateFile(fileData);
  };

  const pwdUpdateForm = async () => {
    const { history } = props;
    try {
      const token = Cookies.get("jwt_token");
      const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;
      const encrypted = CryptoJS.AES.encrypt(pwd["new"], key).toString();

      const updated = { ...editData, password: encrypted };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(updated),
      };

      const response = await fetch(
        apiClient.urls.fatcat.MY_ACCOUNT_STORE_ACCOUNT_INFO,
        options
      );

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["result"] === "OK") {
          alert(res["description"]);
          Cookies.remove("jwt_token");
          history.replace("/login");
        } else {
          alert(res["description"]);
        }
      } else {
        alert("Password Not Changed!");
      }

      updateEdit(false);
      updateLoading(false);
      updatePwdLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const storeData = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const token = Cookies.get("jwt_token");

      const uploadOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      const delOption = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(editData),
      };

      if (file !== undefined) {
        const delResponse = await fetch(
          apiClient.urls.fatcat.MY_ACCOUNT_DELETE_DP +
            `?identificationNo=${data["identificationNo"]}`,
          delOption
        );

        const uploadResponse = await fetch(
          apiClient.urls.fatcat.MY_ACCOUNT_UPLOAD_DP +
            `?identificationNo=${data["identificationNo"]}`,
          uploadOptions
        );

        const response = await fetch(
          apiClient.urls.fatcat.MY_ACCOUNT_STORE_ACCOUNT_INFO,
          options
        );

        if (
          delResponse["ok"] === true &&
          uploadResponse["ok"] === true &&
          response["ok"] === true
        ) {
          getInfo();
        } else {
          alert("Data Not Uploaded Successfully");
        }
      } else {
        const response = await fetch(
          apiClient.urls.fatcat.MY_ACCOUNT_STORE_ACCOUNT_INFO,
          options
        );

        if (response["ok"] === true) {
          getInfo();
        } else {
          alert("Data Not Uploaded Successfully");
        }
      }
      updateEdit(false);
      updateLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const firstNameChanged = (event) => {
    updateEditData({ ...editData, firstName: event.target.value });
  };
  const lastNameChanged = (event) => {
    updateEditData({ ...editData, lastName: event.target.value });
  };
  const mobileChanged = (event) => {
    updateEditData({ ...editData, mobileNo: event.target.value });
  };

  const personalMailChanged = (event) => {
    updateEditData({ ...editData, personalEmail: event.target.value });
  };

  const profMailChanged = (event) => {
    updateEditData({ ...editData, workEmail: event.target.value });
  };

  const desigChanged = (event) => {
    updateEditData({ ...editData, designation: event.target.value });
  };

  const renderGenInfo = () => {
    try {
      return edit ? (
        <>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>First name</p>
              <input
                onChange={firstNameChanged}
                type="text"
                value={editData["firstName"]}
                className={styles["info-input"]}
              />
            </span>

            <span>
              <p className={styles["label"]}>Second name</p>
              <input
                onChange={lastNameChanged}
                type="text"
                value={editData["lastName"]}
                className={styles["info-input"]}
              />
            </span>
          </span>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>Mobile</p>
              <input
                onChange={mobileChanged}
                type="text"
                value={editData["mobileNo"]}
                className={styles["info-input"]}
              />
            </span>

            <span>
              <p className={styles["label"]}>Personal Email</p>
              <input
                onChange={personalMailChanged}
                type="text"
                value={editData["personalEmail"]}
                className={styles["info-input"]}
              />
            </span>
          </span>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>Professional email</p>
              <input
                onChange={profMailChanged}
                type="text"
                value={editData["workEmail"]}
                className={styles["info-input"]}
              />
            </span>

            <span>
              <p className={styles["label"]}>Designation</p>
              <input
                onChange={desigChanged}
                type="text"
                value={editData["designation"]}
                className={styles["info-input"]}
              />
            </span>
          </span>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>Identification Number</p>
              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["identificationNo"]}
              </p>
            </span>
          </span>
        </>
      ) : (
        <>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>First name</p>
              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["firstName"] !== null ? data["firstName"] : ""}
              </p>
            </span>

            <span>
              <p className={styles["label"]}>Second name</p>

              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["lastName"] !== null ? data["lastName"] : ""}
              </p>
            </span>
          </span>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>Mobile</p>
              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["mobileNo"] !== null ? data["mobileNo"] : ""}
              </p>
            </span>

            <span>
              <p className={styles["label"]}>Personal Email</p>

              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["personalMail"] !== null ? data["personalMail"] : ""}
              </p>
            </span>
          </span>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>Professional email</p>
              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["workMail"] !== null ? data["workMail"] : ""}
              </p>
            </span>

            <span>
              <p className={styles["label"]}>Designation</p>
              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["designation"] !== null ? data["designation"] : ""}
              </p>
            </span>
          </span>
          <span className="flex items-center mb-5">
            <span className="mr-12">
              <p className={styles["label"]}>Identification Number</p>
              <p className={`${styles["info-input"]} cursor-not-allowed`}>
                {data["identificationNo"]}
              </p>
            </span>
          </span>
        </>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const newPwdChanged = (event) => {
    updatePwd({ ...pwd, new: event.target.value });
  };

  const confirmPwdChanged = (event) => {
    updatePwd({ ...pwd, confirm: event.target.value });
  };

  const updatePwdFormSubmit = (event) => {
    try {
      event.preventDefault();
      if (pwd.new === pwd.confirm) {
        updatePwdLoading(true);
        pwdUpdateForm();
      } else {
        alert("Passwords did not match");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPwdScreen = () => {
    try {
      return (
        <form onSubmit={updatePwdFormSubmit} className="flex flex-col mb-1">
          <div className="flex items-center">
            <span className="mr-12">
              <p className={styles["label"]}>New Password</p>
              <div className={styles["input-bg"]}>
                <input
                  autoComplete="off"
                  required
                  onChange={newPwdChanged}
                  type={pwdHidden ? "password" : "text"}
                  value={pwd["new"]}
                  className={styles["input"]}
                />
                <button
                  onClick={() => {
                    updatePwdHidden(!pwdHidden);
                  }}
                  type="button"
                >
                  {pwdHidden ? (
                    <IoEyeSharp className={styles["hide-icon"]} />
                  ) : (
                    <IoEyeOffSharp className={styles["hide-icon"]} />
                  )}
                </button>
              </div>
            </span>
            <span>
              <p className={styles["label"]}>Confirm New Password</p>
              <input
                autoComplete="off"
                required
                onChange={confirmPwdChanged}
                type="password"
                value={pwd["confirm"]}
                className={styles["info-input"]}
              />
            </span>
          </div>

          <span className="self-end mt-10 mr-5">
            <button
              disabled={pwdLoading}
              type="submit"
              className={`${styles["info-logout-btn"]} ${
                pwdLoading &&
                "cursor-not-allowed flex justify-center items-center"
              }`}
            >
              {pwdLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#FFF"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Update"
              )}
            </button>
          </span>
        </form>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = async () => {
    updatePop(true);
  };

  const closeModal = async () => {
    updatePop(false);
  };

  const renderPopup = () => {
    return (
      <div className={`${styles["modal"]}`}>
        <p className={`${styles.text} text-center mb-10`}>
          Do you really want to Logout
        </p>

        <span>
          <button
            onClick={closeModal}
            className="mr-9 text-blue-600 font-medium bg-[#F4F9FF] rounded px-5 py-1 border"
          >
            No
          </button>
          <button
            onClick={logoutClicked}
            className="text-blue-600 font-medium bg-red-600 text-white rounded px-5 py-1 border"
          >
            Yes
          </button>
        </span>
      </div>
    );
  };

  const changePwdClicked = () => {
    updatePwdScreen(true);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      const { history } = props;

      token === undefined && history.replace("/login");
      if (token !== undefined) {
        getInfo();
      }
    }
  }, []);

  const overlayStyle = { background: "rgba(0,0,0,0.5)" };

  return (
    <span className="flex flex-row justify-center h-screen overflow-hidden">
      <div
        className={`${styles.home} flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]`}
      >
        <SideBar />
        <div className="flex flex-col">
          <CampusNavBar />
          <div className="flex flex-col overflow-y-auto overflow-x-hidden">
            <NavLink path={path} claims={claims} />
            <div className={styles["account-container"]}>
              <div
                className={`${styles.profile} flex flex-row justify-between items-center`}
              >
                {renderInfo()}
                {loading ? (
                  <span className={`${styles["edit-btn"]} cursor-not-allowed`}>
                    <Oval
                      height={20}
                      width={20}
                      color="#3D65F4"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#3D65F4"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>
                ) : edit ? (
                  <button onClick={saveChanged} className={styles["edit-btn"]}>
                    Save
                  </button>
                ) : (
                  <button
                    disabled={pwdScreen}
                    onClick={editChanged}
                    className={`${styles["edit-btn"]} ${
                      pwdScreen && "cursor-not-allowed"
                    }`}
                  >
                    <span>
                      <RiPencilLine
                        className="text-blue-600 mb-1 mr-1"
                        size={20}
                      />
                    </span>
                    Edit
                  </button>
                )}
              </div>

              <div className={`${styles["info-container"]} flex flex-col mt-8`}>
                <p className={styles["info-main-heading"]}>
                  {pwdScreen ? "Update Password" : "My General Information"}
                </p>
                {pwdScreen ? (
                  renderPwdScreen()
                ) : (
                  <>
                    {renderGenInfo()}
                    <span className="self-end mt-5">
                      <button
                        onClick={openModal}
                        className={styles["info-logout-btn"]}
                      >
                        Logout
                      </button>

                      <Popup
                        open={popUp}
                        onClose={closeModal}
                        {...{ overlayStyle }}
                      >
                        {renderPopup()}
                      </Popup>
                      <button
                        disabled={edit}
                        onClick={changePwdClicked}
                        className={`${styles["info-change-pwd-btn"]} ${
                          edit && "cursor-not-allowed"
                        }`}
                      >
                        Change Password
                      </button>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default MyAccount;
