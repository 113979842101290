import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Oval } from "react-loader-spinner";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const Info = (props) => {
  const [loader, updateLoader] = useState(false);
  const [art, updateArt] = useState([]);
  const [desc, updateDesc] = useState([]);
  const [psos, updatePsos] = useState([]);
  const [marksDist, updateMarksDist] = useState({});
  const [selected, updateSelected] = useState(null);

  const {
    courseCode,
    activeCourse,
    branch,
    sec,
    secOptions,
    internalTypes,
    externalTypes,
  } = props;

  const renderEmptyView = (text) => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>{text}</p>
    </div>
  );

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const renderCoStatements = (each, idx) => {
    return (
      <li className={styles["co-list"]}>
        <p className="text-[#8F949F] font-semibold">
          {each?.coName} Statement:{" "}
          <span className="text-[#3B3B3C] font-bold">
            {activeCourse?.courseCode}.{idx + 1}
          </span>
        </p>
        <h1 className={styles["co-statement"]}>{each?.coDesc}</h1>
      </li>
    );
  };

  const renderMatrix = () => {
    const ans = [];
    try {
      for (let index = 1; index <= desc.length; index++) {
        ans.push(
          <tr
            className={`${styles["art-co-names"]} border border-blue-500 p-0 w-full`}
          >
            <th className={`border border-blue-500 p-0 py-2 w-full`}>
              {`${activeCourse?.courseCode}.${index}`}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderDynamicPso = () => {
    const ans = [];
    try {
      for (let each of psos) {
        ans.push(
          <th scope="col" className="p-0 py-2 text-center">
            {each?.["po"]}
          </th>
        );
      }
    } catch (err) {
      console.log(err);
    }
    return ans;
  };

  const renderPsoHeading = () => {
    const ans = [];
    try {
      for (let each = 1; each <= 12; each++) {
        ans.push(
          <th scope="col" className="p-0 py-2 text-center">
            PO{each}
          </th>
        );
      }
    } catch (err) {
      console.log(err);
    }
    return ans;
  };

  const renderPos = () => {
    const ans = [];
    try {
      for (let index = 1; index <= desc.length; index++) {
        const posList = [];
        for (let each = 1; each <= 12; each++) {
          posList.push(
            <td className="p-0 py-2 text-center">
              {selected[`CO${index}PO${each}`]?.["weightage"] ?? "-"}
            </td>
          );
        }

        for (let each = 1; each <= psos.length; each++) {
          posList.push(
            <td className="p-0 py-2 text-center">
              {selected[`CO${index}${psos?.[each - 1]?.["po"]}`]?.[
                "weightage"
              ] ?? "-"}
            </td>
          );
        }

        ans.push(
          <>
            <tr className="text-[#040C58] border border-t-0 border-l-0 border-blue-500">
              {posList}
            </tr>
          </>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getMarksDist = async () => {
    const token = Cookies.get("jwt_token");
    updateMarksDist({});

    try {
      const joinedSec = secOptions.join();

      const sectionsVal = sec === "" ? joinedSec : sec;

      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_ATTAINMENTS_COURSE_ASS_MARKS_DIST +
          `?courseId=${courseCode}&sectionList=${sectionsVal}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateMarksDist(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDescCo = async (id) => {
    updateLoader(true);
    updateDesc([]);
    updateArt([]);

    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_ART_MATRIX + `?courseId=${id}`,
        { Authorization: token }
      );

      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${id}`,
        { Authorization: token }
      );

      // console.log(response);

      if (response["data"]["statusCode"] === "OK") {
        updateArt(response["data"]["result"]);
      }

      if (responseDesc["data"]["statusCode"] === "OK") {
        updateDesc(responseDesc["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsos(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSelected = () => {
    let selectedData = {};

    for (let index = 1; index <= desc.length; index++) {
      for (let each = 1; each <= 12; each++) {
        selectedData[`CO${index}PO${each}`] = {
          weightage: "",
          coTblId: "",
        };
      }

      for (let each = 1; each <= psos.length; each++) {
        selectedData[`CO${index}${psos?.[each - 1]?.["po"]}`] = {
          weightage: "",
          coTblId: "",
        };
      }
    }
    const coNames = desc.map((each) => each.coName);

    for (let index = 1; index <= desc.length; index++) {
      if (activeCourse?.["courseHavRub"].toLowerCase() === "y") {
        for (let each = 1; each <= 12; each++) {
          for (let item of art.coPoMappingList) {
            if (
              item["coName"] === `CO${index}` &&
              item["poName"] === `PO${each}`
            ) {
              selectedData[`CO${index}PO${each}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }

        for (let each = 1; each <= psos.length; each++) {
          for (let item of art.coPoMappingList) {
            if (
              item["coName"] === `CO${index}` &&
              item["psoName"] === `${psos?.[each - 1]?.["po"]}`
            ) {
              selectedData[`CO${index}${psos?.[each - 1]?.["po"]}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }
      } else {
        for (let each = 1; each <= 12; each++) {
          for (let item of art) {
            if (
              item["coName"] === `${coNames[index - 1]}` &&
              item["poName"] === `PO${each}`
            ) {
              selectedData[`CO${index}PO${each}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }

        for (let each = 1; each <= psos.length; each++) {
          for (let item of art) {
            if (
              item["coName"] === `${coNames[index - 1]}` &&
              item["psoName"] === `${psos?.[each - 1]?.["po"]}`
            ) {
              selectedData[`CO${index}${psos?.[each - 1]?.["po"]}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }
      }
    }

    updateSelected(selectedData);
  };

  const scrollTbClickedLeft = () => {
    const container = document.getElementById("scrollTb");
    sideScroll(container, "left", 25, 100, 400);
  };

  const scrollTbClicked = () => {
    const container = document.getElementById("scrollTb");
    sideScroll(container, "right", 25, 100, 400);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    getDescCo(courseCode);
  }, [courseCode]);

  useEffect(() => {
    getMarksDist();
  }, [courseCode, sec, secOptions]);

  useEffect(() => {
    branch !== "" && getPsoData();
  }, [branch]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        getSelected();
      } catch (err) {
        console.log(err);
      }
    }
  }, [art]);

  return loader ? (
    renderLoader()
  ) : (
    <>
      <div className={styles["bg-containers"]}>
        <p className={styles["art-matrix-title"]}>Course Outcomes</p>

        {desc.length === 0 ? (
          renderEmptyView("No course outcomes found")
        ) : (
          <ul className="list-none mt-6 border-t border-[#456BF1]">
            {desc.map((each, idx) => renderCoStatements(each, idx))}
          </ul>
        )}
      </div>

      <div className={styles["bg-containers"]}>
        <p className={styles["art-matrix-title"]}>Articulation Matrix</p>

        {art.length === 0 ? (
          renderEmptyView("No articulation matrix data found")
        ) : (
          <div className="flex flex-col justify-start flex-wrap mb-5 mt-10 w-full">
            <div className={styles.table}>
              <table className="text-center border border-blue-500 w-2/4">
                <tr
                  className={`${styles.matrix} border border-blue-500 p-0 w-full`}
                >
                  <th className="truncate border border-blue-500 p-0 py-2 w-full">
                    Course Outcomes
                  </th>
                </tr>
                {renderMatrix()}
              </table>

              <div
                id="scrollTb"
                className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
              >
                <table className={`${styles.matrix} p-0 m-0`}>
                  <thead>
                    <tr
                      className={`border border-t-0 border-l-0 border-blue-500 p-0`}
                    >
                      {renderPsoHeading()}
                      {renderDynamicPso()}
                    </tr>
                  </thead>
                  <tbody>{renderPos()}</tbody>
                </table>
              </div>
            </div>

            <span className="flex self-end mt-4">
              <span
                onClick={scrollTbClickedLeft}
                className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] cursor-pointer rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2`}
              >
                <img
                  src="/assets/floatarrow.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </span>
              <span
                onClick={scrollTbClicked}
                className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 cursor-pointer pb-1.5 pl-2 pr-2"
              >
                <img
                  src="/assets/floatarrow.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </span>
            </span>
          </div>
        )}
      </div>

      <div className={styles["bg-containers"]}>
        <p className={styles["art-matrix-title"]}>
          Course Assessment Structure & % Of Marks Distribution
        </p>
        {Object.keys(marksDist).length === 0 ? (
          renderEmptyView(
            "No Course Assessment Structure & Marks distribution data found"
          )
        ) : (
          <table
            className={`${styles["marks-dist-table"]} text-center border border-blue-500 w-4/6 mt-5`}
          >
            <tr className="border border-blue-500 p-0 w-full">
              <th
                className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 w-1/5 "
                rowSpan="2"
              >
                Course Outcomes
              </th>
              <th
                className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
                colSpan={internalTypes.length}
              >
                Internals
              </th>

              <th
                className="bg-[#EFF3FF] border border-r-0 border-blue-500 p-0 py-1 px-1 "
                colSpan={externalTypes.length}
              >
                Externals
              </th>
            </tr>
            <tr className="border border-blue-500 p-0 w-full">
              {internalTypes.map((each) => (
                <th className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 ">
                  {each}
                </th>
              ))}

              {externalTypes.map((each) => (
                <th className="bg-[#EFF3FF] border last:border-r-0 border-blue-500 p-0 py-1 px-1 ">
                  {each}
                </th>
              ))}
            </tr>

            {desc.map((co, idx) => (
              <tr className="border border-blue-500 p-0 w-full">
                <th className="border border-blue-500 p-0 py-1">
                  {activeCourse?.courseCode}.{idx + 1}
                </th>
                {internalTypes.map((each) => (
                  <td className="border border-blue-500 p-0 py-1">
                    {marksDist?.["Internal"]?.[each]?.[`CO${idx + 1}`] ?? ""} %
                  </td>
                ))}

                {externalTypes.map((each) => (
                  <td className="border border-blue-500 p-0 py-1">
                    {marksDist?.["External"]?.[each]?.[`CO${idx + 1}`] ?? ""} %
                  </td>
                ))}
              </tr>
            ))}
          </table>
        )}
      </div>
    </>
  );
};

export default Info;
