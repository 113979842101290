import styles from "./index.module.css";
import { useState, useEffect } from "react";
import apiClient from "../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import ExcelMarkSheet from "./MarksheetScreen/ExcelMarkSheet";
import MarkSheetScreen from "./MarksheetScreen";
import QuestionPaperScreen from "./QuestionPaperScreen";
import MappedQuestionPaper from "./QuestionPaperScreen/MappedQuestionPaper";
import QuestionPaperAnalysis from "./QuestionPaperScreen/QuestionPaperAnalysis";

const screens = {
  markSheet: "marksSheet",
  questionPaper: "questionPaper",
  markSheetManual: "marksSheetManual",
  mappedQuestionPaper: "mappedQuestionPaper",
  questionPaperAnalysis: "questionPaperAnalysis",
};

const QuestionPaperMapping = (props) => {
  const { cls } = props;

  const [tab, updateTab] = useState(screens.markSheet);
  const [errors, updateErrors] = useState([]);
  const [data, updateData] = useState({});
  const [listName, updateList] = useState([]);
  const [examType, updateExamType] = useState("");
  const [templateName, updateTemplateName] = useState("");
  const [sec, updateSec] = useState("");
  const [statusList, updateStatusList] = useState([]);

  const updateTypeNames = (exam) => {
    try {
      const intTheory =
        exam["intTheoryShortList"] === null
          ? ""
          : exam["intTheoryShortList"].split(",");

      const intPract =
        exam["intPractShortList"] === null
          ? ""
          : exam["intPractShortList"].split(",");

      const intProj =
        exam["intProjShortList"] === null
          ? ""
          : exam["intProjShortList"].split(",");

      let internal = intTheory.concat(intPract).concat(intProj);
      internal = internal.filter((each) => each !== "");

      const extTheory =
        exam["extTheoryShortList"] === null
          ? ""
          : exam["extTheoryShortList"].split(",");

      const extPract =
        exam["extPractShortList"] === null
          ? ""
          : exam["extPractShortList"].split(",");
      const extProj =
        exam["extProjShortList"] === null
          ? ""
          : exam["extProjShortList"].split(",");

      let external = extTheory.concat(extPract).concat(extProj);

      external = external.filter((each) => each !== "");
      return internal + "," + external;
    } catch (err) {
      console.log(err);
    }
  };

  const getTemplates = async (id) => {
    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.COURSE_STRUCTURES + `?courseStructId=${id}`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        const list = updateTypeNames(response["data"]["result"]);
        updateList(list.replace(/(^,)|(,$)/g, "").split(","));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateQpTemplateName = (name, screenName, statusList) => {
    updateTemplateName(name);
    updateTab(screens[screenName]);
    updateStatusList(statusList);
  };

  const mappedGoBack = () => {
    updateQpTemplateName("");
    updateTab(screens.questionPaper);
    updateStatusList([]);
  };

  const tabChange = (event) => {
    updateTab(event.target.id);
  };

  const goBack = () => {
    updateErrors([]);
  };

  const excelGoBack = () => {
    goBack();
    updateTab(screens.markSheet);
  };

  const renderQpScreen = () => {
    switch (tab) {
      case screens.markSheetManual:
        return (
          <ExcelMarkSheet
            errors={errors}
            updateErrors={updateErrors}
            excelGoBack={excelGoBack}
            data={data}
            examType={examType}
            sec={sec}
          />
        );
      case screens.markSheet:
        return (
          <MarkSheetScreen
            data={data}
            updateTab={updateTab}
            listName={listName}
            errors={errors}
            updateErrors={updateErrors}
            sec={sec}
            updateSec={updateSec}
            updateExamType={updateExamType}
            goBack={goBack}
          />
        );
      case screens.questionPaper:
        return (
          <QuestionPaperScreen
            data={data}
            listName={listName}
            updateQpTemplateName={updateQpTemplateName}
          />
        );
      case screens.mappedQuestionPaper:
        return (
          <MappedQuestionPaper
            data={data}
            templateName={templateName}
            mappedGoBack={mappedGoBack}
            statusList={statusList}
          />
        );
      case screens.questionPaperAnalysis:
        return (
          <QuestionPaperAnalysis
            data={data}
            templateName={templateName}
            mappedGoBack={mappedGoBack}
            statusList={statusList}
          />
        );
      default:
        break;
    }
  };

  const renderHeaders = () => {
    switch (tab) {
      case screens.markSheetManual:
        return (
          <div
            className={`${styles["excel-header"]} flex items-center justify-between mb-4 border border-0 border-b pb-2 border-[#B8C4E7] z-10`}
          >
            <h1
              className={`${styles["overlay-heading"]} text-[#606981] text-lg z-10`}
            >
              Input Student Marks
            </h1>
            <h1 className={`${styles["exam-type"]} z-10`}>{examType}</h1>
            <span className="flex items-center z-10">
              <h1 className={styles["excel-course-info"]}>
                {`${data?.["courseShortName"]} (${data?.["courseCode"]})`}

                <br />
                <span className="text-[#8F949F] text-sm">{`${data?.["branch"]} - ${data?.["semester"]} (${data?.["batch"]})`}</span>
              </h1>

              <RxCross2
                onClick={cls}
                size={26}
                className="cursor-pointer text-[#456bf1] mr-8"
              />
            </span>
          </div>
        );

      case screens.mappedQuestionPaper:
        return (
          <span className="flex items-center justify-between mb-4 pb-2 border-[#B8C4E7]">
            <h1
              className={`${styles["overlay-heading"]} text-[#606981] text-lg`}
            >
              Question Paper Mapping
            </h1>
            <h1 className={styles["exam-type"]}>{examType}</h1>
            <span className="flex items-center">
              <h1 className={styles["excel-course-info"]}>
                {`${data?.["courseShortName"]} (${data?.["courseCode"]})`}

                <br />
                <span className="text-[#8F949F] text-sm">{`${data?.["branch"]} - ${data?.["semester"]} (${data?.["batch"]})`}</span>
              </h1>

              <RxCross2
                onClick={cls}
                size={26}
                className="cursor-pointer text-[#456bf1]"
              />
            </span>
          </span>
        );

      case screens.questionPaperAnalysis:
        return (
          <span className="flex items-center justify-between mb-4 pb-2 border-[#B8C4E7]">
            <h1
              className={`${styles["overlay-heading"]} text-[#606981] text-2xl`}
            >
              Question Paper Analysis
            </h1>

            <RxCross2
              onClick={cls}
              size={26}
              className="cursor-pointer text-[#456bf1]"
            />
          </span>
        );

      default:
        return (
          <>
            <span className="flex items-center justify-between">
              <h1 className={styles["overlay-title"]}>
                {" "}
                Question Paper Mapping
              </h1>
              <RxCross2
                onClick={cls}
                size={26}
                className="cursor-pointer text-[#456bf1]"
              />
            </span>
            <div className="bg-[#FFFFFF] p-5 pb-0 mt-3">
              <span className="flex flex-row items-center">
                <h1
                  id={screens.markSheet}
                  onClick={tabChange}
                  className={`hover:text-[#3f4451] ${
                    styles["overlay-heading"]
                  } ${
                    tab === screens.markSheet
                      ? "text-[#456bf1] underline text-xl"
                      : "text-[#606981] opacity-70 text-lg"
                  } mb-5 ml-2`}
                >
                  Input Marks Sheet
                </h1>
                <h1
                  id={screens.questionPaper}
                  onClick={tabChange}
                  className={`hover:text-[#3f4451] ${
                    styles["overlay-heading"]
                  } ${
                    tab === screens.questionPaper
                      ? "text-[#456bf1] underline text-xl"
                      : "text-[#606981] opacity-70 text-lg"
                  } mb-5 ml-2`}
                >
                  Input Question Paper
                </h1>
              </span>
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      const { item } = props;
      updateData(item[0]);
      getTemplates(item[0]["courseStructId"]);
    }
  }, []);

  return (
    <>
      {renderHeaders()}
      {renderQpScreen()}
    </>
  );
};

export default QuestionPaperMapping;
