import styles from "./index.module.css";
import * as Highcharts from "highcharts";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as jose from "jose";
import FiltersContext from "../../../../context/FiltersContext";
import { useState, useEffect, useContext } from "react";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

const GapAnalysis = (props) => {
  const [filters, updateFilters] = useState({
    batch: "",
    branch: "",
  });

  const { filtersBatch, filtersBranch } = useContext(FiltersContext);

  const [poFilter, updatePoFilter] = useState("");
  const [dropBatchOptions] = useState(filtersBatch);
  const [dropBranchOptions] = useState(filtersBranch);
  const [userType, updateType] = useState("");
  const [gapData, updateGapData] = useState([]);
  const [gap1Data, update1Data] = useState([]);
  const [gap2Data, update2Data] = useState([]);
  const [gap3Data, update3Data] = useState([]);
  const [gap4Data, update4Data] = useState([]);
  const [poData, updatePoData] = useState([]);

  const getOverallSpider = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { branch, batch } = filters;
      if (branch !== "" && batch !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.GAP_ANALYSIS +
            `?branch=${branch}&batch=${batch}`,
          { Authorization: token }
        );

        // console.log(response);

        if (response["data"]["statusCode"] === "OK") {
          updateGapData(response["data"]["result"]);
        } else {
          updateGapData([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSpider1 = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { branch, batch } = filters;
      if (branch !== "" && batch !== "" && poFilter !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.GAP_ANALYSIS_AY +
            `?ay=1st year&branch=${branch}&batch=${batch}&po=${poFilter}`,
          { Authorization: token }
        );

        // console.log(response);

        if (response["data"]["statusCode"] === "OK") {
          update1Data(response["data"]["result"]);
        } else {
          update1Data([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getSpider2 = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { branch, batch } = filters;
      if (branch !== "" && batch !== "" && poFilter !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.GAP_ANALYSIS_AY +
            `?ay=2nd year&branch=${branch}&batch=${batch}&po=${poFilter}`,
          { Authorization: token }
        );

        // console.log(response);

        if (response["data"]["statusCode"] === "OK") {
          update2Data(response["data"]["result"]);
        } else {
          update2Data([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSpider3 = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { branch, batch } = filters;
      if (branch !== "" && batch !== "" && poFilter !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.GAP_ANALYSIS_AY +
            `?ay=3rd year&branch=${branch}&batch=${batch}&po=${poFilter}`,
          { Authorization: token }
        );

        // console.log(response);

        if (response["data"]["statusCode"] === "OK") {
          update3Data(response["data"]["result"]);
        } else {
          update3Data([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSpider4 = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { branch, batch } = filters;
      if (branch !== "" && batch !== "" && poFilter !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.GAP_ANALYSIS_AY +
            `?ay=4th year&branch=${branch}&batch=${batch}&po=${poFilter}`,
          { Authorization: token }
        );

        // console.log(response);

        if (response["data"]["statusCode"] === "OK") {
          update4Data(response["data"]["result"]);
        } else {
          update4Data([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
          if (res["result"].length !== 0) {
            updatePoFilter(res["result"][0]["po"]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPos = () => {
    try {
      const posOptions = [];
      for (let item of poData) {
        posOptions.push(
          <option key={item["po"]} value={item["po"]}>
            {item["po"]}
          </option>
        );
      }
      return posOptions;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionDesc"]} value={item["optionDesc"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value });
  };

  const poChanged = (event) => {
    updatePoFilter(event.target.value);
  };

  const batchChanged = (event) => {
    updateFilters({ ...filters, batch: event.target.value });
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        // console.log(claim);
        if (claim["user_type"] === "HOD") {
          updateFilters({
            ...filters,
            branch: claim["dept_name"],
            batch: filtersBatch[0]["optionDesc"],
          });
          updateType("HOD");
        } else {
          updateFilters({
            ...filters,
            branch: filtersBranch[0]["optionValue"],
            batch: filtersBatch[0]["optionDesc"],
          });
        }
        getPoData();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        getOverallSpider();
      }
    }
  }, [filters.batch, filters.branch]);

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        getSpider1();
        getSpider2();
        getSpider3();
        getSpider4();
      }
    }
  }, [filters.batch, filters.branch, poFilter]);

  const options = {
    chart: {
      polar: true,
      type: "line",
      height: gapData.length !== 0 ? 400 + gapData.length * 2 : 400,
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      categories:
        gapData.length !== 0
          ? gapData.map((each) => each["po"])
          : [
              "PO1",
              "PO2",
              "PO3",
              "PO4",
              "PO5",
              "PO6",
              "PO7",
              "PO8",
              "PO9",
              "PO10",
              "PO11",
              "PO12",
            ],
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        overflow: "justify",
        style: {
          color: "#606981",
          fontWeight: 400,
          fontSize: 14,
          fontFamily: "Merriweather Sans",
        },
      },
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            textOutline: "none",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
    },

    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Actual PO",
        data:
          gapData.length !== 0
            ? gapData.map((each) => parseInt(each["poTargetAtt"]))
            : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#27AE7A",
      },
      {
        name: "Attained PO",
        data:
          gapData.length !== 0
            ? gapData.map((each) => parseInt(each["poAttPct"]))
            : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#040C58",
      },
    ],
  };

  const options1 = {
    chart: {
      polar: true,
      type: "line",
      //   width: 580,
      //   height: 400,
    },

    pane: {
      size: "55%",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      categories:
        gap1Data.length !== 0
          ? gap1Data.map((each) => each["courseCode"])
          : [
              "Course 1",
              "Course 2",
              "Course 3",
              "Course 4",
              "Course 5",
              "Course 6",
              "Course 7",
            ],
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        overflow: "justify",
        style: {
          color: "#606981",
          fontWeight: 400,
          fontSize: 15,
          fontFamily: "Merriweather Sans",
        },
      },
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            textOutline: "none",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
    },

    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Actual PO",
        data:
          gap1Data.length !== 0
            ? gap1Data.map((each) => parseInt(each["poTargetAtt"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#27AE7A",
      },
      {
        name: "Attained PO",
        data:
          gap1Data.length !== 0
            ? gap1Data.map((each) => parseInt(each["poAttPct"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#040C58",
      },
    ],
  };

  const options2 = {
    chart: {
      polar: true,
      type: "line",
      //   width: 580,
      //   height: 400,
    },

    pane: {
      size: "55%",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      categories:
        gap2Data.length !== 0
          ? gap2Data.map((each) => each["courseCode"])
          : [
              "Course 1",
              "Course 2",
              "Course 3",
              "Course 4",
              "Course 5",
              "Course 6",
              "Course 7",
            ],
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        overflow: "justify",
        style: {
          color: "#606981",
          fontWeight: 400,
          fontSize: 15,
          fontFamily: "Merriweather Sans",
        },
      },
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            textOutline: "none",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
    },

    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Actual PO",
        data:
          gap2Data.length !== 0
            ? gap2Data.map((each) => parseInt(each["poTargetAtt"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#27AE7A",
      },
      {
        name: "Attained PO",
        data:
          gap2Data.length !== 0
            ? gap2Data.map((each) => parseInt(each["poAttPct"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#040C58",
      },
    ],
  };

  const options3 = {
    chart: {
      polar: true,
      type: "line",
      //   width: 580,
      //   height: 400,
    },

    pane: {
      size: "55%",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      categories:
        gap3Data.length !== 0
          ? gap3Data.map((each) => each["courseCode"])
          : [
              "Course 1",
              "Course 2",
              "Course 3",
              "Course 4",
              "Course 5",
              "Course 6",
              "Course 7",
            ],
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        overflow: "justify",
        style: {
          color: "#606981",
          fontWeight: 400,
          fontSize: 15,
          fontFamily: "Merriweather Sans",
        },
      },
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            textOutline: "none",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
    },

    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Actual PO",
        data:
          gap3Data.length !== 0
            ? gap3Data.map((each) => parseInt(each["poTargetAtt"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#27AE7A",
      },
      {
        name: "Attained PO",
        data:
          gap3Data.length !== 0
            ? gap3Data.map((each) => parseInt(each["poAttPct"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#040C58",
      },
    ],
  };

  const options4 = {
    chart: {
      polar: true,
      type: "line",
      //   width: 580,
      //   height: 400,
    },

    pane: {
      size: "55%",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      categories:
        gap4Data.length !== 0
          ? gap4Data.map((each) => each["courseCode"])
          : [
              "Course 1",
              "Course 2",
              "Course 3",
              "Course 4",
              "Course 5",
              "Course 6",
              "Course 7",
            ],
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        overflow: "justify",
        style: {
          color: "#606981",
          fontWeight: 400,
          fontSize: 15,
          fontFamily: "Merriweather Sans",
        },
      },
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            textOutline: "none",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
    },

    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: "Actual PO",
        data:
          gap4Data.length !== 0
            ? gap4Data.map((each) => parseInt(each["poTargetAtt"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#27AE7A",
      },
      {
        name: "Attained PO",
        data:
          gap4Data.length !== 0
            ? gap4Data.map((each) => parseInt(each["poAttPct"]))
            : [0, 0, 0, 0, 0, 0, 0],
        pointPlacement: "on",
        type: "area",
        color: "#040C58",
      },
    ],
  };

  return (
    <div className={`${styles["dashboard-periodwisebar-bg"]} mb-20 mt-28`}>
      <div className="w-full flex flex-row justify-between items-center ml-5 mt-2 mb-6">
        <h1 className={styles["course-analysis-title"]}>
          GAP ANALYSIS OF PO'S
        </h1>
        <div className={styles["filters"]}>
          {userType !== "HOD" && (
            <select
              value={filters.branch}
              onChange={branchChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
            >
              {renderBranchOptions()}
            </select>
          )}
          <select
            value={filters.batch}
            onChange={batchChanged}
            className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
          >
            {renderBatchOptions()}
          </select>
        </div>
      </div>

      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
      <div
        className={`${styles["filter-po-container"]} focus:ring-0 mr-5 p-2 pr-5`}
      >
        <select
          onChange={poChanged}
          value={poFilter}
          className={`${styles["filter-select-po"]} focus:ring-0 p-2`}
        >
          {renderPos()}
        </select>
      </div>
      <div className="w-11/12 flex flex-row justify-between items-center ml-14 mt-2 mb-10">
        <div className={styles["individual-gaps"]}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options1}
            {...props}
          />
          <h1 className={styles["gaps-heading"]}>1st year</h1>
        </div>
        <div className={`${styles["individual-gaps"]} ml-2`}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options2}
            {...props}
          />
          <h1 className={styles["gaps-heading"]}>2nd year</h1>
        </div>
      </div>
      <div className="w-11/12 flex flex-row justify-between items-center ml-14 mt-2 mb-6">
        <div className={styles["individual-gaps"]}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options3}
            {...props}
          />
          <h1 className={styles["gaps-heading"]}>3rd year</h1>
        </div>
        <div className={`${styles["individual-gaps"]} ml-2`}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options4}
            {...props}
          />
          <h1 className={styles["gaps-heading"]}>4th year</h1>
        </div>
      </div>
    </div>
  );
};

export default GapAnalysis;
