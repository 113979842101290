import styles from "./index.module.css";

const ExternalWeightages = (props) => {
  const { name, intWeightsChanged, intWeights } = props;

  const weighChange = (event) => {
    intWeightsChanged(event);
  };

  return (
    <li className="mt-1 mr-10 flex flex-col">
      <label className={styles["project-type-label"]}>
        {name} Weightage (%)
      </label>
      <input
        required
        value={intWeights[`ext${name}Weight`]}
        onChange={weighChange}
        id={`ext${name}Weight`}
        type="text"
        className={`${styles["filter-select"]} focus:ring-0 p-2`}
        placeholder="65%"
      />

      <span className="flex flex-col">
        <label className={styles["project-type-label"]}>Threshold Value</label>
        <input
          required
          value={intWeights[`ext${name}ThValue`]}
          onChange={weighChange}
          id={`ext${name}ThValue`}
          type="text"
          className={`${styles["filter-select"]} focus:ring-0 p-2`}
          placeholder="50%"
        />
      </span>
    </li>
  );
};

export default ExternalWeightages;
