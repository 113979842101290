import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import styles from "./index.module.css";
import { useEffect, useState, useContext } from "react";
import FiltersContext from "../../../../../../context/FiltersContext";

const buttonValues = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const CourseOutComeTable = (props) => {
  const {
    metricsId,
    metricInfo,
    template,
    uploadedStatus,
    branch,
    academicYear,
    statusCode,
  } = props;

  const [semButton, updateSemButton] = useState(Object.keys(buttonValues)[0]);
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);
  const [filtData, updateFiltData] = useState({});
  const [coursesData, updateCoursesData] = useState([]);
  const [firstCore, setFirstCore] = useState("");
  const [secondCore, setSecondCore] = useState("");
  const [isCourse, setIsCourse] = useState(false);
  const [desc, updateDesc] = useState([]);
  const [desc2, updateDesc2] = useState([]);
  const [courseIdList, setCourseIdList] = useState([]);

  const { filtersBatch } = useContext(FiltersContext);

  const submitted1 = async (event) => {
    event.preventDefault();
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      for (let count = 1; count <= rowsCount1; count++) {
        reqBody.push({
          courseOutcomeId: document.getElementById(`${count}-coStatement-1`)
            .name,
          tableNum: "1",
          courseCode: document.getElementById("1-code").value,
          courseName: document.getElementById("1-title").value,
          semester: semButton,
          coName: `CO${count}`,
          coStatement: document.getElementById(`${count}-coStatement-1`).value,
        });
      }

      const options = {
        method: (filtData?.["1"]?.length ?? 0) === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const submitted2 = async (event) => {
    event.preventDefault();
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      for (let count = 1; count <= rowsCount2; count++) {
        reqBody.push({
          courseOutcomeId: document.getElementById(`${count}-coStatement-2`)
            .name,
          tableNum: "2",
          courseCode: document.getElementById("2-code").value,
          courseName: document.getElementById("2-title").value,
          semester: semButton,
          coName: `CO${count}`,
          coStatement: document.getElementById(`${count}-coStatement-2`).value,
        });
      }

      const options = {
        method: (filtData?.["2"]?.length ?? 0) === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const increaseCount1 = () => {
    setRowsCount1(rowsCount1 + 1);
  };

  const decreaseCount1 = () => {
    rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
  };

  const increaseCount2 = () => {
    setRowsCount2(rowsCount2 + 1);
  };

  const decreaseCount2 = () => {
    rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
  };

  const clickedSemBtn = (event) => {
    updateSemButton(event.target.value);
    updateFiltData({});
    setCourseIdList([]);
  };

  const renderButtonOptions = () => {
    return (
      <>
        {Object.entries(buttonValues).map(([key, value]) => (
          <button
            key={key}
            value={key}
            className={`${
              semButton === key
                ? styles["sem-button-active"]
                : styles["sem-button"]
            }`}
            onClick={clickedSemBtn}
          >
            {value}
          </button>
        ))}
      </>
    );
  };

  const renderStructure = (rowsCount, table) => {
    let result = [];

    let courseState;
    let rowsCountUpdated = rowsCount;

    if (statusCode === "303") {
      courseState = table === "1" ? desc : desc2;
      rowsCountUpdated = table === "1" ? desc.length : desc2.length;
    }

    for (let count = 1; count <= rowsCountUpdated; count++) {
      result.push(
        <tr key={`sem-${semButton}-${table}-${count}`}>
          <td>{`CO${count}`}</td>
          <td colSpan={3}>
            {statusCode === "303" ? (
              <span className="text-[#606981]">
                {courseState?.[count - 1]?.["coDesc"] ?? ""}
              </span>
            ) : (
              <input
                required
                name={
                  filtData?.[table]?.[count - 1]?.["course_outcome_id"] ?? ""
                }
                type="text"
                id={`${count}-coStatement-${table}`}
                defaultValue={
                  filtData?.[table]?.[count - 1]?.["co_statement"] ?? ""
                }
              />
            )}
          </td>
        </tr>
      );
    }
    return result;
  };

  const getCourses = async () => {
    const token = Cookies.get("jwt_token");

    updateCoursesData([]);

    try {
      const batch = filtersBatch.filter(
        (each) => each?.["optionDesc"] === academicYear
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=${batch[0]?.["optionValue"]}&branch=${branch}&regulation=&semester=${semButton}&section=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateCoursesData(
          response?.["data"]?.["result"]?.["courseDetailsResponses"] ?? []
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDescCo = async (courseId, table) => {
    table === "1" ? updateDesc([]) : updateDesc2([]);

    const token = Cookies.get("jwt_token");
    try {
      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${courseId}`,
        { Authorization: token }
      );

      if (responseDesc["data"]["statusCode"] === "OK") {
        table === "1"
          ? updateDesc(responseDesc["data"]["result"])
          : updateDesc2(responseDesc["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const storeCoreCourses = async () => {
    if (firstCore === secondCore) {
      alert("Two core courses should not be same");
      return;
    }

    const reqBody = {
      semester: semButton,
      courseIds: [firstCore, secondCore].join(),
    };

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const options = {
        method: "POST",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA_STORE_CORE_COURSES +
          `/${metricsId}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const courseSelChanged = (event) => {
    if (event.target.name === "1") {
      setFirstCore(event.target.value);
    } else {
      setSecondCore(event.target.value);
    }
  };

  const renderCourses = (table) =>
    coursesData.map((each) => (
      <span className="flex flex-row mr-5">
        <input
          id={`${each?.["courseId"]}-${table}`}
          type="radio"
          value={each?.["courseId"]}
          name={table}
          onChange={courseSelChanged}
        />
        <label
          htmlFor={`${each?.["courseId"]}-${table}`}
          className={styles["select-courses"]}
        >
          {each?.["courseShortName"]}
        </label>
      </span>
    ));

  const renderCourseSelectionView = () => (
    <div className="flex flex-col">
      <div className="mt-5">
        <p className={styles["select-courses-text"]}>
          Select the first core course :
        </p>
        <div className="flex flex-wrap">{renderCourses("1")}</div>
      </div>

      <div className="mt-5">
        <p className={styles["select-courses-text"]}>
          Select the second core course :
        </p>
        <div className="flex flex-wrap">{renderCourses("2")}</div>
      </div>

      {firstCore !== "" && secondCore !== "" && (
        <button
          onClick={storeCoreCourses}
          className={styles["submit-btn"]}
          type="button"
        >
          Submit
        </button>
      )}
    </div>
  );

  useEffect(() => {
    if (statusCode === "303") {
      const semCourses = metricInfo?.["course-ids"]?.filter(
        (each) => `${each["semester"]}` === `${semButton}`
      );

      if (semCourses.length > 0) {
        const ids = semCourses?.[0]?.["course_ids"]?.split(",");

        ids?.forEach((each, idx) => {
          getDescCo(each, `${idx + 1}`);
        });

        setCourseIdList(ids);
      }

      branch !== undefined && academicYear !== undefined && getCourses();

      setIsCourse(semCourses.length > 0);
    } else {
      const filteredData = metricInfo?.["course-outcome-table"]?.filter(
        (each) => `${each["semester"]}` === `${semButton}`
      );

      const groupByTableNum = filteredData?.reduce((acc, item) => {
        const tableNum = item["table_num"];

        if (!acc[tableNum]) {
          acc[tableNum] = [];
        }

        acc[tableNum] = [...acc[tableNum], item];

        return acc;
      }, {});

      updateFiltData(groupByTableNum);
    }
    setFirstCore("");
    setSecondCore("");
  }, [branch, academicYear, statusCode, semButton]);

  useEffect(() => {
    setRowsCount1(filtData?.["1"]?.length ?? 1);
    setRowsCount2(filtData?.["2"]?.length ?? 1);
  }, [filtData]);

  return (
    <div className="p-2 pr-0">
      <span className={styles["span-sem"]}>Choose Semester</span>
      <div>{renderButtonOptions()}</div>

      {statusCode === "303" && !isCourse ? (
        renderCourseSelectionView()
      ) : (
        <>
          <form onSubmit={submitted1} className="p-2 pl-0 pr-0 flex flex-col">
            <table className={styles["course-outcome-table"]}>
              <tr>
                <th>Semester No:</th>
                <th colSpan={3}>{buttonValues[semButton]}</th>
              </tr>

              <tr>
                <th>Course Title:</th>
                <th>
                  {statusCode === "303" ? (
                    <span className="text-[#606981]">
                      {coursesData?.filter(
                        (each) =>
                          `${each?.["courseId"]}` === `${courseIdList?.[0]}`
                      )?.[0]?.["courseName"] ?? ""}
                    </span>
                  ) : (
                    <input
                      required
                      type="text"
                      id="1-title"
                      defaultValue={filtData?.["1"]?.[0]?.["course_name"] ?? ""}
                    />
                  )}
                </th>
                <th>Course Code:</th>
                <th>
                  {statusCode === "303" ? (
                    <span className="text-[#606981]">
                      {coursesData?.filter(
                        (each) =>
                          `${each?.["courseId"]}` === `${courseIdList?.[0]}`
                      )?.[0]?.["courseCode"] ?? ""}
                    </span>
                  ) : (
                    <input
                      required
                      type="text"
                      id="1-code"
                      defaultValue={filtData?.["1"]?.[0]?.["course_code"] ?? ""}
                    />
                  )}
                </th>
              </tr>
              <tr>
                <th>Course Outcome No:</th>
                <th colSpan={3}>Course Outcome Statement</th>
              </tr>

              {renderStructure(rowsCount1, "1")}
            </table>

            {!(statusCode === "303") && (
              <>
                <span className="self-end">
                  <button
                    onClick={decreaseCount1}
                    type="button"
                    className={styles["plus-btn"]}
                  >
                    -
                  </button>

                  <button
                    onClick={increaseCount1}
                    type="button"
                    className={styles["plus-btn"]}
                  >
                    +
                  </button>
                </span>

                {rowsCount1 > 0 && (
                  <button className={styles["submit-btn"]} type="submit">
                    Submit
                  </button>
                )}
              </>
            )}
          </form>

          <form onSubmit={submitted2} className="p-2 pl-0 pr-0 flex flex-col">
            <table className={styles["course-outcome-table"]}>
              <tr>
                <th>Semester No:</th>
                <th colSpan={3}>{buttonValues[semButton]}</th>
              </tr>

              <tr>
                <th>Course Title:</th>
                <th>
                  {statusCode === "303" ? (
                    <span className="text-[#606981]">
                      {coursesData?.filter(
                        (each) =>
                          `${each?.["courseId"]}` === `${courseIdList?.[1]}`
                      )?.[0]?.["courseName"] ?? ""}
                    </span>
                  ) : (
                    <input
                      required
                      type="text"
                      id="2-title"
                      defaultValue={filtData?.["2"]?.[0]?.["course_name"] ?? ""}
                    />
                  )}
                </th>
                <th>Course Code:</th>
                <th>
                  {statusCode === "303" ? (
                    <span className="text-[#606981]">
                      {coursesData?.filter(
                        (each) =>
                          `${each?.["courseId"]}` === `${courseIdList?.[1]}`
                      )?.[0]?.["courseCode"] ?? ""}
                    </span>
                  ) : (
                    <input
                      required
                      type="text"
                      id="2-code"
                      defaultValue={filtData?.["2"]?.[0]?.["course_code"] ?? ""}
                    />
                  )}
                </th>
              </tr>
              <tr>
                <th>Course Outcome No:</th>
                <th colSpan={3}>Course Outcome Statement</th>
              </tr>

              {renderStructure(rowsCount2, "2")}
            </table>

            {!(statusCode === "303") && (
              <>
                <span className="self-end">
                  <button
                    onClick={decreaseCount2}
                    type="button"
                    className={styles["plus-btn"]}
                  >
                    -
                  </button>

                  <button
                    onClick={increaseCount2}
                    type="button"
                    className={styles["plus-btn"]}
                  >
                    +
                  </button>
                </span>
                {rowsCount2 > 0 && (
                  <button className={styles["submit-btn"]} type="submit">
                    Submit
                  </button>
                )}
              </>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default CourseOutComeTable;
