import { Component } from "react";
import apiClient from "../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import InitialSetup from "../InitialSetup";
import SideBar from "../layouts/SideBar";
import ObeNavBar from "../layouts/ObeNavBar";
import FacultyNavBar from "../layouts/FacultyNavBar";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";
import styles from "./index.module.css";
import "../LoginForm/index.css";

class Feedback extends Component {
  state = {
    setUp: "loading",
    submitted: false,
    reqDesc: "",
    reqSubject: "",
    userType: "",
    isLoading: false,
  };

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_DASHBOARD`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          this.setState({ setUp: true });
        } else {
          this.setState({ setUp: false });
        }
      } else {
        this.setState({ setUp: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        // console.log(claim);
        this.setState({ userType: claim["user_type"] });
      }
      this.getBatchOptions();
    }
  }

  feedbackSubmit = async () => {
    const { reqDesc, reqSubject } = this.state;
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ reqSubject, reqDesc }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.FEEDBACK_AND_SUPPORT,
        options
      );

      // console.log(response);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["result"] === "OK") {
          this.setState({ submitted: true, reqDesc: "", reqSubject: "" });
        } else {
          alert(res["description"]);
        }
      } else {
        alert("Feedback/Support is not Submitted!");
      }
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  fbFormSubmitted = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.feedbackSubmit();
  };

  subjChanged = (event) => {
    this.setState({ reqSubject: event.target.value });
  };

  descChanged = (event) => {
    this.setState({ reqDesc: event.target.value });
  };

  FeedbackForm = () => {
    const { isLoading, submitted, reqDesc, reqSubject } = this.state;
    return (
      <div className={`${styles["fb-container"]}`}>
        {submitted ? (
          <>
            <img
              className={styles["thanks-img"]}
              alt="Feed Back"
              src="/assets/fb.svg"
            />
            <p className={styles["thanks-text"]}>Thank You For Your Input!</p>
            <p className={styles["thanks-desc"]}>
              Our team will now investigate and work on your feedback or
              reported issues. Your contribution is valuable as we strive to
              improve our web application. <br />
              <br /> If you have any more to share or need assistance, please
              feel free to reach out to at{" "}
              <a
                className="underline text-[#1C60FF]"
                href="mailto:team@fatcat.net.in"
              >
                team@fatcat.net.in
              </a>
              . Thanks again!"
            </p>
          </>
        ) : (
          <form
            onSubmit={this.fbFormSubmitted}
            className={`${styles["fb-form-container"]}`}
          >
            <p className={styles["fb-heading"]}>
              Help Us Improve Your Experience!
            </p>
            <p className={styles["fb-desc"]}>
              Please let us know about any problems, errors, or suggestions you
              have. Together, we'll make our web app even better. Thank you for
              your feedback!
            </p>

            <p className={styles["fb-label"]}>Subject</p>

            <input
              required
              onChange={this.subjChanged}
              value={reqSubject}
              type="text"
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
              placeholder="Ex : I couldn't update my password"
            />

            <p className={styles["fb-label"]}>Description</p>

            <textarea
              value={reqDesc}
              required
              onChange={this.descChanged}
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
              placeholder="Ex : Even after going through the profile page i was unable to change my profile picture, Please Help"
            ></textarea>

            <div>
              <button
                disabled={isLoading}
                type="submit"
                className={`${
                  isLoading &&
                  "cursor-not-allowed flex justify-center items-center"
                } ${styles["fb-submit-btn"]}`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    );
  };

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          return (
            <div className="flex flex-col overflow-y-auto overflow-x-hidden">
              {this.FeedbackForm()}
            </div>
          );
        case false:
          return <InitialSetup />;
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { userType } = this.state;
    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div
          className={`${styles["home"]} flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]`}
        >
          <SideBar />
          <div className="flex flex-col">
            {userType === "FACULTY" ? <FacultyNavBar /> : <ObeNavBar />}
            {this.renderView()}
          </div>
        </div>
      </span>
    );
  }
}

export default Feedback;
