import MyCourses from "./MyCourses";
import SideBar from "../../layouts/SideBar";
import FacultyNavBar from "../../layouts/FacultyNavBar";
import "./index.css";
import "../../LoginForm/index.css";

const FacultyLearnPage = () => {
  return (
    <span className="flex flex-row justify-center h-screen overflow-hidden">
      <div className="home flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]">
        <SideBar />
        <div className="flex flex-col">
          <FacultyNavBar />
          <div className="flex flex-col overflow-y-auto overflow-x-hidden">
            <MyCourses />
          </div>
        </div>
      </div>
    </span>
  );
};

export default FacultyLearnPage;
