import styles from "./index.module.css";
import ProjectListItems from "../ProjectListItems";
import RecentActivity from "../RecentActivity";
import WeeklyProgress from "../WeeklyProgress";
import TotalTasksPie from "../TotalTasksPie";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import * as jose from "jose";
import roles from "../../../../roles";

const projectOverlay = {
  loading: "loading",
  create: "create",
  success: "success",
  failed: "failed",
};

const projectData = {
  id: "",
  type: "",
  name: "",
  academicYear: "",
  branch: "",
  startDate: "",
  endDate: "",
  owner: "",
  priority: "",
  description: "",
};

const Projects = () => {
  const [isOpened, updatedOpened] = useState(false);
  const [projects, updateProjects] = useState([]);
  const [projectsLoader, updateProjectsLoader] = useState(false);
  const [newProject, updateNewProject] = useState(projectData);
  const [newProjectScreen, updateNewProjectScreen] = useState(
    projectOverlay.create
  );
  const [branchOptions, UpdateBranchOptions] = useState([]);
  const [batchOptions, UpdateBatchOptions] = useState([]);
  const [facultyOptions, updateFacultyOptions] = useState([]);
  const [searchItem, updateSearchItem] = useState("");
  const [isCourseModify, updateIsCourseModify] = useState(false);
  const [userdata, updateUserData] = useState({});

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const closeBtn = () => {
    updateIsCourseModify(false);
    updateNewProject(projectData);
    updateNewProjectScreen(projectOverlay.create);
    updatedOpened(false);
  };

  const openBtn = () => {
    updateIsCourseModify(false);
    updatedOpened(true);
  };

  const editBtn = (data) => {
    updateNewProject(data);
    updateIsCourseModify(true);
    updatedOpened(true);
  };

  const okBtn = () => {
    updateIsCourseModify(false);
    updateNewProject(projectData);
    updateNewProjectScreen(projectOverlay.create);
  };

  const tryAgainBtn = () => {
    updateNewProjectScreen(projectOverlay.create);
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const fetchProjects = async () => {
    updateProjectsLoader(true);
    updateProjects([]);
    const options = {
      method: "GET",
      headers,
    };

    try {
      const fetchedResponse = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_PROJECTS,
        options
      );

      if (fetchedResponse.ok) {
        const data = await fetchedResponse.json();

        const updatedData = data.map((each) => ({
          id: each["project_id"],
          name: each.name,
          type: each.type,
          academicYear: each["academic_year"],
          branch: each.branch,
          startDate: each["start_date"],
          endDate: each["end_date"],
          priority: each.priority,
          owner: each.owner,
          description: each.description,
        }));

        updateProjects(updatedData);
      } else {
        updateProjects([]);
      }
    } catch (err) {
      updateProjects([]);

      console.log(err);
    }

    updateProjectsLoader(false);
  };

  const createProject = async (event) => {
    event.preventDefault();

    updateNewProjectScreen(projectOverlay.loading);

    const {
      id,
      type,
      name,
      academicYear,
      branch,
      startDate,
      endDate,
      owner,
      priority,
      description,
    } = newProject;

    const reqBody = {
      type,
      name,
      academic_year: academicYear,
      branch:
        userdata?.["user_type"] === "PRINCIPAL"
          ? branch
          : userdata?.["dept_name"],
      start_date: startDate,
      end_date: endDate,
      owner:
        userdata?.["user_type"] === "PRINCIPAL" ? owner : userdata?.["sub"],
      priority,
      description,
    };

    const options = {
      method: isCourseModify ? "PUT" : "POST",
      headers,
      body: JSON.stringify(reqBody),
    };

    try {
      const storeProject = await fetch(
        isCourseModify
          ? apiClient.urls.fatcat.CONNECT_DASHBOARD_PROJECTS + `/${id}`
          : apiClient.urls.fatcat.CONNECT_DASHBOARD_PROJECTS,
        options
      );

      if (storeProject.ok) {
        updateNewProjectScreen(projectOverlay.success);
        fetchProjects();
      } else {
        updateNewProjectScreen(projectOverlay.failed);
      }
    } catch (err) {
      updateNewProjectScreen(projectOverlay.failed);
      console.log(err);
    }
  };

  const projectDetailsChanged = (event) => {
    const value = event.target.value;
    const key = event.target.name;

    updateNewProject({ ...newProject, [key]: value });
  };

  const renderCreateProjectScreen = () => (
    <>
      <div className={styles["project-details-container"]}>
        <h2 className={styles["project-details-heading"]}>Project Details</h2>
        <div className="flex items-center">
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Choose Type of Project</label>
            <select
              onChange={projectDetailsChanged}
              name="type"
              value={newProject.type}
              required
              className={`${styles["filter-select"]} focus:ring-0 p-2`}
            >
              <option disabled hidden value="">
                SELECT
              </option>
              <option value="SAR">SAR</option>
            </select>
          </span>

          <span className="w-2/6 mr-1 flex flex-col">
            <label className={styles.label}>Enter Project Name</label>
            <input
              onChange={projectDetailsChanged}
              name="name"
              value={newProject.name}
              required
              type="text"
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
              placeholder="ENTER"
            />
          </span>
        </div>

        <div className="flex items-center">
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Select Academic Year</label>
            <select
              onChange={projectDetailsChanged}
              name="academicYear"
              value={newProject.academicYear}
              required
              className={`${styles["filter-select"]} focus:ring-0 p-2`}
            >
              <option disabled hidden value="">
                SELECT YEAR
              </option>
              {renderBatchOptions()}
            </select>
          </span>

          {userdata?.["user_type"] === "PRINCIPAL" && (
            <span className="w-2/6 mr-16 flex flex-col">
              <label className={styles.label}>Choose Program</label>
              <select
                onChange={projectDetailsChanged}
                name="branch"
                value={newProject.branch}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option disabled hidden value="">
                  SELECT
                </option>
                {renderBranchOptions()}
              </select>
            </span>
          )}
        </div>

        <div className="flex items-center">
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Start Date</label>
            <input
              onChange={projectDetailsChanged}
              name="startDate"
              value={newProject.startDate}
              required
              type="date"
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
              placeholder="SELECT DATE"
            />
          </span>

          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>End Date</label>
            <input
              onChange={projectDetailsChanged}
              name="endDate"
              value={newProject.endDate}
              required
              type="date"
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
              placeholder="SELECT DATE"
            />
          </span>
        </div>

        <div className="flex items-center">
          {userdata?.["user_type"] === "PRINCIPAL" && (
            <span className="w-2/6 mr-16 flex flex-col">
              <label className={styles.label}>Select Owner</label>
              <select
                onChange={projectDetailsChanged}
                name="owner"
                value={newProject.owner}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option disabled hidden value="">
                  SELECT USER
                </option>
                {renderFacultyOptions()}
              </select>
            </span>
          )}
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Select Priority</label>
            <select
              onChange={projectDetailsChanged}
              name="priority"
              value={newProject.priority}
              required
              className={`${styles["filter-select"]} focus:ring-0 p-2`}
            >
              <option disabled hidden value="">
                SELECT
              </option>
              <option value={1}>Low</option>
              <option value={2}>Medium</option>
              <option value={3}>High</option>
            </select>
          </span>
        </div>

        <div className="flex items-center">
          <span className="w-2/6 mr-1 flex flex-col">
            <label className={styles.label}>Enter Project Description</label>
            <textarea
              onChange={projectDetailsChanged}
              name="description"
              value={newProject.description}
              required
              placeholder="Project Description"
              className={`${styles["filter-txt-desc"]} focus:ring-0 p-2`}
            ></textarea>
          </span>
        </div>
      </div>
      <span className="flex flex-row justify-end items-end mt-5 mb-5">
        <button
          onClick={closeBtn}
          className={`${styles["cancel-button"]} mr-5`}
          type="button"
        >
          Cancel
        </button>
        <button
          disabled={newProjectScreen === projectOverlay.loading}
          className={`${styles["create-button"]} ${
            newProjectScreen === projectOverlay.loading && "cursor-not-allowed"
          } `}
          type="submit"
        >
          {newProjectScreen === projectOverlay.loading ? (
            <Oval
              height={25}
              width={25}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : isCourseModify ? (
            "Update Course"
          ) : (
            "Create"
          )}
        </button>
      </span>
    </>
  );

  const renderNewProjectScreen = () => {
    switch (newProjectScreen) {
      case projectOverlay.create:
        return renderCreateProjectScreen();

      case projectOverlay.loading:
        return renderCreateProjectScreen();

      case projectOverlay.success:
        return (
          <div className="flex flex-col justify-center items-center h-screen max-h-96">
            <p className={styles["empty-view"]}>
              {isCourseModify
                ? "Project updated successfully!"
                : "Project created successfully!"}
            </p>
            <button
              onClick={okBtn}
              type="button"
              className={`${styles["create-button"]} mt-5`}
            >
              Ok
            </button>
          </div>
        );

      case projectOverlay.failed:
        return (
          <div className="flex flex-col justify-center items-center h-screen max-h-96">
            <p className={styles["empty-view"]}>
              {isCourseModify
                ? "Oops! Project not updated"
                : "Oops! Project not created"}
            </p>
            <button
              onClick={tryAgainBtn}
              type="button"
              className={`${styles["create-button"]} mt-5`}
            >
              Try again
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  const renderOverlayView = () => (
    <form onSubmit={createProject}>
      <h1 className={styles["project-main-heading"]}>Create Project</h1>
      {renderNewProjectScreen()}
    </form>
  );

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>No Projects Found To View</p>
    </div>
  );

  const searchInputChanged = (event) => {
    updateSearchItem(event.target.value);
  };

  const renderProjectsView = () => {
    const content = projectsLoader
      ? "loading"
      : projects.length === 0
      ? "empty"
      : "content";

    switch (content) {
      case "loading":
        return (
          <div className="absolute top-80 left-1/4">
            <Oval
              height={50}
              width={50}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        );

      case "content":
        const filteredData = projects.filter((each) =>
          each.name.toLowerCase().includes(searchItem.toLowerCase())
        );
        return (
          <ul className={styles["projects-list"]}>
            {filteredData.map((each) => (
              <ProjectListItems
                fetchProjects={fetchProjects}
                editBtn={editBtn}
                key={each.id}
                item={each}
                userdata={userdata}
              />
            ))}
          </ul>
        );

      case "empty":
        return renderEmptyView();

      default:
        return null;
    }
  };

  const getFacultyOptions = async () => {
    try {
      updateFacultyOptions([]);
      updateNewProject({ ...newProject, owner: "" });

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_DETAILS +
          `?branch=${newProject.branch}&facultyType=HOD`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateFacultyOptions(response["data"]["result"]);
      } else {
        updateFacultyOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBatchOptions = async () => {
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBatchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const options = [];
    try {
      for (const item of batchOptions) {
        options.push(
          <option key={item["optionValue"]} value={item["optionDesc"]}>
            {item["optionDesc"]}
          </option>
        );
      }
    } catch (err) {
      console.log(err);
    }
    return options;
  };

  const renderBranchOptions = () => {
    const options = [];
    try {
      for (const item of branchOptions) {
        options.push(
          <option key={item["optionValue"]} value={item["optionDesc"]}>
            {item["optionDesc"]}
          </option>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return options;
  };

  const renderFacultyOptions = () => {
    const options = [];
    try {
      for (const item of facultyOptions) {
        options.push(
          <option key={item.facultyUsername} value={item.facultyUsername}>
            {item.facultyUsername}
          </option>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return options;
  };

  useEffect(() => {
    fetchProjects();
    getBatchOptions();
    getBranchOptions();

    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateUserData(claim);
    }
  }, []);

  useEffect(() => {
    getFacultyOptions();
  }, [newProject.branch]);

  return (
    <>
      <div onClick={closeBtn} className={isOpened ? styles.overlay : "hidden"}>
        <div onClick={preventBubbling} className={styles["overlay-card"]}>
          {renderOverlayView()}
        </div>
      </div>
      <div className={styles["dashboard-container"]}>
        <div className={`${styles["projects-container"]} flex flex-col`}>
          <div className="flex items-center">
            <TotalTasksPie />

            <div className={styles["projects-card"]}>
              <h1>PROJECTS</h1>
              <span className="flex justify-around items-center">
                <h1 className={styles["projects-card-values"]}>
                  6 <br />
                  <span>Total</span>
                </h1>
                <h1 className={styles["projects-card-values"]}>
                  2 <br />
                  <span>Over due</span>
                </h1>
              </span>
            </div>

            <div className={styles["projects-card"]}>
              <h1>ISSUES</h1>
              <span className="flex justify-around items-center">
                <h1 className={styles["projects-card-values"]}>
                  15 <br />
                  <span>To be solved</span>
                </h1>
                <h1 className={styles["projects-card-values"]}>
                  2 <br />
                  <span>Solved</span>
                </h1>
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className={styles["search-input-container"]}>
              <img src="/assets/search.svg" alt="search" />
              <input
                onChange={searchInputChanged}
                placeholder="Search"
                type="search"
                value={searchItem}
                className={styles["search-input"]}
              />
              <div className={styles["vertical-line"]}></div>
              <span className={styles["search-input-title"]}>Projects</span>
            </div>

            {(userdata?.user_type === roles.principal ||
              userdata?.user_type === roles.hod) && (
              <button onClick={openBtn} className={styles["new-project-btn"]}>
                <img
                  src="/assets/connectOutline.svg"
                  alt="add"
                  className="mr-2"
                />
                New Project
              </button>
            )}
          </div>
          {renderProjectsView()}
        </div>
        <div className={`${styles["activity-container"]} flex flex-col`}>
          <RecentActivity />
          <WeeklyProgress />
        </div>
      </div>
    </>
  );
};

export default Projects;
