import { Link, withRouter } from "react-router-dom";
import styles from "./index.module.css";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import FacultyCourseList from "./FacultyCourseList";
import FiltersContext from "../../../../context/FiltersContext";

const FacultyCourseMapping = (props) => {
  const { match } = props;
  const { path } = match;

  const { filtersBatch, filtersBranch, filtersSem } =
    useContext(FiltersContext);

  const [filters, updateFilters] = useState({
    semester: "",
    branch: "",
    batch: "",
    section: "",
  });

  const [loader, updateLoader] = useState(true);
  const [userType, updateType] = useState("");
  const [dropBatchOptions] = useState(filtersBatch);
  const [dropBranchOptions] = useState(filtersBranch);
  const [dropSemOptions] = useState(filtersSem);
  const [dropSectionOptions, updateSecOptions] = useState([]);
  const [courses, updateCoursesData] = useState([]);

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    updateSecOptions([]);

    try {
      let batchVal = dropBatchOptions.filter(
        (each) => `${each.optionValue}` === `${filters.batch}`
      );
      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${
            batchVal?.[0]?.["optionDesc"] ?? ""
          }`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          updateSecOptions(response["data"]["result"]);
          updateFilters({ ...filters, section: response["data"]["result"][0] });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <li
            className={
              `${filters.batch}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            value={item["optionValue"]}
            onClick={batchChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      if (userType === "FRESHMAN") {
        ans.push(
          <li
            className={
              `${filters.semester}` === `${dropSemOptions[0]["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[0]["optionValue"]}
            value={dropSemOptions[0]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[0]["optionDesc"]}
          </li>
        );

        ans.push(
          <li
            className={
              `${filters.semester}` === `${dropSemOptions[1]["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[1]["optionValue"]}
            value={dropSemOptions[1]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[1]["optionDesc"]}
          </li>
        );
      } else {
        for (const item of dropSemOptions) {
          ans.push(
            <li
              className={
                `${filters.semester}` === `${item["optionValue"]}`
                  ? styles["batches-ul-active"]
                  : "bg-[#fff] text-[#606981]"
              }
              key={item["optionValue"]}
              value={item["optionValue"]}
              onClick={semesterChanged}
            >
              {item["optionDesc"]}
            </li>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSectionOptions = () => {
    const ans = [];
    try {
      for (const item of dropSectionOptions) {
        ans.push(
          <li
            className={
              `${filters.section}` === `${item}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item}
            id={item}
            onClick={sectionChanged}
          >
            {item}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value, section: "" });
  };

  const batchChanged = (event) => {
    updateFilters({ ...filters, batch: event.target.value, section: "" });
  };

  const semesterChanged = (event) => {
    const val = event.target.value;
    updateFilters({
      ...filters,
      semester: val,
    });
  };

  const sectionChanged = (event) => {
    const val = event.target.id;
    updateFilters({
      ...filters,
      section: val,
    });
  };

  const getCoursesData = async () => {
    const token = Cookies.get("jwt_token");

    updateLoader(true);
    updateCoursesData([]);

    try {
      if (
        filters.batch !== "" &&
        filters.branch !== "" &&
        filters.section !== ""
      ) {
        const response = await apiClient.get(
          apiClient.urls.fatcat.FACULTY_FOR_COURSE_MAPPING +
            `?branch=${filters.branch}&batch=${filters.batch}&regulation=&section=${filters.section}&semester=${filters.semester}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updateCoursesData(response["data"]["result"]);
        }
        updateLoader(false);
      }
      updateLoader(false);
    } catch (err) {
      updateLoader(false);
      console.log(err);
    }
  };

  const batchTabLeft = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "left", 25, 250, 20);
  };

  const batchTab = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "right", 25, 250, 20);
  };

  const semTabLeft = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "left", 25, 150, 20);
  };

  const semTab = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "right", 25, 150, 20);
  };

  const secTabLeft = () => {
    const container = document.getElementById("sec-tabs");
    sideScroll(container, "left", 25, 80, 20);
  };

  const secTab = () => {
    const container = document.getElementById("sec-tabs");
    sideScroll(container, "right", 25, 80, 20);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim);

        if (claim["user_type"] === "HOD") {
          updateFilters({
            ...filters,
            branch: claim["dept_name"],
            batch: filtersBatch[0]["optionValue"],
            semester: filtersSem[0]["optionValue"],
          });
        } else {
          updateFilters({
            ...filters,
            batch: filtersBatch[0]["optionValue"],
            branch: filtersBranch[0]["optionValue"],
            semester: filtersSem[0]["optionValue"],
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    getSectionOptions();
  }, [filters.batch, filters.branch]);

  useEffect(() => {
    getCoursesData();
  }, [filters.batch, filters.branch, filters.semester, filters.section]);

  return (
    <div className={`${styles["faculty-obe"]}`}>
      <div className="flex flex-row ml-8">
        <Link to="/obe/faculty/analysis">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/faculty/analysis"
                ? "font-bold underline"
                : "font-medium"
            } ml-4 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            ANALYSIS
          </h1>
        </Link>
        <Link to="/obe/faculty">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/faculty" ? "font-bold underline" : "font-medium"
            } ml-10 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            MANAGE FACULTY
          </h1>
        </Link>
        <Link to="/obe/faculty/faculty-course">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/faculty/faculty-course"
                ? "font-bold underline"
                : "font-medium"
            } ml-10 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            FACULTY-COURSE MAPPING
          </h1>
        </Link>
      </div>

      <div className="bg-[#FCFDFF] pb-32">
        <div className={`${styles["students-bg"]} mb-2 pl-2`}>
          {!(userType["user_type"] === "HOD") && (
            <div className={`${styles["students-filters"]} w-32 mr-3 ml-2`}>
              <select
                value={filters.branch}
                className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mt-7 mb-1.5 pr-5`}
                onChange={branchChanged}
              >
                {renderBranchOptions()}
              </select>
            </div>
          )}

          <div className={`${styles["students-filters"]} ml-1 w-5/12`}>
            <h1 className={`${styles["filters-title"]}`}>Choose Batch</h1>
            <div className="flex relative">
              <button
                onClick={batchTabLeft}
                className={styles["arrow-buttons"]}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul id="batch-tabs" className={styles["batches-ul"]}>
                {renderBatchOptions()}
              </ul>

              <button
                onClick={batchTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>

          <div className={`${styles["students-filters"]} ml-3 w-3/12`}>
            <h1 className={`${styles["filters-title"]}`}>Semester</h1>

            <div className="flex relative">
              <button onClick={semTabLeft} className={styles["arrow-buttons"]}>
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul
                id="sem-tabs"
                className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
              >
                {renderSemOptions()}
              </ul>

              <button
                onClick={semTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>

          {dropSectionOptions.length > 0 && (
            <div className={`${styles["students-filters"]} ml-3 w-2/12`}>
              <h1 className={`${styles["filters-title"]}`}>Section</h1>

              <div className="flex relative">
                <button
                  onClick={secTabLeft}
                  className={styles["arrow-buttons"]}
                >
                  <img
                    src="/assets/left-arr-outline.svg"
                    alt="arrow"
                    width={8}
                    height={8}
                  />
                </button>
                <ul
                  id="sec-tabs"
                  className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
                >
                  {renderSectionOptions()}
                </ul>

                <button
                  onClick={secTab}
                  className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
                >
                  <img
                    src="/assets/left-arr-outline.svg"
                    alt="arrow"
                    width={8}
                    height={8}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={`${styles["dashboard-periodwisebar-bg"]} mb-10`}>
          <div className="flex flex-row justify-between mb-5">
            <h1 className="ml-5 font-bold text-[#606981] mt-1.5">
              TOTAL COURSES : {courses?.length ?? ""}
            </h1>
          </div>

          <hr className={styles["hr-line"]} />

          <div className="font-bold text-base flex flex-row text-[#8F949F] mt-10 mb-4 pl-6">
            <p
              className={`${styles["table-labels"]} w-2/12 flex flex-row justify-start`}
            >
              Course ID
            </p>
            <p
              className={`${styles["table-labels"]} w-1/12 mr-6 flex flex-row justify-start whitespace-nowrap`}
            >
              Course ShortName
            </p>
            <p
              className={`${styles["table-labels"]} w-1/12 ml-16 flex flex-row justify-start`}
            >
              Section
            </p>
            <p
              className={`${styles["table-labels"]} w-2/12 ml-6 flex flex-row justify-start`}
            >
              Faculty
            </p>
            <p
              className={`${styles["table-labels"]} w-2/12 ml-12 flex flex-row justify-start`}
            >
              Action
            </p>
          </div>

          {loader ? (
            <div className="flex justify-center mt-10 w-full">
              <Oval
                height={50}
                width={50}
                color="#3d65f4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3d65f4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <ul>
              {filters.batch === "" ||
              filters.branch === "" ||
              filters.section === ""
                ? ""
                : courses.map((each, idx) => (
                    <FacultyCourseList
                      key={`${each?.courseId}-${idx}`}
                      index={idx}
                      item={each}
                      getCourses={getCoursesData}
                    />
                  ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FacultyCourseMapping);
