import styles from "./index.module.css";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";

const metricData = {
  criteriaNo: "",
  startDate: "",
  endDate: "",
};

const metricOverlay = {
  loading: "loading",
  create: "create",
  success: "success",
  failed: "failed",
};

const MetricAssignOverlay = (props) => {
  const { closeBtn, projectId, fetchCriteria, fetchMetrics, criteriaOptions } =
    props;

  const [newMetric, updateNewMetric] = useState(metricData);
  const [newMetricScreen, updateNewMetricScreen] = useState(
    metricOverlay.create
  );
  const [facultyOptions, updateFacultyOptions] = useState([]);
  const [userdata, updateUserData] = useState(null);
  const [isMetricModify, updateIsMetricModify] = useState(false);
  const [metricOptions, updateMetricOptions] = useState([]);
  const [metricSelectedValues, updateMetricSelectedValues] = useState([]);
  const [authorSelectedValues, updateAuthorSelectedValues] = useState([]);
  const [approverSelectedValues, updateApproverSelectedValues] = useState([]);

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const valuesChanged = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case "criteria":
        updateNewMetric({ ...newMetric, criteriaNo: inputValue });
        break;

      case "startDate":
        updateNewMetric({ ...newMetric, startDate: inputValue });
        break;

      case "endDate":
        updateNewMetric({ ...newMetric, endDate: inputValue });
        break;

      default:
        break;
    }
  };

  const renderCriteriaOptions = () => {
    const ans = [];

    criteriaOptions.forEach((each) => {
      ans.push(<option value={each?.criteria_no}>{each?.criteria_no}</option>);
    });

    return ans;
  };

  const createMetrics = async (event) => {
    event.preventDefault();

    const unSelected = [];

    metricSelectedValues.length < 1 && unSelected.push("Metric");
    authorSelectedValues.length < 1 && unSelected.push("Approver");
    approverSelectedValues.length < 1 && unSelected.push("Author");

    if (unSelected.length > 0) {
      const errMsg = `Select ${unSelected.join(", ")} options`;
      alert(errMsg);
      return;
    }

    updateNewMetricScreen(metricOverlay.loading);

    const metricsData = metricSelectedValues.map((each) => each.id);
    const authorData = authorSelectedValues.map((each) => each.id);
    const approverData = approverSelectedValues.map((each) => each.id);

    const reqBody = {
      ...newMetric,
      metricNo: metricsData,
      author: authorData,
      approver: approverData,
    };

    const options = {
      method: isMetricModify ? "PUT" : "POST",
      headers,
      body: JSON.stringify(reqBody),
    };

    try {
      const storeMetric = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC + `/${projectId}`,
        options
      );

      if (storeMetric.ok) {
        updateNewMetricScreen(metricOverlay.success);
        fetchMetrics === undefined ? fetchCriteria() : fetchMetrics();
      } else {
        updateNewMetricScreen(metricOverlay.failed);
      }
    } catch (err) {
      updateNewMetricScreen(metricOverlay.failed);
      console.log(err);
    }
  };

  const getFacultyOptions = async () => {
    if (userdata !== null) {
      try {
        const response = await apiClient.get(
          apiClient.urls.fatcat.FACULTY_DETAILS +
            `?branch=${userdata?.["dept_name"]}&facultyType=FACULTY`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          const updatedData = response?.["data"]?.["result"]?.map((each) => ({
            name: each.facultyUsername,
            id: each.facultyUsername,
          }));

          updateFacultyOptions(updatedData);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getMetricOptions = async () => {
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({ projectId, criteriaNo: newMetric.criteriaNo }),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_UNASSIGNED,
        options
      );

      if (response.ok) {
        const data = await response.json();

        const updatedData = data.map((each) => ({
          name: each?.metric_no,
          id: each?.metric_no,
        }));

        updateMetricOptions(updatedData);
      } else {
        updateMetricOptions([]);
      }
    } catch (err) {
      updateMetricOptions([]);
      console.log(err);
    }
  };

  const renderMetricCreateScreen = () => (
    <form onSubmit={createMetrics}>
      <div className="flex justify-between items-center">
        <h1 className={styles["project-main-heading"]}>New Metric</h1>
        <button type="button" className={styles["all-settings"]}>
          <img className="mr-3" src="/assets/chocolate-burger.svg" alt="icon" />
          All Settings
        </button>
      </div>
      <div className={styles["project-details-container"]}>
        <h2 className={styles["project-details-heading"]}>Metric Details</h2>
        <div className="flex items-center mt-3">
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Choose criteria</label>
            <select
              value={newMetric.criteriaNo}
              required
              name="criteria"
              onChange={valuesChanged}
              className={`${styles["filter-select"]} focus:ring-0 p-2`}
            >
              <option disabled hidden value="">
                SELECT
              </option>
              {renderCriteriaOptions()}
            </select>
          </span>

          <span className="w-2/6 mr-1 flex flex-col">
            <label className={styles.label}>Choose metrics</label>

            <Multiselect
              className={`${styles["filter-select"]}`}
              style={{ searchBox: { border: "none" } }}
              showCheckbox
              hideSelectedList
              avoidHighlightFirstOption={true}
              options={metricOptions}
              selectedValues={metricSelectedValues}
              onSelect={(selectedList) =>
                updateMetricSelectedValues(selectedList)
              }
              onRemove={(selectedList) =>
                updateMetricSelectedValues(selectedList)
              }
              displayValue="name"
            />
          </span>
        </div>

        <div className="flex items-center mt-3">
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Author</label>

            <Multiselect
              className={`${styles["filter-select"]}`}
              style={{ searchBox: { border: "none" } }}
              showCheckbox
              hideSelectedList
              avoidHighlightFirstOption={true}
              options={facultyOptions}
              selectedValues={authorSelectedValues}
              onSelect={(selectedList) =>
                updateAuthorSelectedValues(selectedList)
              }
              onRemove={(selectedList) =>
                updateAuthorSelectedValues(selectedList)
              }
              displayValue="name"
            />
          </span>

          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Approver</label>
            <Multiselect
              className={`${styles["filter-select"]}`}
              style={{ searchBox: { border: "none" } }}
              showCheckbox
              hideSelectedList
              avoidHighlightFirstOption={true}
              options={facultyOptions}
              selectedValues={approverSelectedValues}
              onSelect={(selectedList) =>
                updateApproverSelectedValues(selectedList)
              }
              onRemove={(selectedList) =>
                updateApproverSelectedValues(selectedList)
              }
              displayValue="name"
            />
          </span>
        </div>

        <div className="flex items-center mt-3">
          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>Start Date</label>
            <input
              name="startDate"
              onChange={valuesChanged}
              required
              type="date"
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
            />
          </span>

          <span className="w-2/6 mr-16 flex flex-col">
            <label className={styles.label}>End Date</label>
            <input
              name="endDate"
              onChange={valuesChanged}
              required
              type="date"
              className={`${styles["filter-txt"]} focus:ring-0 p-2`}
            />
          </span>
        </div>
      </div>
      <span className="flex flex-row justify-end items-end mt-5 mb-5">
        <button
          onClick={clsOverlay}
          className={`${styles["cancel-button"]} mr-5`}
          type="button"
        >
          Cancel
        </button>
        <button
          disabled={newMetricScreen === metricOverlay.loading}
          className={`${styles["create-button"]} ${
            newMetricScreen === metricOverlay.loading && "cursor-not-allowed"
          } `}
          type="submit"
        >
          {newMetricScreen === metricOverlay.loading ? (
            <Oval
              height={25}
              width={25}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : isMetricModify ? (
            "Update Metric"
          ) : (
            "Create"
          )}
        </button>
      </span>
    </form>
  );

  const renderMetricOverlayView = () => {
    switch (newMetricScreen) {
      case metricOverlay.create:
        return renderMetricCreateScreen();

      case metricOverlay.loading:
        return renderMetricCreateScreen();

      case metricOverlay.success:
        return (
          <>
            <div className="flex justify-between items-center">
              <h1 className={styles["project-main-heading"]}>New Metric</h1>
              <button type="button" className={styles["all-settings"]}>
                <img
                  className="mr-3"
                  src="/assets/chocolate-burger.svg"
                  alt="icon"
                />
                All Settings
              </button>
            </div>
            <div className="flex flex-col justify-center items-center h-screen max-h-96">
              <p className={styles["empty-view"]}>
                {isMetricModify
                  ? "Metric updated successfully!"
                  : "Metric assigned successfully!"}
              </p>
              <button
                onClick={okBtn}
                type="button"
                className={`${styles["create-button"]} mt-5`}
              >
                Ok
              </button>
            </div>
          </>
        );

      case metricOverlay.failed:
        return (
          <>
            <div className="flex justify-between items-center">
              <h1 className={styles["project-main-heading"]}>New Metric</h1>
              <button type="button" className={styles["all-settings"]}>
                <img
                  className="mr-3"
                  src="/assets/chocolate-burger.svg"
                  alt="icon"
                />
                All Settings
              </button>
            </div>

            <div className="flex flex-col justify-center items-center h-screen max-h-96">
              <p className={styles["empty-view"]}>
                {isMetricModify
                  ? "Oops! Metric not updated"
                  : "Oops! Metric not assigned"}
              </p>
              <button
                onClick={tryAgainBtn}
                type="button"
                className={`${styles["create-button"]} mt-5`}
              >
                Try again
              </button>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const tryAgainBtn = () => {
    updateNewMetricScreen(metricOverlay.create);
  };

  const okBtn = () => {
    updateIsMetricModify(false);
    updateNewMetric(metricData);
    updateNewMetricScreen(metricOverlay.create);
  };

  const clsOverlay = () => {
    closeBtn();
  };

  useEffect(() => {
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateUserData(claim);
    }
  }, []);

  useEffect(() => {
    getFacultyOptions();
  }, [userdata]);

  useEffect(() => {
    if (newMetric.criteriaNo !== "") {
      getMetricOptions();
    }
  }, [newMetric.criteriaNo]);

  return renderMetricOverlayView();
};

export default MetricAssignOverlay;
