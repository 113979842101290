import { Component } from "react";
import styles from "./index.module.css";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";
import * as jose from "jose";
import FiltersContext from "../../../../../context/FiltersContext";

const facMap = {
  batch: "",
  branch: "",
  deptName: "",
  regulation: "",
  semester: "",
  courseId: "",
  facultyId: "",
};

class FacultyCourseMapping extends Component {
  state = {
    optionsSec: [],
    sectionList: "",
    isLoading: false,
    file: null,
    created: false,
    data: facMap,
    courseData: [],
    facData: [],
    branchOptions: [],
    semOptions: [],
    sectionOptions: [],
    batchOptions: [],
    regOptions: [],
    userType: "",
    selectedOption: [],
    selectedList: [],
    selectedListCourse: [],
  };

  static contextType = FiltersContext;

  getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          regOptions: response["data"]["result"],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    const { batchOptions, data } = this.state;
    try {
      let batch = batchOptions.filter(
        (each) => each.optionValue === data.batch
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${data.branch}&batch=${batch[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          this.setState({
            sectionList: "",
            sectionOptions: response["data"]["result"],
          });
        } else {
          this.setState({
            sectionList: "",
            sectionOptions: [],
          });
        }
      } else {
        this.setState({
          sectionList: "",
          sectionOptions: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getOptions = async () => {
    try {
      const { filtersBatch, filtersBranch, filtersSem } = this.context;

      this.setState({
        batchOptions: filtersBatch,
        branchOptions: filtersBranch,
        semOptions: filtersSem,
      });
      await this.getRegOptions();
    } catch (err) {
      console.log(err);
    }
  };

  renderBatchOptions = () => {
    const { batchOptions } = this.state;

    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { semOptions, userType } = this.state;

    const ans = [];
    try {
      if (userType === "FRESHMAN") {
        ans.push(
          <option
            key={semOptions[0]["optionValue"]}
            value={semOptions[0]["optionValue"]}
          >
            {semOptions[0]["optionDesc"]}
          </option>
        );
        ans.push(
          <option
            key={semOptions[1]["optionValue"]}
            value={semOptions[1]["optionValue"]}
          >
            {semOptions[1]["optionDesc"]}
          </option>
        );
      } else {
        for (const item of semOptions) {
          ans.push(
            <option key={item["optionValue"]} value={item["optionValue"]}>
              {item["optionDesc"]}
            </option>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderRegOptions = () => {
    const { regOptions } = this.state;

    const ans = [];
    try {
      for (const item of regOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    const token = Cookies.get("jwt_token");
    const claim = jose.decodeJwt(token);
    this.setState({ userType: claim["user_type"] });
    if (navigator.onLine) {
      this.getOptions();
      this.getCourses();
      this.getFaculty();
    }
  }

  getCourses = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { data } = this.state;

      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=${data.batch}&branch=${data.branch}&regulation=${data.regulation}&semester=${data.semester}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          courseData: response["data"]["result"]["courseDetailsResponses"],
        });
      } else {
        this.setState({ courseData: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getFaculty = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { data } = this.state;

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_DETAILS +
          `?branch=${data.deptName}&facultyType=faculty&semester=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          facData: response["data"]["result"],
        });
      } else {
        this.setState({ facData: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  courseAndSectionApi = async () => {
    await this.getSectionOptions();
    await this.getCourses();
  };

  batchChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], batch: event.target.value, courseId: "" },
        selectedListCourse: [],
      }),
      this.courseAndSectionApi
    );
  };

  branchChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], branch: event.target.value, courseId: "" },
        selectedListCourse: [],
      }),
      this.courseAndSectionApi
    );
  };

  courseNameChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], courseId: event.target.value },
    }));
  };

  deptChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], deptName: event.target.value, facultyId: "" },
        selectedList: [],
      }),
      this.getFaculty
    );
  };

  facultyNameChanged = (event) => {
    this.setState((prev) => ({
      data: {
        ...prev["data"],
        facultyId: event.target.value,
        facultyName: event.target.textContent,
      },
    }));
  };

  regulationChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], regulation: event.target.value, courseId: "" },
        selectedListCourse: [],
      }),
      this.getCourses
    );
  };

  semesterChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], semester: event.target.value, courseId: "" },
        selectedListCourse: [],
      }),
      this.getCourses
    );
  };

  renderSectionOptions = () => {
    const ans = [];
    const { sectionOptions, optionsSec } = this.state;
    try {
      for (const item of sectionOptions) {
        ans.push(
          <span className="flex items-center justify-center mr-4 mb-2">
            <input
              id={item}
              checked={optionsSec.includes(item)}
              onChange={this.getSelectedSections}
              type="checkbox"
              value={item}
              className={`${styles["sec-check"]} mr-2`}
            />
            <label
              key={item}
              htmlFor={item}
              className={`${styles["sec-check-label"]} mt-1`}
            >
              {item}
            </label>
          </span>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getSelectedSections = (event) => {
    const { optionsSec } = this.state;
    const data = optionsSec;
    try {
      if (optionsSec.includes(event.target.value)) {
        const idx = optionsSec.indexOf(event.target.value);
        data.splice(idx, 1);
      } else {
        data.push(event.target.value);
      }
      this.setState({ optionsSec: data });
    } catch (err) {
      console.log(err);
    }
  };

  facMapping = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const { optionsSec, selectedList, selectedListCourse } = this.state;

      const facultyId =
        selectedList?.length > 0 ? selectedList[0].facultyId : null;
      const courseId =
        selectedListCourse?.length > 0 ? selectedListCourse[0].courseId : null;

      if (selectedList.length === 0 || selectedListCourse.length === 0) {
        alert("Please select an option in Courses and Faculty."); // Show alert if no option is selected
        this.setState({ isLoading: false });
        return;
      }

      if (optionsSec.length > 0) {
        const updatedData = {
          facultyCourseSectionList: optionsSec.join().replace(/^,|,$/g, ""),
          courseDetailsId: courseId,
          facultyId: facultyId,
        };

        const token = Cookies.get("jwt_token");

        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(updatedData),
        };

        const response = await fetch(
          apiClient.urls.fatcat.OBE_FACULTY_COURSE_MAPPING,
          options
        );

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["statusCode"] === "OK") {
            this.setState({ created: true });
          } else {
            alert(res["description"]);
          }
        } else {
          alert("Faculty-Course Mapping is Unsuccessful!");
        }
      } else {
        alert("Please select atleast one section");
      }
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  okBtn = () => {
    this.setState(
      {
        created: false,
        data: facMap,
        courseData: [],
        facData: [],
        optionsSec: [],
        sectionOptions: [],
      },
      this.props.reload
    );
  };

  handleSelect = (selectedList) => {
    this.setState({ selectedList });
  };

  handleRemove = (selectedList) => {
    this.setState({ selectedList });
  };

  handleSelectCourse = (selectedListCourse) => {
    this.setState({ selectedListCourse });
  };

  handleRemoveCourse = (selectedListCourse) => {
    this.setState({ selectedListCourse });
  };

  render() {
    const {
      created,
      data,
      courseData,
      facData,
      isLoading,
      selectedList,
      selectedListCourse,
    } = this.state;
    const facultyDetails = facData.map((each) => ({
      facultyId: each.facultyId,
      key: each.facultyName ? each.facultyName : `Faculty ${each.facultyId}`,
    }));

    const courseDetails = courseData.map((each) => ({
      courseId: each.courseId,
      key: each.courseName ? each.courseName : `Course ${each.courseName}`,
    }));

    return (
      <>
        {created ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              Faculty mapped successfully!
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : (
          <form onSubmit={this.facMapping}>
            <div className="flex flex-row justify-between flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pt-0 pb-10">
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.labels}> Regulation </label>
                <select
                  required
                  value={data.regulation}
                  onChange={this.regulationChanged}
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {this.renderRegOptions()}
                </select>
              </span>
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.labels}> Batch</label>
                <select
                  required
                  onChange={this.batchChanged}
                  value={data.batch}
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {this.renderBatchOptions()}
                </select>
              </span>
              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Branch</label>
                <select
                  required
                  onChange={this.branchChanged}
                  value={data.branch}
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {this.renderBranchOptions()}
                </select>
              </span>
              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}> Semester </label>
                <select
                  required
                  value={data.semester}
                  onChange={this.semesterChanged}
                  className={`${styles["filter-select"]} focus:ring-0 border-none p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {this.renderSemOptions()}
                </select>
              </span>

              <span className="lg:w-2/5 mt-1 mr-1 flex flex-col mb-2">
                <label className={styles.labels}>Section </label>

                <span className="flex items-center flex-wrap">
                  {this.renderSectionOptions()}
                </span>
              </span>

              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Select Course</label>
                <Multiselect
                  displayValue="key"
                  customCloseIcon={
                    <span
                      className={`${styles["cross-mark"]} border border-red-500`}
                    >
                      &#x274C;
                    </span>
                  }
                  onKeyPressFn={() => {}}
                  avoidHighlightFirstOption
                  onSelect={this.handleSelectCourse} // Access selected item here
                  onRemove={this.handleRemoveCourse}
                  selectedValues={selectedListCourse}
                  options={courseDetails}
                  selectionLimit={1}
                  placeholder={selectedListCourse.length > 0 ? "" : "SELECT"}
                  style={{
                    chips: {
                      background: "transparent",
                      color: "#606981",
                    },
                    multiselectContainer: {
                      background: "#f9fbff",
                    },
                    searchBox: {
                      fontSize: "14px", // Font size for the search box
                      fontFamily: "Merriweather Sans", // Font family for the search box
                      fontWeight: 400, // Font weight for the search box
                      color: "#606981", // Text color for the search box
                      background: "#ffffff", // Background color for the search box
                      border: "1.5px solid #f1f3f5", // Border style for the search box
                      borderRadius: "5px", // Border radius for the search box
                      marginBottom: "20px", // Margin bottom for the search box
                      WebkitAppearance: "none", // Remove default styling in WebKit browsers
                      MozAppearance: "none", // Remove default styling in Mozilla browsers
                      appearance: "none", // Remove default styling
                      outline: "none", // Remove outline
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/filterArrow.svg)`, // Add background image
                      backgroundRepeat: "no-repeat", // No repeat for the background image
                      backgroundPosition: "calc(100% - 20px) 17px", // Corrected position for the dropdown arrow
                    },
                  }}
                />
              </span>
              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Faculty Department</label>
                <select
                  required
                  onChange={this.deptChanged}
                  value={data.deptName}
                  className={`${styles["filter-select"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {this.renderBranchOptions()}
                </select>
              </span>

              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Select Faculty</label>
                <Multiselect
                  displayValue="key"
                  avoidHighlightFirstOption
                  customCloseIcon={
                    <span
                      className={`${styles["cross-mark"]} border border-red-500`}
                    >
                      &#x274C;
                    </span>
                  }
                  onKeyPressFn={() => {}}
                  onSelect={this.handleSelect} // Access selected item here
                  onRemove={this.handleRemove}
                  selectedValues={selectedList}
                  options={facultyDetails}
                  selectionLimit={1}
                  placeholder={selectedList.length > 0 ? "" : "SELECT"}
                  style={{
                    chips: {
                      background: "transparent",
                      color: "#606981",
                    },
                    multiselectContainer: {
                      color: "",
                      background: "#f9fbff",
                    },
                    searchBox: {
                      fontSize: "14px", // Font size for the search box
                      fontFamily: "Merriweather Sans", // Font family for the search box
                      fontWeight: 400, // Font weight for the search box
                      color: "#606981", // Text color for the search box
                      background: "#ffffff", // Background color for the search box
                      border: "1.5px solid #f1f3f5", // Border style for the search box
                      borderRadius: "5px", // Border radius for the search box
                      marginBottom: "20px", // Margin bottom for the search box
                      WebkitAppearance: "none", // Remove default styling in WebKit browsers
                      MozAppearance: "none", // Remove default styling in Mozilla browsers
                      appearance: "none", // Remove default styling
                      outline: "none", // Remove outline
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/filterArrow.svg)`, // Add background image
                      backgroundRepeat: "no-repeat", // No repeat for the background image
                      backgroundPosition: "calc(100% - 20px) 17px", // Corrected position for the dropdown arrow
                    },
                  }}
                />
              </span>
            </div>
            <span className="flex flex-row justify-end items-end mt-10 mb-5">
              <button
                disabled={isLoading}
                className={`${
                  isLoading && "cursor-not-allowed"
                } font-Lato-normal self-end text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-2 mr-5 mt-4`}
                type="submit"
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Map Faculty & Course"
                )}
              </button>
            </span>
          </form>
        )}
      </>
    );
  }
}

export default FacultyCourseMapping;
