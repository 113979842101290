import { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import styles from "./index.module.css";
import FiltersContext from "../../../../../../context/FiltersContext";

const ProgramArtMatrixTable = (props) => {
  const {
    uploadedStatus,
    branch,
    academicYear,
    metricInfo,
    metricsId,
    template,
    statusCode,
  } = props;

  const { filtersBatch } = useContext(FiltersContext);

  const [artData, updateArtData] = useState([]);
  const [filtData, updateFiltData] = useState({});
  const [rowsCount, setRowsCount] = useState(1);
  const [poData, updatePoData] = useState([]);
  const [psoData, updatePsoData] = useState([]);

  const increaseCount = () => {
    setRowsCount(rowsCount + 1);
  };

  const decreaseCount = () => {
    rowsCount > 0 && setRowsCount(rowsCount - 1);
  };

  const submitted = async (event) => {
    event.preventDefault();
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      for (let count = 1; count <= rowsCount; count++) {
        [...poData, ...psoData].forEach((each) =>
          reqBody.push({
            programArtId: document.getElementById(`${each["po"]}-${count}`)
              .name,
            courseName: document.getElementById(`name-${count}`).value,
            courseCode: document.getElementById(`code-${count}`).value,
            poName: each["po"],
            poValue: document.getElementById(`${each["po"]}-${count}`).value,
          })
        );
      }

      const options = {
        method:
          metricInfo["program-articulation-matrix-table"].length === 0
            ? "POST"
            : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderArtTable = () =>
    Object.keys(artData).length > 0 && (
      <>
        <div className={`${styles["table"]} pl-2 pr-2`}>
          <div
            id="dashboardArticulation"
            className={`${styles["table-scroll-art"]} border border-r-0 border-l-0 border-b-0 m-0`}
          >
            <table className="text-center border border-blue-500">
              <tbody>
                <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
                  <th
                    className={`${styles["table-th"]} border border-blue-500 p-2`}
                  >
                    S.No
                  </th>
                  <th
                    className={`${styles["table-th"]} border border-blue-500 p-2`}
                  >
                    Course Short Name
                  </th>
                  <th
                    className={`${styles["table-th"]} border border-blue-500 p-2`}
                  >
                    Course Code
                  </th>
                  {renderArtPoPso()}
                </tr>
                {renderPoArtTable()}
              </tbody>
            </table>
          </div>
        </div>
        <span className="flex justify-end self-end mt-3 pb-5">
          <span
            onClick={artscrollTbClickedLeft}
            className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
          <span
            onClick={artscrollTbClicked}
            className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
          >
            <img
              src="/assets/floatarrow.svg"
              alt="arrow"
              width={8}
              height={8}
            />
          </span>
        </span>
      </>
    );

  const renderArtPoPso = () => {
    try {
      let ans = [];
      const yearKeys = Object.keys(artData);

      let pos = artData?.[yearKeys?.[0]]?.["totalCoursesMappedForAY"];
      let posCount = Object.keys(pos);

      for (let each of posCount) {
        ans.push(
          <th className={`${styles["table-th"]} border border-blue-500 p-2`}>
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log();
    }
  };

  const renderPoArtTable = () => {
    try {
      let ans = [];
      const yearKeys = Object.keys(artData).sort();

      let count = 0;

      for (let year of yearKeys) {
        const courseKeys = Object.keys(artData[year]);
        for (let course of courseKeys) {
          if (
            !course.startsWith("totalCoursesMappedForAY") &&
            !course.startsWith("mappingAvgForAY")
          ) {
            count += 1;

            let posAns = [];

            for (let item of Object.entries(
              artData?.[year]?.[course]?.["poArticulationMatrixForCourse"]
            )) {
              posAns.push(
                <td
                  className={`${styles["table-td"]} border border-blue-500 p-2`}
                >
                  {item[1] === "0.00" ? "-" : item[1]}
                </td>
              );
            }

            ans.push(
              <tr className="border border-blue-500 text-[#606981] bg-[#FFFFFF] p-0">
                <td
                  className={`${styles["table-td"]} border border-blue-500 p-2`}
                >
                  {count}
                </td>
                <th
                  className={`${styles["table-th"]} border border-blue-500 p-2 text-[#040C58]`}
                >
                  {artData[year][course]["courseShortName"]}
                </th>
                <td
                  className={`${styles["table-td"]} border border-blue-500 p-2`}
                >
                  {artData[year][course]["courseCode"]}
                </td>
                {posAns}
              </tr>
            );
          }
        }

        let totalCoursesMapped = [];

        for (let item of Object.entries(
          artData?.[year]?.["totalCoursesMappedForAY"]
        )) {
          totalCoursesMapped.push(
            <th className={`${styles["table-th"]} border border-blue-500 p-2`}>
              {item[1]}
            </th>
          );
        }

        ans.push(
          <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
            <th
              className={`${styles["table-th"]} border border-blue-500 p-2`}
              colSpan={3}
            >
              Total Courses Mapped ({year})
            </th>
            {totalCoursesMapped}
          </tr>
        );

        let mappingAvg = [];

        for (let item of Object.entries(artData?.[year]?.["mappingAvgForAY"])) {
          mappingAvg.push(
            <th className={`${styles["table-th"]} border border-blue-500 p-2`}>
              {item[1]}
            </th>
          );
        }

        ans.push(
          <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
            <th
              className={`${styles["table-th"]} border border-blue-500 p-2`}
              colSpan={3}
            >
              Mapping Average ({year})
            </th>
            {mappingAvg}
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getPoArtDetails = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const batch = filtersBatch.filter(
        (each) => each?.["optionDesc"] === academicYear
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.DASHBOARD_PROGRAM_ART_MATRIX +
          `?batchId=${batch[0]?.["optionValue"]}&branch=${branch}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateArtData(response["data"]["result"]);
        uploadedStatus("success");
      } else {
        uploadedStatus("empty");
      }
    } catch (err) {
      console.log(err);
      uploadedStatus("failed");
    }
  };

  const artscrollTbClickedLeft = () => {
    const container = document.getElementById("dashboardArticulation");
    sideScroll(container, "left", 25, 100, 400);
  };

  const artscrollTbClicked = () => {
    const container = document.getElementById("dashboardArticulation");
    sideScroll(container, "right", 25, 100, 400);
  };

  const renderStructure = (rowsCount) => {
    let result = [];

    for (let count = 1; count <= rowsCount; count++) {
      const data = Object.keys(filtData ?? {});
      result.push(
        <tr key={`${count}-tr`}>
          <td className={styles["course-info"]}>
            <input
              required
              id={`code-${count}`}
              type="text"
              className={styles["label-box"]}
              defaultValue={
                filtData[data?.[count - 1]]?.["PO1"]?.["course_code"] ?? ""
              }
            />
          </td>

          <td className={styles["course-info"]}>
            <input
              required
              id={`name-${count}`}
              type="text"
              className={styles["label-box"]}
              defaultValue={
                filtData[data?.[count - 1]]?.["PO1"]?.["course_name"] ?? ""
              }
            />
          </td>

          {[...poData, ...psoData].map((each) => (
            <td key={`${each["po"]}-${count}-th`}>
              <input
                name={
                  filtData[data?.[count - 1]]?.[each["po"]]?.[
                    "program_art_id"
                  ] ?? ""
                }
                key={`${each["po"]}-${count}`}
                id={`${each["po"]}-${count}`}
                type="text"
                className={styles["label-box"]}
                defaultValue={
                  filtData[data?.[count - 1]]?.[each["po"]]?.["po_value"] ?? ""
                }
              />
            </td>
          ))}
        </tr>
      );
    }
    return result;
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsoData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPosNames = () => {
    try {
      let ans = [];

      for (let each of poData) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPsosNames = () => {
    try {
      let ans = [];

      for (let each of psoData) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    branch !== undefined && getPsoData();
  }, [branch, statusCode]);

  useEffect(() => {
    getPoData();
  }, []);

  useEffect(() => {
    if (statusCode === "303") {
      branch !== undefined && academicYear !== undefined && getPoArtDetails();
    } else {
      const groupByTableNumArt = metricInfo?.[
        "program-articulation-matrix-table"
      ]?.reduce((acc, item) => {
        const code = item["course_code"];
        const po = item["po_name"];

        if (!acc[code]) {
          acc[code] = {};
        }

        if (!acc[code][po]) {
          acc[code][po] = {};
        }

        acc[code][po] = item;

        return acc;
      }, {});

      updateFiltData(groupByTableNumArt);
    }
  }, [branch, academicYear, statusCode]);

  useEffect(() => {
    const count = Object?.keys(filtData)?.length ?? 1;

    setRowsCount(count === 0 ? 1 : count);
  }, [filtData]);

  return statusCode === "303" ? (
    renderArtTable()
  ) : (
    <form onSubmit={submitted} className="flex flex-col">
      <div className="w-[355px]">
        <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
          <div className={styles.table}>
            <div
              id="dashboardArticulation"
              className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
            >
              <table className={styles["course-art-table"]}>
                <thead>
                  <tr>
                    <th className={styles["course-info"]}>Course Code</th>
                    <th className={styles["course-info"]}>Course Name</th>

                    {renderPosNames()}
                    {renderPsosNames()}
                  </tr>
                </thead>
                <tbody>{renderStructure(rowsCount)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <span className="flex justify-end self-end">
        <button
          onClick={decreaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={increaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>
      <span className="flex justify-end self-end mt-3">
        <span
          onClick={artscrollTbClickedLeft}
          className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
        >
          <img src="/assets/floatarrow.svg" alt="arrow" width={8} height={8} />
        </span>
        <span
          onClick={artscrollTbClicked}
          className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
        >
          <img src="/assets/floatarrow.svg" alt="arrow" width={8} height={8} />
        </span>
      </span>
      {rowsCount > 0 && (
        <button className={styles["submit-btn"]} type="submit">
          Submit
        </button>
      )}
    </form>
  );
};

export default ProgramArtMatrixTable;
