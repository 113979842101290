import styles from "./index.module.css";

const MarksDistEnrolRatioTable = () => (
  <form className="p-2 pr-0 flex flex-col pt-5">
    <table className={styles["marks-enroll-table"]}>
      <thead>
        <tr>
          <th>
            Item (Students enrolled in the First Year on average over 3 academic
            years <br /> (CAY, CAYm1 and CAYm2))
          </th>
          <th className={styles["head-center"]}>Marks</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {` >= 90% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
            <br />
            {`CAYm1 and CAYm2)`}
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            {` >= 80% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
            <br />
            {`CAYm1 and CAYm2)`}
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            {` >= 70% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
            <br />
            {`CAYm1 and CAYm2)`}
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            {` >= 60% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
            <br />
            CAYm1 and CAYm2)
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            {` >= 50% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
            <br />
            {`CAYm1 and CAYm2)`}
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            {` >= 40% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
            <br />
            {`CAYm1 and CAYm2)`}
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>
      </tbody>
    </table>

    <button className={styles["submit-btn"]} type="submit">
      Submit
    </button>
  </form>
);

export default MarksDistEnrolRatioTable;
