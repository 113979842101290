import { Link } from "react-router-dom";
import "./index.css";

const InitialSetup = () => (
  <div className="flex flex-col items-center fixed left-1/2 top-1/3">
    <img
      src="/assets/campus-setup.svg"
      alt="Campus Setup"
      width={132}
      height={132}
    />
    <Link to="/obe/campus-setup">
      <button className="setup-campus-btn">Setup Campus</button>
    </Link>
  </div>
);

export default InitialSetup;
