import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import styles from "./index.module.css";
import { Component } from "react";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

class CourseStructureList extends Component {
  state = { internalData: [], externalData: [], itemData: {}, popUp: false };

  componentDidMount() {
    const { item } = this.props;

    const intTheory =
      item.intTheoryShortList === null
        ? ""
        : item.intTheoryShortList.split(",");

    const intPract =
      item.intPractShortList === null ? "" : item.intPractShortList.split(",");

    const intProj =
      item.intProjShortList === null ? "" : item.intProjShortList.split(",");

    const internal = intTheory.concat(intPract).concat(intProj);

    const internalValues = [];

    for (const each of internal) {
      if (each !== "") {
        internalValues.push(each);
      }
    }

    // console.log(internalValues)

    // internal = internal.filter((each) => each !== '');

    const extTheory =
      item.extTheoryShortList === null
        ? ""
        : item.extTheoryShortList.split(",");

    const extPract =
      item.extPractShortList === null ? "" : item.extPractShortList.split(",");
    const extProj =
      item.extProjShortList === null ? "" : item.extProjShortList.split(",");

    const external = extTheory.concat(extPract).concat(extProj);

    // external = external.filter((each) => each !== '');
    const externalValues = [];

    for (const each of external) {
      if (each !== "") {
        externalValues.push(each);
      }
    }

    this.setState({
      internalData: internalValues,
      externalData: externalValues,
      itemData: item,
    });
  }

  deleteStruct = async () => {
    const { itemData } = this.state;
    const token = Cookies.get("jwt_token");

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
        // Accept: 'application/json',
      },
    };
    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_COURSE_STRUCT_DELETE +
          `?courseStructId=${itemData["courseStructId"]}`,
        options
      );

      if (response["ok"] === true) {
        await this.closeModal();
        setTimeout(() => {
          this.props.reload();
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  renderPopup = () => {
    const { itemData } = this.state;

    return (
      <div className={`${styles["modal"]}`}>
        <p className={`${styles.text} text-center mb-10`}>
          Do you want to delete the{" "}
          <span className="capitalize font-black">
            {itemData["courseTypeName"]}
          </span>{" "}
          Course Structure?
        </p>

        <span>
          <button
            onClick={this.closeModal}
            className="mr-9 text-blue-600 font-medium bg-[#F4F9FF] rounded px-5 py-1 border"
          >
            No
          </button>
          <button
            onClick={this.deleteStruct}
            className="text-blue-600 font-medium bg-red-600 text-white rounded px-5 py-1 border"
          >
            Yes
          </button>
        </span>
      </div>
    );
  };

  openModal = async () => {
    this.setState({ popUp: true });
  };

  closeModal = async () => {
    this.setState({ popUp: false });
  };

  getIntTd = () => {
    const { internalData } = this.state;
    const ans = [];

    for (const each of internalData) {
      ans.push(
        <td className={`${styles.text} border border-blue-600 py-2`}>{each}</td>
      );
    }

    return ans;
  };

  getExtTd = () => {
    const { externalData } = this.state;
    const ans = [];

    for (const each of externalData) {
      ans.push(
        <td className={`${styles.text} border border-blue-600 py-2`}>{each}</td>
      );
    }

    return ans;
  };

  editStructureClicked = () => {
    const { editStructure, item } = this.props;
    editStructure(item);
  };

  render() {
    const { itemData, externalData, internalData, popUp } = this.state;
    const overlayStyle = { background: "rgba(0,0,0,0.5)" };

    return (
      <li>
        <table className="w-full mb-8 border-solid border-blue-600 border-2">
          <tr className=" bg-[#EFF3FF]">
            <td
              className="py-2 text-[#040C58] text-center font-semibold"
              colSpan={100}
            >
              <div className="flex flex-row">
                <p className={`${styles.title} w-11/12`}>
                  {itemData["courseTypeName"]}
                  <span className="text-[#4C5182] font-normal">
                    (All Regulations)
                  </span>
                </p>
                <span className="flex flex-row items-center w-1/12">
                  <RiPencilFill
                    className="text-blue-600 cursor-pointer"
                    onClick={this.editStructureClicked}
                  />
                  <Popup
                    {...{ overlayStyle }}
                    open={popUp}
                    onClose={this.closeModal}
                  >
                    {this.renderPopup()}
                  </Popup>
                  <MdDeleteOutline
                    className="text-red-600 ml-3 cursor-pointer"
                    onClick={this.openModal}
                  />
                </span>
              </div>
            </td>
          </tr>
          <tr className="text-center">
            {internalData.length !== 0 && (
              <td
                colSpan={internalData.length}
                className={`${styles.subtitle} py-2 border-blue-600 border-r border-t`}
              >
                Internal
              </td>
            )}
            {externalData.length !== 0 && (
              <td
                colSpan={externalData.length}
                className={`${styles.subtitle} py-2 border-blue-600 border-t`}
              >
                External
              </td>
            )}
          </tr>
          <tr className="text-center text-sm">
            {this.getIntTd()}
            {this.getExtTd()}
          </tr>
        </table>
      </li>
    );
  }
}

export default CourseStructureList;
