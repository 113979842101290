import ExcelView from "./ExcelView";
import styles from "./index.module.css";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";

const AddStudentsPage = (props) => {
  const { batch, branch, reload } = props;

  const [isOverlayActive, setIsOverlayActive] = useState(false);

  const showOverlay = (event) => {
    event.stopPropagation();
    setIsOverlayActive(true);
  };

  const overlayExcel = () => {
    setIsOverlayActive(false);
  };

  const clickOverlay = () => {
    if (isOverlayActive === true) {
      overlayExcel();
    }
  };

  const closeBtn = () => {
    setIsOverlayActive(false);
  };

  const excelEntryBtn = () => {
    setIsOverlayActive(false);
  };

  const preventPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <div
        className={styles["add-students-page-container"]}
        onClick={clickOverlay}
      >
        <img src="/studentassests/addStudents.svg" alt="add" />
        <button className={styles["add-students-button"]} onClick={showOverlay}>
          Add Students
        </button>
      </div>

      {isOverlayActive && (
        <div
          onClick={closeBtn}
          className={isOverlayActive ? styles.overlay : "hidden"}
        >
          <div onClick={preventPropagation} className={styles["overlay-card"]}>
            <span className="flex flex-row justify-between w-full">
              <h1 className={styles["overlay-title"]}>Enter Student Details</h1>
              <span className="flex items-center">
                <RxCross2
                  onClick={closeBtn}
                  size={26}
                  className="cursor-pointer text-[#456bf1] items-end"
                />
              </span>
            </span>
            <div className={styles["excel-sheet-container"]}>
              <ExcelView
                closeBtn={closeBtn}
                reload={reload}
                excelEntryBtn={excelEntryBtn}
                batch={batch}
                branch={branch}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddStudentsPage;
