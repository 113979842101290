import styles from "./index.module.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const AttainmentsTable = () => {
  const [poData, updatePoData] = useState([]);
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);


  const increaseCount = (each) => {
    if (each === 1) {
      setRowsCount1(rowsCount1 + 1);
    }
    if (each === 2) {
      setRowsCount2(rowsCount2 + 1);
    }

  
  };

  const decreaseCount = (each) => {
    if (each === 1) {
      rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
    }

    if (each === 2) {
      rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
    }

  };
  const sideScroll = (element, direction, speed, distance, step) => {
    if (!element) {
      console.error("Element not found.");
      return;
    }

    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };


  const artscrollTbClickedLeft = (each) => {
    const container = document.getElementById(each);
    sideScroll(container, "left", 25, 100, 400);
  };

  const artscrollTbClicked = (each) => {
    const container = document.getElementById(each);
    sideScroll(container, "right", 25, 100, 400);
  };

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);
      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPosNames = () => {
    try {
      let ans = [];
      for (let each of poData) {
        ans.push(<th key={each["po"]}>{each["po"]}</th>);
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderStructure = (rowsCount) => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>
            <input className={styles["label-box"]} required />
          </td>

          {[...poData].map((each) => (
            <td>
              <input className={styles["label-box"]} required />
            </td>
          ))}
        </tr>
      );
    }
    return result;
  };

  useEffect(() => {
    getPoData();
  }, []);

  return (
    <div>
      <>
        <form className="flex flex-col ">
          <div className="w-[355px]">
            <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
              <div className={styles.table}>
                <div
                  id="daTable"
                  className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                >
                  <table className={styles["attainments-table"]}>
                    <thead>
                      <tr>
                        <th className={styles["course-info"]}>Course</th>

                        {renderPosNames()}
                      </tr>
                    </thead>
                    <tbody>{renderStructure(rowsCount1)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <span className="flex justify-end self-end">
            <button
              onClick={() => decreaseCount(1)}
              type="button"
              className={styles["plus-btn"]}
            >
              -
            </button>

            <button
              onClick={() => increaseCount(1)}
              type="button"
              className={styles["plus-btn"]}
            >
              +
            </button>
          </span>
          <span className="flex justify-end self-end mt-3">
            <span
              onClick={() => artscrollTbClickedLeft("daTable")}
              className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={() => artscrollTbClicked("daTable")}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>
          {rowsCount1 > 0 && (
            <button className={`${styles["submit-btn"]} mb-5`} type="submit">
              Submit
            </button>
          )}
        </form>
      </>

      <>
        <form className="flex flex-col">
          <div className="w-[355px]">
            <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
              <div className={styles.table}>
                <div
                  id="idaTable"
                  className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                >
                  <table className={styles["attainments-table"]}>
                    <thead>
                      <tr>
                        <th className={styles["course-info"]}>
                          Name of the Survey
                        </th>

                        {renderPosNames()}
                      </tr>
                    </thead>
                    <tbody>{renderStructure(rowsCount2)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <span className="flex justify-end self-end">
            <button
              onClick={() => decreaseCount(2)}
              type="button"
              className={styles["plus-btn"]}
            >
              -
            </button>

            <button
              onClick={() => increaseCount(2)}
              type="button"
              className={styles["plus-btn"]}
            >
              +
            </button>
          </span>
          <span className="flex justify-end self-end mt-3">
            <span
              onClick={() => artscrollTbClickedLeft("idaTable")}
              className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={() => artscrollTbClicked("idaTable")}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>
          {rowsCount2 > 0 && (
            <button className={`${styles["submit-btn"]} mb-5`} type="submit">
              Submit
            </button>
          )}
        </form>
      </>

      <>
        <form className="flex flex-col">
          <div className="w-[355px]">
            <div className="flex flex-col justify-start flex-wrap mt-2 w-full">
              <div className={styles.table}>
                <div
                  id="overAllTable"
                  className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                >
                  <table className={styles["attainments-table"]}>
                    <thead>
                      <tr>
                        <th className={styles["course-info"]}>Assessment</th>

                        {renderPosNames()}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Direct Attainment</td>
                        {[...poData].map((each) => (
                          <td>
                            <input className={styles["label-box"]} required />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>Indirect Attainment</td>
                        {[...poData].map((each) => (
                          <td>
                            <input className={styles["label-box"]} required />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>Overall Attainment</td>
                        {[...poData].map((each) => (
                          <td>
                            <input className={styles["label-box"]} required />
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <span className="flex justify-end self-end">
            <button
              onClick={() => decreaseCount(3)}
              type="button"
              className={styles["plus-btn"]}
            >
              -
            </button>

            <button
              onClick={() => increaseCount(3)}
              type="button"
              className={styles["plus-btn"]}
            >
              +
            </button>
          </span>
          <span className="flex justify-end self-end mt-3">
            <span
              onClick={() => artscrollTbClickedLeft("overAllTable")}
              className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={() => artscrollTbClicked("overAllTable")}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>

          <button className={`${styles["submit-btn"]} mb-5`} type="submit">
            Submit
          </button>
        </form>
      </>
    </div>
  );
};

export default AttainmentsTable;
