import styles from "./index.module.css";

const InternalProjectType = (props) => {
  const {
    number,
    intLongChanged,
    intShortChanged,
    radioBtn,
    intTheoryShortList,
    intTheoryLongList,
    intProjShortList,
    intProjLongList,
    intPractShortList,
    intPractLongList,
    showRadio,
  } = props;

  const longChanged = (event) => {
    intLongChanged(event);
  };

  const shortChanged = (event) => {
    intShortChanged(event);
  };

  let sval;
  let lval;

  switch (radioBtn === "epic" ? "theoretical" : radioBtn) {
    case "theoretical":
      sval = intTheoryShortList[`${number}`];
      lval = intTheoryLongList[`${number}`];

      break;
    case "practical":
      sval = intPractShortList[`${number}`];
      lval = intPractLongList[`${number}`];

      break;
    case "project":
      sval = intProjShortList[`${number}`];
      lval = intProjLongList[`${number}`];

      break;
    default:
      break;
  }

  return (
    <li className="flex flex-row">
      <span className="flex flex-col w-1/3 mr-20">
        <label className={`${styles["project-type-label"]} capitalize`}>
          Internal {showRadio && `(${radioBtn})`} - {number} (Short Form)
        </label>
        <input
          value={sval}
          required
          name={radioBtn}
          onChange={shortChanged}
          id={`${number}`}
          type="text"
          className={`${styles["filter-select"]} focus:ring-0 p-2`}
        />
      </span>
      <span className="flex flex-col w-1/3">
        <label className={`${styles["project-type-label"]} capitalize`}>
          Internal {showRadio && `(${radioBtn})`} - {number} (Long Form)
        </label>
        <input
          value={lval}
          required
          name={radioBtn}
          onChange={longChanged}
          id={`${number}`}
          type="text"
          className={`${styles["filter-select"]} focus:ring-0 p-2`}
        />
      </span>
    </li>
  );
};

export default InternalProjectType;
