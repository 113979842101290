import styles from "./index.module.css";
import { Link, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import * as jose from "jose";
import Cookies from "js-cookie";

const CampusNavBar = (props) => {
  const [popActive, updatePop] = useState(false);
  const [userType, updateUserType] = useState("");
  const [clgName, updateClg] = useState("");

  const popBtn = () => {
    updatePop(!popActive);
  };

  useEffect(() => {
    const token = Cookies.get("jwt_token");

    if (token !== undefined) {
      const claims = jose.decodeJwt(token);
      updateUserType(claims["user_type"]);
      updateClg(claims["clg_name"]);
    }
  }, []);

  const logoutClicked = () => {
    const { history } = props;
    Cookies.remove("jwt_token");
    history.replace("/login");
  };

  document.addEventListener("click", (e) => {
    if (e.target.id === "box") {
      if (popActive === false) {
        updatePop(true);
      }
    } else {
      updatePop(false);
    }
  });

  const { history } = props;

  return (
    <nav
      className={`${styles["nav-bar"]} flex flex-row justify-between items-center`}
    >
      <span className="flex flex-row items-center">
        <div
          className={`${styles["tab-bar"]} flex flex-row justify-center items-center bg-[#ECF0FF] list-style-none`}
        >
          <p className={`${styles["college-name"]} truncate text-ellipsis`}>
            {clgName}
          </p>
        </div>

        <div
          className={`${styles["arrows-container"]} flex flex-row justify-between items-center ml-10`}
        >
          <div
            onClick={() => history.goBack()}
            className={`${styles["arrow-bg"]} cursor-pointer`}
          >
            <img
              src="/assets/leftarrow.svg"
              width={9}
              height={9}
              alt="leftarrow"
            />
          </div>
          <div
            onClick={() => history.goForward()}
            className={`${styles["arrow-bg"]} cursor-pointer`}
          >
            <img
              src="/assets/rightarrow.svg"
              width={9}
              height={9}
              alt="rightarrow"
            />
          </div>
        </div>
      </span>

      <span className="flex flex-row items-center">
        <div className="ml-5">
          <span
            className="
          text-gray-500 cursor-not-allowed
          hover:text-gray-700
          focus:text-gray-700
          mr-4 md:mr-8
          dropdown-toggle
          hidden-arrow
          flex items-center
        "
            id="dropdownMenuButton1"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className={`${styles.bell} flex flex-col items-center`}>
              <img
                src="/assets/notification.svg"
                width={20}
                height={20}
                alt="notifications"
              />
              <img
                src="/assets/bellbottom.svg"
                width={8}
                height={8}
                alt="notifications"
              />
            </span>
            <span
              className={`${styles.count} flex justify-center items-center text-white bg-orange-700 absolute border-2 border-white mb-2 ml-4 px-1`}
            >
              0
            </span>
          </span>
        </div>

        <div
          className={`${styles.profile} ml-3 cursor-pointer`}
          onClick={popBtn}
        >
          <img
            id="box"
            src="/assets/defaultImage.svg"
            className={`rounded-full`}
            width={45}
            height={45}
            alt="profile"
          />
          {popActive && (
            <span
              className={
                userType !== "FACULTY"
                  ? userType === "PRINCIPAL"
                    ? `${styles.dropdown} ${styles["dropdown-admin"]}`
                    : styles.dropdown
                  : styles.drop
              }
            >
              <ul className="flex flex-col p-5 list-none">
                <Link
                  to={
                    userType === "FACULTY" ? "/faculty/account" : "/obe/account"
                  }
                >
                  <li className={styles["li-items"]}>
                    <img
                      src="/assets/myAccount.svg"
                      width={18}
                      height={18}
                      alt="Account"
                    />
                    <span className={styles["li-text"]}>My Account</span>
                  </li>
                </Link>
                {userType === "PRINCIPAL" && (
                  <>
                    <Link to="/obe/hods">
                      <li className={styles["li-items"]}>
                        <img
                          src="/assets/hods.svg"
                          width={18}
                          height={18}
                          alt="Hods"
                        />
                        <span className={styles["li-text"]}>Manage HODs</span>
                      </li>
                    </Link>
                  </>
                )}
                {(userType === "PRINCIPAL" || userType === "HOD") && (
                  <Link to="/obe/campus-setup">
                    <li className={styles["li-items"]}>
                      <img
                        src="/assets/campusSetup.svg"
                        width={18}
                        height={18}
                        alt="Campus Setup"
                      />
                      <span className={styles["li-text"]}>
                        Manage Campus Setup
                      </span>
                    </li>
                  </Link>
                )}

                {(userType === "PRINCIPAL" || userType === "HOD") && (
                  <Link to="/obe/modify-weightages">
                    <li className="li-items">
                      <img
                        src="/assets/modify-weightages.svg"
                        width={18}
                        height={18}
                        alt="Hods"
                      />
                      <span className="li-text">Modify Weightages</span>
                    </li>
                  </Link>
                )}
                {userType === "PRINCIPAL" && (
                  <Link to="/obe/bulk-migration">
                    <li className="li-items">
                      <img
                        src="/assets/bulk-migration.svg"
                        width={18}
                        height={18}
                        alt="Hods"
                      />
                      <span className="li-text">Bulk Migration</span>
                    </li>
                  </Link>
                )}
              </ul>
              <button
                onClick={logoutClicked}
                className={`${styles["logout-btn"]} ml-6`}
              >
                Logout
              </button>
            </span>
          )}
        </div>
      </span>
    </nav>
  );
};

export default withRouter(CampusNavBar);
