import Cookies from "js-cookie";
import { Route, Redirect } from "react-router-dom";
import * as jose from "jose";

const ProtectedRoute = (props) => {
  const token = Cookies.get("jwt_token");
  if (token === undefined) {
    return <Redirect to="/login" />;
  }

  const { path } = props;
  const claim = jose.decodeJwt(token);

  if (path.startsWith("/feedback")) {
    return <Route {...props} />;
  }

  if (path.startsWith("/obe")) {
    if (claim["user_type"] === "FACULTY") {
      return <Redirect to="/faculty/dashboard/managecourses" />;
    }
  } else if (path.startsWith("/faculty")) {
    if (claim["user_type"] !== "FACULTY") {
      return <Redirect to="/obe/dashboard" />;
    }
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
