import React, { useRef, useState, useEffect } from "react";
import styles from "./index.module.css";
import { registerAllModules } from "handsontable/registry";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.min.css";
import { Oval } from "react-loader-spinner";
import { MdOutlineUploadFile } from "react-icons/md";
import Popup from "reactjs-popup";
import { RxCross2 } from "react-icons/rx";

registerAllModules();

const overlayStyle = { background: "rgba(0,0,0,0.5)" };

const ExcelView = (props) => {
  const hotTableComponent = useRef(null);

  const [showExcel, updateShowExcel] = useState(false);

  const [loading, updateLoading] = useState(false);
  const [loader, updateLoader] = useState(true);

  const [popUp, updatePopup] = useState(false);
  const [errors, updateErrors] = useState([]);
  const { batch, branch, excelEntryBtn, reload, closeBtn } = props;

  const [excelData, updateExcelData] = useState([[]]);

  const getExcelData = async () => {
    updateLoader(true);
    const token = Cookies.get("jwt_token");
    const queryParams = `?batchId=${batch}&branch=${branch}`;

    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(
        `${apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_EXCEL_TEMPLATE}${queryParams}`,
        options
      );
      if (response.ok) {
        updateShowExcel(true);
        const blob = await response.blob();

        excelConvert(blob);
      }
    } catch (err) {
      console.log(err);
    }
    updateLoader(false);
  };

  const highlightCells = (errCell) => {
    const hot = hotTableComponent.current.hotInstance;

    errCell.forEach(([row, col]) => {
      highlightCellByCoords(hot, row, col);
    });
  };

  const highlightCellByCoords = (hotInstance, row, col) => {
    hotInstance.setCellMeta(
      row - 1,
      col - 1,
      "className",
      `${styles["highlighted-cell"]}`
    );

    hotInstance.render();
  };

  const renderErrors = () => {
    let ans = [];

    try {
      for (let i = 0; i < errors.length; i++) {
        ans.push(
          <li key={i}>
            <span>{i + 1}.</span>
            {errors[i]}
          </li>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const addTemplateManually = async (fileData) => {
    try {
      updateLoading(true);
      updateErrors([]);

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: fileData,
      };

      const queryParams = `?batchId=${batch}&branch=${branch}`;

      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_BULK + queryParams,
        options
      );

      if (response.ok) {
        alert("Students are created!");
        reload();
        closeBtn();
      } else {
        const data = await response.json();
        updateErrors(data ?? ["Something went wrong!"]);
        alert("Students are not created!");
      }

      updateLoading(false);
    } catch (err) {
      alert("Students are not created!");
      console.log(err);
    }
    updateLoading(false);
  };

  const submitBtn = () => {
    if (hotTableComponent.current) {
      const hotInstance = hotTableComponent.current.hotInstance;
      const data = hotInstance.getData();
      // console.log(data);

      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate Excel file
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      const blob = new Blob([wbout], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = "HandsontableData.xlsx";

      // a.click();

      const formData = new FormData();
      formData.append("file", blob);

      addTemplateManually(formData);
    }
  };

  const goBack = () => {
    excelEntryBtn();
  };

  const excelConvert = (blob) => {
    try {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, {
          type: "binary",
          cellDates: true,
        });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Parse the sheet to preserve empty rows
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        const rows = [];
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const row = [];
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            const cell = sheet[cellRef];
            if (cell?.t === "d") {
              row.push(
                cell.v instanceof Date
                  ? cell.v.toISOString().split("T")[0]
                  : cell.v
              );
            } else {
              row.push(cell ? cell.v : null);
            }
          }
          rows.push(row);
        }

        updateExcelData(rows);
      };

      reader.readAsBinaryString(blob);
    } catch (err) {
      console.log(err);
    }
  };

  const fileUpload = (event) => {
    updatePopup(false);
    const file = event.target.files[0];
    const blob = new Blob([file], { type: file.type });
    excelConvert(blob);
  };

  const closeModal = () => {
    updatePopup(false);
  };

  const popUpBtn = () => {
    updatePopup(true);
  };

  const renderPopup = () => (
    <div className={`${styles["modal"]}`}>
      <label className={styles["button-pop"]} htmlFor="upload">
        Choose File
      </label>
      <input
        id="upload"
        onChange={fileUpload}
        type="file"
        accept=".csv,.xlsx,.xls"
        className="w-4/12 mr-20"
      />

      <button
        onClick={downloadTemplateDevice}
        className={`${styles["button-download"]} text-center pb-2`}
      >
        <span className="mr-2">
          <img src="/assets/download.svg" alt="download" />
        </span>
        Download Template
      </button>
    </div>
  );

  const errCls = () => {
    updateErrors([]);
  };

  const downloadTemplateDevice = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const queryParams = `?batchId=${batch}&branch=${branch}`;

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(
        `${apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_EXCEL_TEMPLATE}${queryParams}`,
        options
      );

      if (response.ok) {
        // Convert the received data into a Blob

        const blobData = await response.blob();

        // Create a Blob object

        const blob = new Blob([blobData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const link = document.createElement("a");

        // Set the link's href attribute to the Blob data
        link.href = window.URL.createObjectURL(blob);

        // Set the download attribute with the desired file name
        link.download = "Student Template";

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click event on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
        alert("Template Downloaded Successfully");
      } else {
        alert("Unable To Download");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        Please complete all the campus setup first <br />
        including sections for this branch in this particular batch
      </p>
    </div>
  );

  useEffect(() => {
    getExcelData();
  }, []);

  useEffect(() => {
    try {
      const cells = errors.map((each) => {
        const stringWithArray = each;

        const startIndex = stringWithArray.indexOf("[");
        const endIndex = stringWithArray.lastIndexOf("]");
        const arraySubstring = stringWithArray.substring(
          startIndex,
          endIndex + 1
        );

        return JSON.parse(arraySubstring);
      });

      highlightCells(cells);
    } catch (err) {
      console.log(err);
    }
  }, [errors]);

  return (
    <div className={styles["excel-container"]}>
      {loader ? (
        <div className="w-full flex justify-center mb-10 mt-24">
          <Oval
            height={50}
            width={50}
            color="#3D65F4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#3D65F4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : showExcel ? (
        <>
          <div className={styles["excel-width"]}>
            <HotTable
              ref={hotTableComponent}
              data={excelData}
              cells={(row, col) => {
                const cellProperties = {};

                if (row === 7 || (col < 2 && row < 4)) {
                  cellProperties.className = `${styles["read-only-cell"]}`;
                }

                if (col < 2 && row === 5) {
                  cellProperties.className = `${styles["read-only-cell-custom"]} `;
                }

                if (
                  row < 5 ||
                  (row === 5 && col !== 1) ||
                  (row > 5 && row < 8)
                ) {
                  cellProperties.readOnly = true;
                }

                return cellProperties;
              }}
              minSpareRows={40}
              minCols={10}
              maxCols={9}
              colWidths={130}
              width="100%"
              height="100%"
              className={`${styles["custom-handsontable"]}`}
              licenseKey="non-commercial-and-evaluation"
            />

            {errors.length > 0 && (
              <div className={styles["errors-card"]}>
                <div className={styles["err-count-title"]}>
                  (Found {errors.length} Errors)
                  <button
                    type="button"
                    className="absolute right-2"
                    onClick={errCls}
                  >
                    <RxCross2
                      size={22}
                      className="cursor-pointer text-[#456bf1]"
                    />
                  </button>
                </div>
                <div className={styles["err-container"]}>
                  <ol className={styles["errors-list"]}>{renderErrors()}</ol>
                </div>
              </div>
            )}
          </div>
          <span
            className={`${styles["excel-btns"]} flex flex-row justify-end items-end mt-4 mb-4`}
          >
            <button onClick={goBack} className={styles["back-button"]}>
              Go Back
            </button>

            <button
              onClick={popUpBtn}
              className={`${styles["back-button"]}`}
              type="button"
            >
              <MdOutlineUploadFile className={styles["excel-upload-icon"]} />
              Upload Excel
            </button>

            <Popup open={popUp} onClose={closeModal} {...{ overlayStyle }}>
              {renderPopup()}
            </Popup>
            <button
              disabled={loading}
              onClick={submitBtn}
              className={`${styles["button"]} ${
                loading && "cursor-not-allowed"
              }`}
            >
              {loading ? (
                <Oval
                  height={20}
                  width={85}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Submit"
              )}
            </button>
          </span>
        </>
      ) : (
        renderEmptyView()
      )}
    </div>
  );
};

export default ExcelView;
