import styles from './index.module.css'

const SuccessRateTable = () => (
  <form className="p-2 pr-0 flex flex-col">
    <table className={styles["success-rate-table"]}>
      <thead>
        <tr>
          <th>Item</th>
          <th>LYGm1</th>
          <th>LYGm2</th>
          <th>LYGm3</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            A*=&nbsp;&nbsp (No. of students admitted in the 1st year of <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that batch and
            those actually admitted in the <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2nd year via
            lateral entry, plus the number of <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; students admitted
            through multiple entry (if <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any) and separate
            division if applicable, minus <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the number of
            students who exited through <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; multiple entry (if
            any)).
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>
            B=&nbsp; No. of students who graduated from the program in the
            stipulated course duration
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>Success Rate (SR)= (B/A) * 100</td>
          <td className={styles["input-col"]}>SR_1</td>
          <td className={styles["input-col"]}>SR_2</td>
          <td className={styles["input-col"]}>SR_3</td>
        </tr>

        <tr>
          <td>Average SR of three batches ((SR_1+ SR_2+ SR_3)/3)</td>
          <td className={styles["input-col"]} colSpan={3}>
            <input required />
          </td>
        </tr>
      </tbody>
    </table>

    <button className={styles["submit-btn"]} type="submit">
      Submit
    </button>
  </form>
);

export default SuccessRateTable;