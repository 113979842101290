import styles from "./index.module.css";
import { withRouter } from "react-router-dom";

const MetricsList = (props) => {
  const { item, projectId } = props;
  const {
    metricsId,
    metricNo,
    metricStatement,
    authors,
    approvers,
    startDate,
    endDate,
  } = item;

  const startdate = new Date(startDate);

  const updatedStartDate = `${startdate.getDate()}/${
    startdate.getMonth() + 1
  }/${startdate.getFullYear()}`;

  const enddate = new Date(endDate);

  const updatedEndDate = `${enddate.getDate()}/${
    enddate.getMonth() + 1
  }/${enddate.getFullYear()}`;

  const progressStyle = {
    width: `${60}%`,
  };

  const metricDataRoute = () => {
    const { history } = props;

    history.push(`/connect/dashboard/metrics-data/${projectId}/${metricsId}`);
  };

  return (
    <tr onClick={metricDataRoute} className="cursor-pointer">
      <td>{metricNo}</td>
      <td className={styles["metric-table-desc"]} colSpan={2}>
        {metricStatement}
      </td>
      <td className={styles["metric-table-name"]}>
        <div className={styles["metric-bar-container"]}>
          <div className={styles["metric-bar"]} style={progressStyle}>
            {58}%
          </div>
        </div>
      </td>
      <td className={styles["metric-table-name"]}>{authors}</td>
      <td className={styles["metric-table-name"]}>{approvers}</td>
      <td>{updatedStartDate}</td>
      <td>{updatedEndDate}</td>
    </tr>
  );
};

export default withRouter(MetricsList);
