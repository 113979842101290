import { Component } from "react";
import InitialSetup from "../../../InitialSetup";
import SideBar from "../../../layouts/SideBar";
import ObeNavBar from "../../../layouts/ObeNavBar";
import StudentInfo from "./StudentInfo";
import "./index.css";
import "../../../LoginForm/index.css";
import FiltersContext from "../../../../context/FiltersContext";

class StudentProfile extends Component {
  state = { setUp: false };

  static contextType = FiltersContext;

  componentDidMount() {
    const { filtersBatch } = this.context;
    this.setState({ setUp: filtersBatch.length > 0 });
  }

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          return (
            <div className="flex flex-col min-h-full overflow-x-hidden pb-5">
              <StudentInfo />
            </div>
          );
        case false:
          return <InitialSetup />;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div className="home flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]">
          <SideBar />
          <div className="flex flex-col">
            <ObeNavBar />
            {this.renderView()}
          </div>
        </div>
      </span>
    );
  }
}

export default StudentProfile;
