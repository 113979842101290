import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import styles from "./index.module.css";
import { useState } from "react";
import { useEffect } from "react";

const components = {
  1: "Basic Sciences",
  2: "Basic Engineering",
  3: "Humanities and Social Sciences",
  4: "Program Core",
  5: "Program Electives",
  6: "Open Electives",
  7: "Project(s)",
  8: "Internships/Seminars",
};

const CurriculumComponentsTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;
  const [totalCredits, setTotalCredits] = useState("");

  const submitted = async () => {
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      for (let count = 1; count <= 8; count++) {
        reqBody.push({
          componentId: count,
          curriculumContent: document.getElementById(
            `${count}-curriculumContent`
          ).value,
          totalHours: document.getElementById(`${count}-hours`).value,
          credits: document.getElementById(`${count}-credits`).value,
        });
      }

      const options = {
        method:
          metricInfo["curriculum-components-table"].length === 0
            ? "POST"
            : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderComponents = () => {
    let result = Object.entries(components).map((item, idx) => (
      <tr>
        <td>{item[1]}</td>
        <td>
          <input
            defaultValue={
              metricInfo?.["curriculum-components-table"]?.[idx]?.[
                "curriculum_content"
              ] ?? ""
            }
            type="text"
            id={`${item[0]}-curriculumContent`}
          />
        </td>
        <td>
          <input
            defaultValue={
              metricInfo?.["curriculum-components-table"]?.[idx]?.[
                "total_hours"
              ] ?? ""
            }
            type="text"
            id={`${item[0]}-hours`}
          />
        </td>
        <td>
          <input
            defaultValue={
              metricInfo?.["curriculum-components-table"]?.[idx]?.[
                "total_credits"
              ] ?? ""
            }
            type="text"
            id={`${item[0]}-credits`}
          />
        </td>
      </tr>
    ));

    return result;
  };

  useEffect(() => {
    let sum = 0;
    for (let each of metricInfo["curriculum-components-table"]) {
      if (each["total_credits"] !== "") {
        sum = sum + parseFloat(each["total_credits"]);
      }
    }

    sum > 0 && setTotalCredits(sum);
  }, []);

  return (
    <div className="p-2 pr-0 flex flex-col">
      <table className={styles["component-table"]}>
        <tr>
          <th>Curriculum Component</th>
          <th>
            Curriculum Content
            <br />
            (% of total credits <br /> of the program)
          </th>
          <th>
            Total Number <br />
            of Hours
          </th>
          <th>
            Total Number <br />
            of Credits
          </th>
        </tr>
        <tbody>{renderComponents()}</tbody>

        <tr>
          <td colSpan={3} className={styles["total-credits"]}>
            Total number of Credits:
          </td>
          <td className={`${styles["count-credits"]} text-center pr-2`}>
            {totalCredits}
          </td>
        </tr>
      </table>

      <button
        onClick={submitted}
        className={styles["submit-btn"]}
        type="button"
      >
        Submit
      </button>
    </div>
  );
};

export default CurriculumComponentsTable;
