import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";

const Students = (props) => {
  const [loader, updateLoader] = useState(false);
  const [data, updateData] = useState([]);
  const { batch, branch, courseCode } = props;

  const getStudentsData = async () => {
    updateLoader(true);
    updateData([]);

    if (batch !== "" && branch !== "" && courseCode !== "") {
      const token = Cookies.get("jwt_token");

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let queryParams = `?batchId=${batch}&branch=${branch}&courseId=${courseCode}`;

      try {
        const response = await fetch(
          apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_CO_ATTAINMENTS +
            queryParams,
          options
        );

        if (response.ok) {
          const data = await response.json();

          updateData(data);
        }
      } catch (err) {
        console.log(err);
      }
    }

    updateLoader(false);
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>No student data found</p>
    </div>
  );

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  useEffect(() => {
    getStudentsData();
  }, [batch, branch, courseCode]);

  return (
    <div className={styles["bg-containers"]}>
      <table className={styles["students-table-container"]}>
        <tr>
          <th>Student ID</th>
          <th>Name</th>
          <th>Course</th>
          <th>Contact Info</th>
          <th>Outcomes</th>
          <th>Avg Attainment</th>
        </tr>

        {data.map((item) => (
          <tr className="text-[#606981]">
            <td>{item.roll_num}</td>
            <td>
              {item.first_name} {item.last_name}
            </td>
            <td>{item.branch}</td>
            <td className="relative overflow-y-hidden">
              +91 {item.phone_number}{" "}
              <img
                className="bg-[#E2E9FF] p-2 rounded-2xl absolute right-0 top-2.5"
                src="/assets/contact.svg"
                alt="contact"
              />
            </td>
            <td
              className={`text-${
                item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"
              }`}
            >
              {item.total_cos_attained}/{item.total_cos}
            </td>
            <td
              className={`text-${
                item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"
              }`}
            >
              {item.avg_att * 1}%
            </td>
          </tr>
        ))}
      </table>
      {loader ? renderLoader() : data.length === 0 && renderEmptyView()}
    </div>
  );
};

export default Students;
