import { useEffect, useState } from "react";
import styles from "./index.module.css";
import StatementList from "./StatementList";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const Statement = (props) => {
  const [statements, updateStatements] = useState([]);
  const [newStatement, updateNewStatement] = useState("");
  const [emptyStatement, updateEmptyStatement] = useState(false);
  const { uploadedStatus, metricsId, template, templateNames } = props;

  const renderMissionStatements = () => {
    return statements.map((each) => (
      <StatementList
        key={each.id}
        item={each}
        template={template}
        templateNames={templateNames}
      />
    ));
  };

  const renderEmptyStatement = () => (
    <li className="flex items-center w-full text-[#606981] bg-[#ffffff] font-medium p-0 pl-3 mb-3">
      <span className="flex flex-col w-full">
        <p
          className={`${styles["co-statement-text"]} mb-4 mt-4 flex items-center text-[#8F949F] w-full mr-1`}
        >
          {template === templateNames.missionStatement ? "Mission" : "PEO"}{" "}
          Statement {statements.length + 1}:
        </p>

        <input
          onChange={statementChanged}
          type="text"
          className={`${styles["filter-select-desc"]} bg-[#fff] border-[#8F949F] border-0 border-b w-full p-0 m-0 flex break-words`}
        />
      </span>
    </li>
  );

  const statementChanged = (event) => {
    updateNewStatement(event.target.value);
  };

  const submitStatement = async () => {
    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const statementKey =
      template === templateNames.missionStatement
        ? "missionStatement"
        : "peoStatement";

    const statementNoKey =
      template === templateNames.missionStatement ? "missionNo" : "peoNo";

    const reqBody = {
      [`${statementKey}`]: newStatement,
      [`${statementNoKey}`]: statements.length + 1,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({ data: reqBody }),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const statementBtn = () => {
    updateEmptyStatement(!emptyStatement);
  };

  useEffect(() => {
    const { metricInfo } = props;

    updateStatements(metricInfo);
  }, []);

  useEffect(() => {
    statements.length === 0
      ? updateEmptyStatement(true)
      : updateEmptyStatement(false);
  }, [statements]);

  return (
    <div className="flex flex-col">
      <ul className="list-none">
        {renderMissionStatements()}

        {emptyStatement && renderEmptyStatement()}
      </ul>

      <button
        onClick={emptyStatement ? submitStatement : statementBtn}
        type="button"
        className={styles["mission-statement-btn"]}
      >
        {emptyStatement ? "Submit" : "+"}
      </button>
    </div>
  );
};

export default Statement;
