import React, { useRef, useState, useEffect } from "react";
import styles from "./index.module.css";
import { registerAllModules } from "handsontable/registry";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.min.css";
import { Oval } from "react-loader-spinner";
import { MdOutlineUploadFile } from "react-icons/md";
import Popup from "reactjs-popup";
import { RxCross2 } from "react-icons/rx";

registerAllModules();

const overlayStyle = { background: "rgba(0,0,0,0.5)" };

const ExcelMarkSheet = (props) => {
  const hotTableComponent = useRef(null);
  const [excelData, updateExcelData] = useState([]);
  const [empty, updateEmpty] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [cosData, updateCosData] = useState([]);
  const [popUp, updatePopup] = useState(false);
  const [blobData, updateBlob] = useState(null);
  const [errors, updateErrors] = useState([]);
  const [excelLoading, updateExcelLoading] = useState(false);

  const { excelGoBack, data, examType, sec } = props;

  const cos = cosData.map((each, index) => `CO${index + 1}`);

  const maxMarksCells = [];
  const coCells = [];
  const qNoCells = [];

  const rollNoCells = [];

  for (let i = 1; i < 80; i++) {
    maxMarksCells.push({
      row: 8,
      col: i,
      placeholder: "Max marks",
      placeholderCellClassName: styles["custom-handsontable-placeholder"],
    });

    data["courseHavRub"] === "N"
      ? coCells.push({
          row: 9,
          col: i,
          type: "autocomplete",
          source: ["", ...cos],
          className: styles["custom-cell"],
          placeholder: "Input COs",
          placeholderCellClassName: styles["custom-input-cos-placeholder"],
        })
      : coCells.push({
          row: 9,
          col: i,
          placeholder: "Rubric No",
          placeholderCellClassName: styles["custom-handsontable-placeholder"],
        });

    data["courseHavRub"] === "N" &&
      qNoCells.push({
        row: 10,
        col: i,
        placeholder: "Question No",
        placeholderCellClassName: styles["custom-handsontable-placeholder"],
      });

    rollNoCells.push({
      row: data["courseHavRub"] === "N" ? 11 + i : 10 + i,
      col: 0,
      placeholder: "Roll No",
      placeholderCellClassName: styles["custom-handsontable-placeholder"],
    });
  }

  const preCol = [...maxMarksCells, ...coCells, ...qNoCells, ...rollNoCells];

  const highlightCells = (errCell) => {
    const hot = hotTableComponent.current.hotInstance;

    errCell.forEach(([row = 1, col = 1]) => {
      highlightCellByCoords(hot, row - 1, col - 1);
    });
  };

  const highlightCellByCoords = (hotInstance, row, col) => {
    hotInstance.setCellMeta(
      row,
      col,
      "className",
      `${styles["highlighted-cell"]}`
    );
    hotInstance.render();
  };

  const renderErrors = () => {
    let ans = [];

    try {
      for (let i = 0; i < errors.length; i++) {
        ans.push(
          <li key={i}>
            <span>{i + 1}.</span>
            {errors[i]}
          </li>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const addTemplateManually = async (fileData) => {
    try {
      updateLoading(true);
      updateErrors([]);

      if (sec !== "") {
        const token = Cookies.get("jwt_token");

        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: fileData,
        };

        const tempId = data["courseHavRub"] === "N" ? 1 : 2;

        const response = await fetch(
          apiClient.urls.fatcat.OBE_FACULTY_UPLOAD_TEMPLATE +
            `?courseId=${data["courseId"]}&examType=${examType}&templateId=${tempId}&sectionName=${sec}`,
          options
        );

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["statusCode"] === "OK") {
            updateLoading(false);
            alert("Marks Sheet Uploaded!");
          } else {
            updateLoading(false);

            updateErrors(res["result"] ?? ["Something went wrong!"]);
            // alert(res["description"]);
          }
        } else {
          alert("Marks Sheet Not Uploaded!");
          updateLoading(false);
        }
      } else {
        alert("Select the section");
      }

      updateLoading(false);
    } catch (err) {
      console.log(err);
      alert("Marks Sheet Not Uploaded!");
      updateLoading(false);
    }
  };

  const submitBtn = () => {
    if (hotTableComponent.current) {
      const hotInstance = hotTableComponent.current.hotInstance;
      const data = hotInstance.getData();

      const formattedData = data.map((each) =>
        each.map((item) => (item === "" ? null : item))
      );
      // console.log(data);

      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(formattedData);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate Excel file
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      const blob = new Blob([wbout], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = "HandsontableData.xlsx";

      // a.click();

      const formData = new FormData();
      formData.append("file", blob);

      addTemplateManually(formData);
    }
  };

  const goBack = () => {
    excelGoBack();
  };

  const excelConvert = (blob) => {
    try {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Parse the sheet to preserve empty rows
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        const rows = [];
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const row = [];
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            row.push(sheet[cellRef] ? sheet[cellRef].v : null);
          }
          rows.push(row);
        }

        updateExcelData(rows);
      };

      reader.readAsBinaryString(blob);
    } catch (err) {
      console.log(err);
    }
  };

  const fileUpload = (event) => {
    updatePopup(false);
    const file = event.target.files[0];
    const blob = new Blob([file], { type: file.type });
    excelConvert(blob);
  };

  const downloadTemplate = async () => {
    updateExcelLoading(true);

    try {
      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_FACULTY_COURSES_DOWNLOAD_TEMPLATE +
          `?courseId=${data["courseId"]}&examType=${examType}&sectionName=${sec}`,
        options
      );

      if (response["ok"] === true) {
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          await response.json();
          updateEmpty(true);
        } else {
          const blob = await response.blob();

          excelConvert(blob);
          updateBlob(blob);
          updateEmpty(false);
        }
      }
    } catch (err) {
      console.log(err);
    }

    updateExcelLoading(false);
  };

  const downloadTemplateDevice = () => {
    if (blobData !== null) {
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${data["courseShortName"]}-${examType}.xlsx`;
      a.click();
    } else {
      alert("Unable to download");
    }
  };

  const getDescCo = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${id}`,
        { Authorization: token }
      );

      // console.log(response);

      if (responseDesc["data"]["statusCode"] === "OK") {
        updateCosData(responseDesc["data"]["result"]);
      } else {
        updateCosData([]);
      }
    } catch (err) {
      console.log(err);
      updateCosData([]);
    }
  };

  const closeModal = () => {
    updatePopup(false);
  };

  const popUpBtn = () => {
    updatePopup(true);
  };

  const renderPopup = () => (
    <div className={`${styles["modal"]}`}>
      <label className={styles["button-pop"]} htmlFor="upload">
        Choose File
      </label>
      <input
        id="upload"
        onChange={fileUpload}
        type="file"
        accept=".csv,.xlsx,.xls"
        className="w-4/12 mr-20"
      />

      <button
        onClick={downloadTemplateDevice}
        className={`${styles["button-download"]} text-center pb-2`}
      >
        <span className="mr-2">
          <img src="/assets/download.svg" alt="download" />
        </span>
        Download Template
      </button>
    </div>
  );

  const errCls = () => {
    updateErrors([]);
  };

  useEffect(() => {
    try {
      const cells = errors.map((each) => {
        const stringWithArray = each;

        const startIndex = stringWithArray.indexOf("[");
        const endIndex = stringWithArray.lastIndexOf("]");
        const arraySubstring = stringWithArray.substring(
          startIndex,
          endIndex + 1
        );

        return JSON.parse(arraySubstring);
      });

      highlightCells(cells);
    } catch (err) {
      console.log(err);
    }
  }, [errors]);

  useEffect(() => {
    downloadTemplate();
    getDescCo(data?.["courseId"]);
  }, []);

  return (
    <div className={styles["excel-container"]}>
      {excelLoading ? (
        <div className="flex justify-center absolute left-2/4 mt-24">
          <Oval
            height={60}
            width={60}
            color="#3D65F4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#3D65F4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : empty ? (
        <div className="flex justify-center items-center p-10 mt-5 mb-5">
          <p className={styles["fill-matrix"]}>
            Please enter articulation Matrix first
          </p>
        </div>
      ) : (
        <div className={styles["excel-width"]}>
          <HotTable
            ref={hotTableComponent}
            data={excelData}
            cell={preCol}
            cells={(row, col) => {
              const cellProperties = {};
              if ((row === 8 || row >= 11) && col > 0) {
                cellProperties.type = "numeric";
              }
              if (row < 8) {
                cellProperties.readOnly = true;
              }

              if (row >= 8 && row <= 10 && col === 0) {
                cellProperties.readOnly = true;
              }

              if (row === 10 && data["courseHavRub"] === "Y") {
                cellProperties.readOnly = true;
              }

              if (row === 11 && data["courseHavRub"] === "N") {
                cellProperties.readOnly = true;
              }

              if (cellProperties.readOnly) {
                cellProperties.className = `${styles["readonly-cell"]}`;
              }

              if (row === 7 && col > 0) {
                cellProperties.className = `${styles["max-marks-cells"]}`;
              }

              if (
                row > 7 &&
                row < 11 &&
                col === 0 &&
                data["courseHavRub"] === "N"
              ) {
                cellProperties.className = `${styles["readonly-cell-cols"]}`;
              }

              if (
                row > 7 &&
                row < 10 &&
                col === 0 &&
                data["courseHavRub"] === "Y"
              ) {
                cellProperties.className = `${styles["readonly-cell-cols"]}`;
              }

              if (row === 12 && data["courseHavRub"] === "N") {
                cellProperties.className = `${styles["roll-cells"]}`;
              }

              if (row === 11 && data["courseHavRub"] === "Y") {
                cellProperties.className = `${styles["roll-cells"]}`;
              }

              return cellProperties;
            }}
            minSpareRows={50}
            minSpareCols={26}
            maxCols={80}
            colWidths={(index) => (index === 0 ? 150 : 100)}
            fixedRowsTop={data?.["courseHavRub"] === "N" ? 12 : 11}
            fixedColumnsStart={1}
            allowInsertColumn
            allowInsertRow
            allowRemoveColumn
            allowRemoveRow
            contextMenu
            width="100%"
            height="100%"
            className={`${styles["custom-handsontable"]} htCenter htMiddle`}
            licenseKey="non-commercial-and-evaluation"
          />

          {errors.length > 0 && (
            <div className={styles["errors-card"]}>
              <div className={styles["err-count-title"]}>
                (Found {errors.length} Errors)
                <button
                  type="button"
                  className="absolute right-2"
                  onClick={errCls}
                >
                  <RxCross2
                    size={22}
                    className="cursor-pointer text-[#456bf1]"
                  />
                </button>
              </div>
              <div className={styles["err-container"]}>
                <ol className={styles["errors-list"]}>{renderErrors()}</ol>
              </div>
            </div>
          )}
        </div>
      )}
      <span
        className={`${styles["excel-btns"]} flex flex-row justify-end items-end mb-2 mt-2`}
      >
        <button onClick={goBack} className={styles["back-button"]}>
          Go Back
        </button>

        {!empty && (
          <>
            <button
              onClick={popUpBtn}
              className={`${styles["back-button"]}`}
              type="button"
            >
              <MdOutlineUploadFile className={styles["excel-upload-icon"]} />
              Upload Excel
            </button>

            <Popup open={popUp} onClose={closeModal} {...{ overlayStyle }}>
              {renderPopup()}
            </Popup>
            <button
              disabled={loading}
              onClick={submitBtn}
              className={`${styles["button"]} ${
                loading && "cursor-not-allowed"
              }`}
            >
              {loading ? (
                <Oval
                  height={20}
                  width={85}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Submit"
              )}
            </button>
          </>
        )}
      </span>
    </div>
  );
};

export default ExcelMarkSheet;
