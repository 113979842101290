import "./index.css";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

const POGraph = (props) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "PO Analytics Will Appear Here After an Year Complete",
      verticalAlign: "middle",
      style: {
        color: "#9FB2F6",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "900",
      },
    },

    xAxis: {
      categories: [
        "PO1",
        "PO2",
        "PO3",
        "PO4",
        "PO5",
        "PO6",
        "PO7",
        "PO8",
        "PO9",
        "PO10",
        "PO11",
        "PO12",
      ],
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        formatter: function () {
          return this.value + "%";
        },
      },
    },
    tooltip: {
      valueSuffix: "%",
    },
    accessibility: {
      enabled: false,
    },

    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        // pointPadding: 0,
        // borderRadius: 3,
      },
    },

    legend: {
      y: 20,
      enabled: false,
      align: "right",
      verticalAlign: "bottom",
      layout: "horizontal",
      symbolRadius: 3,
      itemStyle: {
        color: "#8F949F",
        fontWeight: "normal",
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        data: [19, 32, 43, 21, 61, 31, 10, 23, 53, 28, 40, 10],
        type: "column",
        name: "2016-20",
        // color: '#9FB2F6',
        color: "transparent",
      },
      {
        data: [10, 23, 53, 28, 40, 10, 10, 23, 53, 28, 40, 10],
        type: "column",
        name: "2017-21",
        // color: '#B6DAF9',
        color: "transparent",
      },
      {
        data: [11, 22, 43, 62, 13, 21, 10, 23, 53, 28, 40, 10],
        type: "column",
        name: "2018-22",
        // color: '#D9E0FF',
        color: "transparent",
      },
      {
        data: [10, 23, 53, 28, 40, 10, 10, 23, 53, 28, 40, 10],
        type: "column",
        name: "2019-23",
        // color: '#B9B8FF',
        color: "transparent",
      },
      {
        data: [10, 23, 53, 28, 40, 10, 10, 23, 53, 28, 40, 10],
        type: "column",
        name: "2020-24",
        // color: '#EA9FD8',
        color: "transparent",
      },
    ],
  };

  return (
    <div className="w-full mt-10 bg-[#ffffff] mb-4 pr-4 pb-2">
      <span className="flex flex-row justify-between mb-10 items-center">
        <h1 className="periodwisebar-title">PO - BREAKDOWN</h1>
      </span>

      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </div>
  );
};

export default POGraph;
