import styles from './index.module.css'

const PlacementEntrepreneurshipTable = () => (
  <form className="p-2 pr-0 flex flex-col">
    <table className={styles["place-enter-table"]}>
      <thead>
        <tr>
          <th>Item</th>
          <th>CAYm1</th>
          <th>CAYm2</th>
          <th>CAYm3</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            FS*= Total no. of final year students
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>X= No. of students placed</td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>Y= No. of students admitted to higher studies</td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>Z= No. of students taking up entrepreneurship</td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>X + Y + Z =</td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
          <td className={styles["input-col"]}>
            <input required />
          </td>
        </tr>

        <tr>
          <td>Placement Index (P) = (((X + Y + Z)/FS) * 100)</td>
          <td className={styles["input-col"]}>AP1</td>
          <td className={styles["input-col"]}>AP2</td>
          <td className={styles["input-col"]}>AP3</td>
        </tr>

        <tr>
          <td>Average placement index = (P_1 + P_2 + P_3)/3</td>
          <td className={styles["input-col"]} colSpan={3}>
            <input required />
          </td>
        </tr>
      </tbody>
    </table>

    <button
    className={styles['submit-btn']} type='submit' 
>Submit</button>

  </form>
);

export default PlacementEntrepreneurshipTable;