import "./index.css";
import POGraph from "../POGraph";
import { RxCross2 } from "react-icons/rx";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as jose from "jose";
import FiltersContext from "../../../../context/FiltersContext";
import { Oval } from "react-loader-spinner";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

const overlayScreen = {
  attainmentData: "Attainment Data",
  mappingData: "Mapping Data",
};

const PocoGraph = (props) => {
  const options = {
    chart: {
      type: "line",
      width: 580,
      height: 245,
    },
    title: {
      text: "PO Trends Will Appear Here Soon",
      verticalAlign: "middle",
      style: {
        color: "#9FB2F6",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "900",
      },
    },

    xAxis: {
      min: 0,
      categories: ["2016", "2017", "2018", "2019", "2020"],
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        formatter: function () {
          return this.value + "%";
        },
      },
    },
    accessibility: {
      enabled: false,
    },

    tooltip: {
      valueSuffix: "%",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        // pointWidth: 25,
        // pointPadding: 0,
        // borderRadius: 3,
        // groupPadding: 0.5,
      },
    },

    legend: {
      y: 100,
      x: 0,
      enabled: false,
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      itemStyle: {
        color: "#8F949F",
        fontWeight: "normal",
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        data: [57, 23, 80, 45, 34],
        type: "line",
        name: `CO's`,
        // color: '#FFAA5A',
        color: "transparent",
        dashStyle: "Solid",
        marker: {
          enabled: false,
        },
      },

      {
        data: [66, 19, 30, 48, 77],
        type: "line",
        name: "POs",
        // color: '#3579D6',
        color: "transparent",

        dashStyle: "ShortDash",
        marker: {
          enabled: false,
        },
      },
      {
        data: [19, 20, 30, 34, 78],
        type: "line",
        name: "POs(IDA)",
        // color: '#F87787',
        color: "transparent",

        dashStyle: "Solid",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  const { filtersBatch, filtersBranch } = useContext(FiltersContext);

  const [poDaData, updatePoDaData] = useState([]);
  const [poData, updatePoData] = useState([]);
  const [artData, updateArtData] = useState([]);
  const [branch, updateBranch] = useState("");
  const [poBranch, updatePoBranch] = useState("");
  const [poBatch, updatePoBatch] = useState("");
  const [artBranch, updateArtBranch] = useState("");
  const [artBatch, updateArtBatch] = useState("");
  const [poYear, updatePoYear] = useState("");
  const [dropBranchOptions] = useState(filtersBranch);
  const [poDropBatchOptions] = useState(filtersBatch);
  const [poDropYearOptions, UpdatePoYearOptions] = useState([]);
  const [userType, updateType] = useState("");
  const [overlay, updateOverlay] = useState(false);
  const [screen, updateScreen] = useState(overlayScreen.attainmentData);
  const [overlayLoader, updateOverlayLoader] = useState(false);

  const getPoYearOptions = async () => {
    const token = Cookies.get("jwt_token");

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=AY&screenName=OBE_PO_AY`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdatePoYearOptions(response["data"]["result"]);
        if (response["data"]["result"].length !== 0) {
          updatePoYear(response["data"]["result"][0]["optionValue"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoDaDetails = async () => {
    try {
      const token = Cookies.get("jwt_token");

      if (poBatch !== "" && poBranch !== "" && poYear !== "") {
        updatePoDaData([]);
        updateOverlayLoader(true);

        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_DASHBOARD_PO_DA_ATTAINMENT_AY +
            `?batch=${poBatch}&branch=${poBranch}&academicYear=${poYear}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updatePoDaData(response["data"]["result"]);
          updateOverlayLoader(false);
        } else {
          updatePoDaData([]);
          updateOverlayLoader(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoDetails = async () => {
    try {
      const token = Cookies.get("jwt_token");

      if (poBatch !== "" && poBranch !== "") {
        updatePoData([]);

        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_DASHBOARD_PO_ATTAINMENT_DETAILS +
            `?batch=${poBatch}&branch=${poBranch}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updatePoData(response["data"]["result"]);
        } else {
          updatePoData([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPoArtDetails = async () => {
    try {
      const token = Cookies.get("jwt_token");

      if (artBatch !== "" && artBranch !== "") {
        updateArtData([]);
        updateOverlayLoader(true);

        const response = await apiClient.get(
          apiClient.urls.fatcat.DASHBOARD_PROGRAM_ART_MATRIX +
            `?batchId=${artBatch}&branch=${artBranch}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updateArtData(response["data"]["result"]);
          updateOverlayLoader(false);
        } else {
          updateArtData([]);
          updateOverlayLoader("No_Content");
        }
      }
    } catch (err) {
      console.log(err);
      updateOverlayLoader("No_Content");
    }
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoBatchOptions = () => {
    const ans = [];
    try {
      for (const item of poDropBatchOptions) {
        ans.push(
          <option key={item["optionDesc"]} value={item["optionDesc"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderArtBatchOptions = () => {
    const ans = [];
    try {
      for (const item of poDropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoYearOptions = () => {
    const ans = [];
    try {
      for (const item of poDropYearOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const branchChanged = (event) => {
    updateBranch(event.target.value);
  };

  const poBranchChanged = (event) => {
    updatePoBranch(event.target.value);
  };

  const poBatchChanged = (event) => {
    updatePoBatch(event.target.value);
  };

  const artBranchChanged = (event) => {
    updateArtBranch(event.target.value);
  };

  const artBatchChanged = (event) => {
    updateArtBatch(event.target.value);
  };

  const poYearChanged = (event) => {
    updatePoYear(event.target.value);
  };

  const closeBtn = () => {
    updateOverlay(false);
  };

  const openBtn = () => {
    updateOverlay(true);
  };

  const renderOverAllPoTable = () => {
    try {
      let ans = [];

      let count = 0;

      for (let each of poData) {
        count++;
        ans.push(
          <tr className="border border-blue-500 text-[#040C58]">
            <td className="table-td border border-blue-500 p-2 text-[#606981]">
              {count}
            </td>
            <th className="table-th border border-blue-500 p-2">
              {each["po"]}
            </th>
            <td className="table-td border border-blue-500 p-2 text-[#606981]">
              {each["poDaPct"]}
            </td>
            <td className="table-td border border-blue-500 p-2 text-[#606981]">
              {each["poIdaPct"]}
            </td>
            <th className="table-td border border-blue-500 p-2">
              {each["poAttPct"]}
            </th>
            <td className="table-td border border-blue-500 p-2 text-[#606981]">
              {each["poTargetAtt"]}
            </td>
            <th
              className={`${
                each["isPoAttained"] === "No"
                  ? "text-red-500"
                  : "text-green-500"
              } table-td border border-blue-500 p-2 text-base font-normal`}
            >
              {each["isPoAttained"]}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoPso = () => {
    try {
      let ans = [];
      let pos = poDaData?.[`PO AT IN % FOR ${poYear.toUpperCase()}`] ?? {};
      let posCount = Object.keys(pos);

      for (let each of posCount) {
        ans.push(
          <th className="table-th border border-blue-500 p-2">{each}</th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPoDaTable = () => {
    try {
      let ans = [];

      let count = 0;

      for (let each in poDaData) {
        if (!each.startsWith("PO AT IN % FOR")) {
          count += 1;

          let posAns = [];

          for (let item in poDaData[each]["poPctDetails"]) {
            posAns.push(
              <td className="table-td border border-blue-500 p-2">
                {poDaData[each]["poPctDetails"][item]}
              </td>
            );
          }

          ans.push(
            <tr className="border border-blue-500 text-[#606981] bg-[#FFFFFF] p-0">
              <td className="table-td border border-blue-500 p-2">{count}</td>
              <th className="table-th border border-blue-500 p-2 text-[#040C58]">
                {poDaData[each]["courseShortName"]}
              </th>
              <td className="table-td border border-blue-500 p-2">
                {poDaData[each]["courseCode"]}
              </td>
              {posAns}
            </tr>
          );
        } else {
          let posAns = [];

          for (let item in poDaData[each]) {
            posAns.push(
              <th className="table-th border border-blue-500 p-2">
                {poDaData[each][item]}
              </th>
            );
          }

          ans.push(
            <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
              <th className="table-th border border-blue-500 p-2" colSpan={3}>
                {each}
              </th>
              {posAns}
            </tr>
          );
        }
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderArtPoPso = () => {
    try {
      let ans = [];
      const yearKeys = Object.keys(artData);

      let pos = artData?.[yearKeys?.[0]]?.["totalCoursesMappedForAY"];
      let posCount = Object.keys(pos);

      for (let each of posCount) {
        ans.push(
          <th className="table-th border border-blue-500 p-2">{each}</th>
        );
      }

      return ans;
    } catch (err) {
      console.log();
    }
  };

  const renderPoArtTable = () => {
    try {
      let ans = [];
      const yearKeys = Object.keys(artData).sort();

      let count = 0;

      for (let year of yearKeys) {
        const courseKeys = Object.keys(artData[year]);
        for (let course of courseKeys) {
          if (
            !course.startsWith("totalCoursesMappedForAY") &&
            !course.startsWith("mappingAvgForAY")
          ) {
            count += 1;

            let posAns = [];

            for (let item of Object.entries(
              artData?.[year]?.[course]?.["poArticulationMatrixForCourse"]
            )) {
              posAns.push(
                <td className="table-td border border-blue-500 p-2">
                  {item[1] === "0.00" ? "-" : item[1]}
                </td>
              );
            }

            ans.push(
              <tr className="border border-blue-500 text-[#606981] bg-[#FFFFFF] p-0">
                <td className="table-td border border-blue-500 p-2">{count}</td>
                <th className="table-th border border-blue-500 p-2 text-[#040C58]">
                  {artData[year][course]["courseShortName"]}
                </th>
                <td className="table-td border border-blue-500 p-2">
                  {artData[year][course]["courseCode"]}
                </td>
                {posAns}
              </tr>
            );
          }
        }

        let totalCoursesMapped = [];

        for (let item of Object.entries(
          artData?.[year]?.["totalCoursesMappedForAY"]
        )) {
          totalCoursesMapped.push(
            <th className="table-th border border-blue-500 p-2">{item[1]}</th>
          );
        }

        ans.push(
          <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
            <th className="table-th border border-blue-500 p-2" colSpan={3}>
              Total Courses Mapped ({year})
            </th>
            {totalCoursesMapped}
          </tr>
        );

        let mappingAvg = [];

        for (let item of Object.entries(artData?.[year]?.["mappingAvgForAY"])) {
          mappingAvg.push(
            <th className="table-th border border-blue-500 p-2">{item[1]}</th>
          );
        }

        ans.push(
          <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
            <th className="table-th border border-blue-500 p-2" colSpan={3}>
              Mapping Average ({year})
            </th>
            {mappingAvg}
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const screenChange = (event) => {
    updateScreen(event.target.name);
  };

  const renderAttainmentScreen = () => (
    <>
      <div className="flex flex-row justify-end items-center font-medium mt-5 w-full">
        <div className="graph-filters">
          <select
            value={poBatch}
            onChange={poBatchChanged}
            className={`filter-select-dash focus:ring-0 mr-2 p-2 pr-10`}
          >
            {renderPoBatchOptions()}
          </select>
          {userType !== "HOD" && (
            <select
              value={poBranch}
              onChange={poBranchChanged}
              className={`filter-select-dash focus:ring-0 mr-2 p-2 pr-10`}
            >
              {renderBranchOptions()}
            </select>
          )}

          <select
            value={poYear}
            onChange={poYearChanged}
            className={`filter-select-dash focus:ring-0 mr-2 p-2 pr-10`}
          >
            {renderPoYearOptions()}
          </select>

          {/* <select className={`filter-select focus:ring-0 mr-2 p-2 pr-10`}>
            <option>Percentage</option>
          </select> */}
        </div>
      </div>
      {overlayLoader === false ? (
        <>
          <h1 className="periodwisebar-title text-center mt-5 mb-5">
            COMPLETE PO ATTAINMENTS THROUGH DIRECT ASSESSMENT
          </h1>

          <div className="table pl-2 pr-2">
            <div
              id="dashboardPo"
              className={`table-scroll border border-r-0 border-l-0 border-b-0  m-0`}
            >
              <table className="text-center border border-blue-500">
                <tbody>
                  <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
                    <th className="table-th border border-blue-500 p-2">
                      S.No
                    </th>
                    <th className="table-th border border-blue-500 p-2">
                      Course Short Name
                    </th>
                    <th className="table-th border border-blue-500 p-2">
                      Course Code
                    </th>
                    {renderPoPso()}
                  </tr>

                  {renderPoDaTable()}
                </tbody>
              </table>
            </div>
          </div>
          <span className="flex justify-end self-end mt-3 pb-5">
            <span
              onClick={poscrollTbClickedLeft}
              className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={poscrollTbClicked}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>

          <h1 className="periodwisebar-title text-center mt-8">
            Overall Attainments Of POs And PSOs
          </h1>

          <div className="flex flex-col items-center mt-4 w-2/3 mb-5 overflow-x-auto">
            <table className="text-center border border-blue-500 text-sm">
              <tbody>
                <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
                  <th className="table-th border border-blue-500 p-2">S.No</th>
                  <th className="table-th border border-blue-500 p-2">PO</th>
                  <th className="table-th border border-blue-500 p-2">
                    PO Attainment Through DA
                  </th>
                  <th className="table-th border border-blue-500 p-2">
                    PO Attainment Through IDA
                  </th>

                  <th className="table-th border border-blue-500 p-2">
                    SUMMATION OF 90% OF DA & 10% OF IDA
                  </th>
                  <th className="table-th border border-blue-500 p-2">
                    Target
                  </th>

                  <th className="table-th border border-blue-500 p-2">
                    Target Attained
                    <br />
                    (YES/NO)
                  </th>
                </tr>

                {renderOverAllPoTable()}
              </tbody>
            </table>
          </div>
        </>
      ) : overlayLoader === true ? (
        renderLoader()
      ) : (
        renderEmptyView()
      )}
    </>
  );

  const renderMappingScreen = () => (
    <>
      <div className="flex flex-row justify-end items-center font-medium mt-5 w-full">
        <div className="graph-filters">
          <select
            value={artBatch}
            onChange={artBatchChanged}
            className={`filter-select-dash focus:ring-0 mr-2 p-2 pr-10`}
          >
            {renderArtBatchOptions()}
          </select>
          {userType !== "HOD" && (
            <select
              value={artBranch}
              onChange={artBranchChanged}
              className={`filter-select-dash focus:ring-0 mr-2 p-2 pr-10`}
            >
              {renderBranchOptions()}
            </select>
          )}
        </div>
      </div>
      {overlayLoader === false ? (
        <>
          <h1 className="periodwisebar-title text-center mt-5 mb-5">
            Program Articulation Matrix
          </h1>

          <div className="table pl-2 pr-2">
            <div
              id="dashboardArticulation"
              className={`table-scroll border border-r-0 border-l-0 border-b-0  m-0`}
            >
              <table className="text-center border border-blue-500">
                <tbody>
                  <tr className="border border-blue-500 text-[#040C58] bg-[#EFF3FF] p-0">
                    <th className="table-th border border-blue-500 p-2">
                      S.No
                    </th>
                    <th className="table-th border border-blue-500 p-2">
                      Course Short Name
                    </th>
                    <th className="table-th border border-blue-500 p-2">
                      Course Code
                    </th>
                    {renderArtPoPso()}
                  </tr>

                  {renderPoArtTable()}
                </tbody>
              </table>
            </div>
          </div>
          <span className="flex justify-end self-end mt-3 pb-5">
            <span
              onClick={artscrollTbClickedLeft}
              className={`floatarr self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={artscrollTbClicked}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>
        </>
      ) : overlayLoader === true ? (
        renderLoader()
      ) : (
        renderEmptyView()
      )}
    </>
  );

  const renderLoader = () => (
    <div className="mt-52 h-screen">
      <Oval
        height={50}
        width={85}
        color="#3d65f4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3d65f4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className="empty-view">No Data Found For The Selected Filters.</p>
    </div>
  );

  const renderView = () => (
    <div className="flex flex-col items-center text-[#636363] bg-[#FFFFFF] shadow mb-5 p-2">
      <div className="flex flex-row justify-between items-center w-full mb-2 mt-4 pl-5 border-b-2 border-[#EFEFEF] w-full pb-0.5">
        <span className="flex flex-row items-center">
          <button
            name={overlayScreen.attainmentData}
            onClick={screenChange}
            type="button"
            className={`hover:text-[#3f4451] ${
              screen === overlayScreen.attainmentData
                ? "overlay-tab-active"
                : "overlay-tab"
            } mr-12`}
          >
            {overlayScreen.attainmentData}
          </button>
          <button
            name={overlayScreen.mappingData}
            onClick={screenChange}
            type="button"
            className={`hover:text-[#3f4451] ${
              screen === overlayScreen.mappingData
                ? "overlay-tab-active"
                : "overlay-tab"
            }`}
          >
            {overlayScreen.mappingData}
          </button>
        </span>

        <RxCross2
          onClick={closeBtn}
          size={26}
          className="cursor-pointer self-end text-[#456bf1] mb-1"
        />
      </div>

      {screen === overlayScreen.attainmentData
        ? renderAttainmentScreen()
        : renderMappingScreen()}
    </div>
  );

  const poscrollTbClickedLeft = () => {
    const container = document.getElementById("dashboardPo");
    sideScroll(container, "left", 25, 100, 400);
  };

  const poscrollTbClicked = () => {
    const container = document.getElementById("dashboardPo");
    sideScroll(container, "right", 25, 100, 400);
  };

  const artscrollTbClickedLeft = () => {
    const container = document.getElementById("dashboardArticulation");
    sideScroll(container, "left", 25, 100, 400);
  };

  const artscrollTbClicked = () => {
    const container = document.getElementById("dashboardArticulation");
    sideScroll(container, "right", 25, 100, 400);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");

      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        if (claim["user_type"] === "HOD") {
          updateBranch(claim["dept_name"]);
          updatePoBranch(claim["dept_name"]);
          updateArtBranch(claim["dept_name"]);
          updateType("HOD");
        }

        updatePoBatch(filtersBatch[0]["optionDesc"]);
        updateArtBatch(filtersBatch[0]["optionValue"]);
        getPoYearOptions();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        overlay && screen === overlayScreen.attainmentData && getPoDaDetails();
      } catch (err) {
        console.log(err);
      }
    }
  }, [poBatch, poBranch, poYear, overlay, screen]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        overlay && screen === overlayScreen.attainmentData && getPoDetails();
      } catch (err) {
        console.log(err);
      }
    }
  }, [poBatch, poBranch, overlay, screen]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        overlay && screen === overlayScreen.mappingData && getPoArtDetails();
      } catch (err) {
        console.log(err);
      }
    }
  }, [artBatch, artBranch, overlay, screen]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        if (userType !== "HOD") {
          if (filtersBranch.length !== 0) {
            updateBranch(filtersBranch[0]["optionValue"]);
            updatePoBranch(filtersBranch[0]["optionValue"]);
            updateArtBranch(filtersBranch[0]["optionValue"]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [filtersBranch]);

  return (
    <div className="dashboard-periodwisebar-bg">
      <div onClick={closeBtn} className={overlay ? "overlay" : "hidden"}>
        <div onClick={preventBubbling} className="overlay-card">
          {renderView()}
        </div>
      </div>
      <div className="mb-10 container">
        <h1 className="periodwisebar-title">
          5 YEAR PO-CO ATTAINMENTS COMPARISON
        </h1>

        <div className="graph-filters">
          {userType !== "HOD" && (
            <select
              onChange={branchChanged}
              value={branch}
              className={`filter-select-dash focus:ring-0 mr-5 p-2 pr-10`}
            >
              {renderBranchOptions()}
            </select>
          )}

          <button
            onClick={openBtn}
            type="button"
            className={`see-data-btn focus:ring-0 mr-3 p-2`}
          >
            See Data
          </button>

          <button
            type="button"
            className="info-btns cursor-not-allowed flex items-center"
          >
            <span className="mr-2 mt-1">
              <img
                src="/assets/download.svg"
                alt="download"
                width={18}
                height={18}
              />
            </span>
            Download report
          </button>
        </div>
      </div>

      <div className="barchart-container">
        <div className="line-chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            {...props}
          />
        </div>

        <div className="bar-chart-details">
          <table className="text-center border border-blue-500 w-full">
            <tbody>
              <tr className="table-th border border-blue-500 text-[#040C58] bg-[#EFF3FF]">
                <th className="border border-blue-500 p-2"></th>
                <th className="border border-blue-500 p-2">POs</th>
                <th className="border border-blue-500 p-2">COs</th>
                <th className="border border-blue-500 p-2">Percentage</th>
                <th className="border border-blue-500" colSpan={4}>
                  Non-Attained <br />
                  <span className="flex w-full">
                    <div className="border border-r-0 border-b-0 border-l-0 border-blue-500 w-full">
                      (PO)
                    </div>
                    <div className="border border-blue-500 border-b-0 border-r-0 w-full pr-0.5">
                      (CO)
                    </div>
                  </span>
                </th>
              </tr>
              <tr className="border border-blue-500 text-[#040C58]">
                <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                  20XX-20XX
                </th>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  {/* 24/34 */}
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  {/* 124/134 */}
                </td>

                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  {/* 98% */}
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  {/* (24) */}
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  {/* (24) */}
                </td>
              </tr>

              {/* <tr className="border border-blue-500 text-[#040C58]">
                <th className="border border-blue-500 p-2  bg-[#EFF3FF]">
                  2017-21
                </th>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  24/34
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  124/134
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  98%
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  (24)
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  (24)
                </td>
              </tr>
              <tr className="border border-blue-500 text-[#040C58]">
                <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                  2018-22
                </th>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  24/34
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  124/134
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  98%
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  (24)
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  (24)
                </td>
              </tr>
              <tr className="border border-blue-500 text-[#040C58]">
                <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                  2019-23
                </th>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  24/34
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  124/134
                </td>
                <td className="table-td border border-blue-500 p-2 text-[#606981]">
                  98%
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  (24)
                </td>
                <td
                  colSpan={2}
                  className="table-td border border-blue-500 p-2 text-[#606981]"
                >
                  (24)
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>

      <POGraph />
    </div>
  );
};

export default PocoGraph;
