import { Component } from "react";
import InitialSetup from "../../InitialSetup";
import ManageCourses from "./ManageCourses";
import SideBar from "../../layouts/SideBar";
import ObeNavBar from "../../layouts/ObeNavBar";
import "./index.css";
import "../../LoginForm/index.css";
import Attainments from "./Attainments";
import FiltersContext from "../../../context/FiltersContext";

class CoursesPage extends Component {
  state = { setUp: false };
  static contextType = FiltersContext;

  componentDidMount() {
    const { filtersBatch } = this.context;
    this.setState({ setUp: filtersBatch.length > 0 });
  }

  renderView = () => {
    const { setUp } = this.state;
    try {
      switch (setUp) {
        case true:
          const { match } = this.props;
          const { path } = match;
          return path.includes("/obe/courses/managecourses") ? (
            <div className="flex flex-col overflow-hidden">
              <ManageCourses />
            </div>
          ) : (
            <div className="flex flex-col overflow-hidden">
              <Attainments />
            </div>
          );
        case false:
          return <InitialSetup />;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <span className="flex flex-row justify-center h-screen overflow-hidden">
        <div className="home flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]">
          <SideBar />
          <div className="flex flex-col">
            <ObeNavBar />
            {this.renderView()}
          </div>
        </div>
      </span>
    );
  }
}

export default CoursesPage;
