import { Component } from "react";
import styles from "./index.module.css";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import ExcelView from "./ExcelView";
import FiltersContext from "../../../../../context/FiltersContext";
import roles from "../../../../roles";

class CreateCourse extends Component {
  static contextType = FiltersContext;

  state = {
    excelView: false,
    optionsSec: [],
    created: false,
    data: {
      sectionsForCourse: "",
      batch: "",
      branch: "",
      courseCode: "",
      courseName: "",
      courseShortName: "",
      courseStructureId: "",
      regulation: "",
      semester: "",
      thValue: 50,
      targetAtt: 65,
    },
    courseStructure: [],
    branchOptions: [],
    semOptions: [],
    sectionOptions: [],
    batchOptions: [],
    regOptions: [],
    userType: {},
    isLoading: false,
  };

  excelEntryBtn = () => {
    const { excelView } = this.state;
    this.setState({ excelView: !excelView });
  };

  componentDidMount() {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      const claim = jose.decodeJwt(token);
      const { item } = this.props;
      if (item !== null) {
        let a = {
          batch: item["batchId"],
          branch: item["branch"],
          courseCode: item["courseCode"],
          courseId: item["courseId"],
          courseName: item["courseName"],
          courseShortName: item["courseShortName"],
          courseStructureId: item["courseStructId"],
          regulation: item["regulationId"],
          sectionsForCourse: item["sectionsForCourse"],
          semester: item["semester"],
          targetAtt: item["coTargetAtt"],
        };

        this.setState(
          {
            data: a,
            optionsSec: item["sectionsForCourse"]
              .split(",")
              .map((each) => each.trim()),
          },
          this.getSectionOptions
        );
      }

      this.setState({ userType: claim });
      this.getOptions();
      this.getStructures();
    }
  }

  getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    const { batchOptions, data } = this.state;

    try {
      const { item } = this.props;
      let batch;
      if (item === null) {
        batch = batchOptions.filter((each) => each.optionValue === data.batch);
      }

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${data.branch}&batch=${
            item === null ? batch[0]["optionDesc"] : item["batch"]
          }`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          this.setState({
            sectionOptions: response["data"]["result"],
          });
        } else {
          this.setState({
            sectionOptions: [],
          });
        }
      } else {
        this.setState({
          sectionOptions: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          regOptions: response["data"]["result"],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getOptions = async () => {
    try {
      const { filtersBatch, filtersBranch, filtersSem } = this.context;

      this.setState({
        branchOptions: filtersBranch,
        batchOptions: filtersBatch,
        semOptions: filtersSem,
      });

      await this.getRegOptions();
    } catch (err) {
      console.log(err);
    }
  };

  renderBatchOptions = () => {
    const { batchOptions } = this.state;

    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { semOptions, userType } = this.state;

    const ans = [];
    try {
      if (userType["user_type"] === roles.freshMan) {
        ans.push(
          <option
            key={semOptions[0]["optionValue"]}
            value={semOptions[0]["optionValue"]}
          >
            {semOptions[0]["optionDesc"]}
          </option>
        );
        ans.push(
          <option
            key={semOptions[1]["optionValue"]}
            value={semOptions[1]["optionValue"]}
          >
            {semOptions[1]["optionDesc"]}
          </option>
        );
      } else {
        for (const item of semOptions) {
          ans.push(
            <option key={item["optionValue"]} value={item["optionValue"]}>
              {item["optionDesc"]}
            </option>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSectionOptions = () => {
    const ans = [];
    const { sectionOptions, optionsSec } = this.state;
    try {
      for (const item of sectionOptions) {
        ans.push(
          <span className="flex items-center justify-center mr-4 mb-2">
            <input
              id={item}
              checked={optionsSec.includes(item)}
              onChange={this.getSelectedSections}
              type="checkbox"
              value={item}
              className={`${styles["sec-check"]} mr-2`}
            />
            <label
              key={item}
              htmlFor={item}
              className={`${styles["sec-check-label"]} mt-1`}
            >
              {item}
            </label>
          </span>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderRegOptions = () => {
    const { regOptions } = this.state;

    const ans = [];
    try {
      for (const item of regOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getStructures = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.COURSE_STRUCTURES,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          courseStructure: response["data"]["result"],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { data, optionsSec } = this.state;
      const { item } = this.props;

      if (optionsSec.length > 0) {
        const req = {
          ...data,
          sectionsForCourse: optionsSec.join().replace(/^,|,$/g, ""),
        };
        const options = {
          method: item === null ? "POST" : "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(req),
        };

        const response = await fetch(
          item === null
            ? apiClient.urls.fatcat.OBE_COURSES_MANAGE_COURSES_CREATE_COURSE
            : apiClient.urls.fatcat.OBE_UPDATE_COURSE,
          options
        );

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["statusCode"] === "OK") {
            this.setState({ created: true });
          }
        } else {
          this.setState({
            optionsSec: [],
            sectionOptions: [],
            data: {
              sectionOptions: [],
              sectionsForCourse: "",
              batch: "",
              branch: "",
              courseCode: "",
              courseName: "",
              courseShortName: "",
              courseStructureId: "",
              regulation: "",
              semester: "",
              thValue: 50,
              targetAtt: 65,
            },
          });
        }
      } else {
        alert("Please select atleast one section");
      }

      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  batchChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], batch: event.target.value },
      }),
      this.getSectionOptions
    );
  };

  branchChanged = (event) => {
    this.setState(
      (prev) => ({
        data: { ...prev["data"], branch: event.target.value },
      }),
      this.getSectionOptions
    );
  };

  courseCodeChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], courseCode: event.target.value },
    }));
  };

  courseNameChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], courseName: event.target.value },
    }));
  };

  courseShortNameChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], courseShortName: event.target.value },
    }));
  };

  courseStructureIdChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], courseStructureId: event.target.value },
    }));
  };

  regulationChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], regulation: event.target.value },
    }));
  };

  semesterChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], semester: event.target.value },
    }));
  };

  thValueChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], thValue: event.target.value },
    }));
  };

  targetAttValueChanged = (event) => {
    this.setState((prev) => ({
      data: { ...prev["data"], targetAtt: event.target.value },
    }));
  };

  courseCreated = (event) => {
    event.preventDefault();
    if (navigator.onLine) {
      this.setState({ isLoading: true });
      this.getData();
    }
  };

  okBtn = () => {
    const { item, reload, closeBtn } = this.props;
    item !== null && closeBtn();

    this.setState({
      optionsSec: [],
      sectionOptions: [],
      created: false,
      data: {
        sectionsForCourse: "",
        batch: "",
        branch: "",
        courseCode: "",
        courseName: "",
        courseShortName: "",
        courseStructureId: "",
        regulation: "",
        semester: "",
        thValue: 50,
        targetAtt: 65,
      },
    });

    reload();
  };

  getSelectedSections = (event) => {
    const { optionsSec } = this.state;
    const data = optionsSec;
    try {
      if (optionsSec.includes(event.target.value)) {
        const idx = optionsSec.indexOf(event.target.value);
        data.splice(idx, 1);
      } else {
        data.push(event.target.value);
      }
      this.setState({ optionsSec: data });
    } catch (err) {
      console.log(err);
    }
  };

  structCreateBtn = () => {
    const { newCourseBtn } = this.props;
    newCourseBtn();
  };

  courseCreatedExcel = () => {
    this.setState({ created: true });
  };

  render() {
    const { created, data, courseStructure, isLoading, excelView } = this.state;
    const { item } = this.props;
    return (
      <>
        {created ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              Course {item === null ? "created" : "updated"} successfully!
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : (
          <>
            {excelView ? (
              <ExcelView
                excelEntryBtn={this.excelEntryBtn}
                courseCreatedExcel={this.courseCreatedExcel}
              />
            ) : (
              <form onSubmit={this.courseCreated}>
                <div
                  className={`${styles["course-bg"]} flex flex-row justify-between flex-wrap text-[#636363] bg-[#FFFFFF] mb-5 p-5 pt-0 pb-10`}
                >
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Regulation</label>
                    <select
                      required
                      onChange={this.regulationChanged}
                      value={data.regulation}
                      className={`${styles["filter-select"]} focus:ring-0 p-2`}
                    >
                      <option selected disabled hidden value="">
                        SELECT
                      </option>
                      {this.renderRegOptions()}
                    </select>
                  </span>
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Batch</label>
                    <select
                      required
                      onChange={this.batchChanged}
                      value={data.batch}
                      className={`${styles["filter-select"]} focus:ring-0 p-2`}
                    >
                      <option selected disabled hidden value="">
                        SELECT
                      </option>
                      {this.renderBatchOptions()}
                    </select>
                  </span>
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Branch</label>
                    <select
                      required
                      onChange={this.branchChanged}
                      value={data.branch}
                      className={`${styles["filter-select"]} focus:ring-0 p-2`}
                    >
                      <option selected disabled hidden value="">
                        SELECT
                      </option>
                      {this.renderBranchOptions()}
                    </select>
                  </span>
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Semester</label>
                    <select
                      required
                      onChange={this.semesterChanged}
                      value={data.semester}
                      className={`${styles["filter-select"]} focus:ring-0 p-2`}
                    >
                      <option selected disabled hidden value="">
                        SELECT
                      </option>
                      {this.renderSemOptions()}
                    </select>
                  </span>

                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col mb-2">
                    <label className={styles.label}>Section </label>

                    <span className="flex items-center flex-wrap">
                      {this.renderSectionOptions()}
                    </span>
                  </span>

                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Course Code</label>

                    <input
                      required
                      onChange={this.courseCodeChanged}
                      value={data.courseCode}
                      type="text"
                      className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                      placeholder="ENTER"
                    />
                  </span>
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Course Name</label>

                    <input
                      required
                      onChange={this.courseNameChanged}
                      value={data.courseName}
                      type="text"
                      className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                      placeholder="ENTER"
                    />
                  </span>
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Course Short Name</label>

                    <input
                      required
                      onChange={this.courseShortNameChanged}
                      value={data.courseShortName}
                      type="text"
                      className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                      placeholder="ENTER"
                    />
                  </span>
                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Course Structure</label>
                    <select
                      required
                      onChange={this.courseStructureIdChanged}
                      value={data.courseStructureId}
                      className={`${styles["filter-select"]} focus:ring-0 capitalize p-2`}
                    >
                      <option selected disabled hidden value="">
                        SELECT
                      </option>
                      {courseStructure.map((each) => (
                        <option
                          key={each.courseStructId}
                          value={each.courseStructId}
                        >
                          {each.courseTypeName}
                        </option>
                      ))}
                    </select>

                    {item === null && (
                      <button
                        onClick={this.structCreateBtn}
                        type="button"
                        className={styles["struct-create-btn"]}
                      >
                        <img
                          width={12}
                          src="/assets/plus.svg"
                          alt="add"
                          className="mr-2"
                        />
                        Create course structure
                      </button>
                    )}
                  </span>

                  <span className="lg:w-2/5 mt-1 mr-1 flex flex-col">
                    <label className={styles.label}>Target Attainment</label>

                    <input
                      required
                      onChange={this.targetAttValueChanged}
                      value={data.targetAtt}
                      type="text"
                      className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                      placeholder="65"
                    />
                  </span>
                </div>
                <span className="flex flex-row justify-end items-end mt-10 mb-5">
                  <div className={styles["container"]}>
                    {item === null && (
                      <div className={styles["button-wrap"]}>
                        <button
                          type="button"
                          className={styles["button"]}
                          onClick={this.excelEntryBtn}
                        >
                          Excel Entry
                        </button>
                      </div>
                    )}
                  </div>

                  <button
                    disabled={isLoading}
                    className={`${styles["fac-button"]} ${
                      isLoading && "cursor-not-allowed"
                    }`}
                    type="submit"
                  >
                    {isLoading ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#FFF"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : item === null ? (
                      "Create Course"
                    ) : (
                      "Update Course"
                    )}
                  </button>
                </span>
              </form>
            )}
          </>
        )}
      </>
    );
  }
}

export default CreateCourse;
