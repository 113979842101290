import { Component } from "react";
import styles from "./index.module.css";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { Oval } from "react-loader-spinner";
import ExcelView from "./ExcelView";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import FiltersContext from "../../../../../context/FiltersContext";

class CreateFaculty extends Component {
  state = {
    pwdHidden: true,
    excelView: false,
    isLoading: false,
    created: false,
    branchOptions: [],
    facData: {
      deptName: "",
      designation: "",
      facultyName: "",
      facultyType: "FACULTY",
      facultyUsername: "",
      loginPassword: "",
      workEmail: "",
    },
  };

  static contextType = FiltersContext;

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  addFaculty = async () => {
    const res = await this.checkUsername();

    try {
      const { facData } = this.state;
      const token = Cookies.get("jwt_token");

      const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;
      const encrypted = CryptoJS.AES.encrypt(
        facData["loginPassword"],
        key
      ).toString();

      const facultyData = {
        ...facData,
        loginPassword: encrypted,
      };

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(facultyData),
      };

      if (res) {
        const response = await fetch(
          apiClient.urls.fatcat.OBE_FACULTY_CREATE_FACULTY,
          options
        );

        // console.log(response);

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["statusCode"] === "OK") {
            this.setState({ created: true });
          } else {
            alert(res["description"]);
          }
        } else {
          this.setState({
            facData: {
              deptName: "",
              designation: "",
              facultyId: "",
              facultyName: "",
              loginPassword: "",
              workEmail: "",
            },
          });
        }
      } else {
        alert("Username Not Available");
      }

      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  checkUsername = async () => {
    try {
      const token = Cookies.get("jwt_token");
      const { facData } = this.state;

      const response = await apiClient.get(
        apiClient.urls.fatcat.CHECK_USERNAME +
          `?username=${facData["facultyUsername"]}`,
        {
          Authorization: token,
        }
      );

      if (response["data"]["statusCode"] === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  facultyCreated = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.addFaculty();
  };

  deptChanged = (event) => {
    this.setState((prev) => ({
      facData: { ...prev["facData"], deptName: event.target.value },
    }));
  };

  designationChanged = (event) => {
    this.setState((prev) => ({
      facData: { ...prev["facData"], designation: event.target.value },
    }));
  };

  facultyIdChanged = (event) => {
    this.setState((prev) => ({
      facData: { ...prev["facData"], facultyUsername: event.target.value },
    }));
  };

  facultyNameChanged = (event) => {
    this.setState((prev) => ({
      facData: { ...prev["facData"], facultyName: event.target.value },
    }));
  };

  loginPasswordChanged = (event) => {
    this.setState((prev) => ({
      facData: { ...prev["facData"], loginPassword: event.target.value },
    }));
  };

  mailChanged = (event) => {
    this.setState((prev) => ({
      facData: { ...prev["facData"], workEmail: event.target.value },
    }));
  };

  okBtn = () => {
    this.setState(
      {
        created: false,
        facData: {
          deptName: "",
          designation: "",
          facultyName: "",
          facultyType: "FACULTY",
          facultyUsername: "",
          loginPassword: "",
          workEmail: "",
        },
      },
      this.props.reload
    );
  };

  componentDidMount() {
    if (navigator.onLine) {
      const { filtersBranch } = this.context;

      this.setState({
        branchOptions: filtersBranch,
      });
    }
  }

  excelEntryBtn = () => {
    const { excelView } = this.state;
    this.setState({ excelView: !excelView });
  };

  facultyCreatedExcel = () => {
    this.setState({ created: true });
  };

  render() {
    const { excelView, created, facData, isLoading, pwdHidden } = this.state;

    return (
      <>
        {created ? (
          <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <img
              src="/assets/tick.svg"
              alt="new course"
              width={34}
              height={34}
            />
            <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
              Faculty created successfully!
            </h1>
            <button
              onClick={this.okBtn}
              className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
              type="button"
            >
              Ok
            </button>
          </div>
        ) : excelView ? (
          <ExcelView
            excelEntryBtn={this.excelEntryBtn}
            facultyCreatedExcel={this.facultyCreatedExcel}
          />
        ) : (
          <form onSubmit={this.facultyCreated}>
            <div className="flex flex-row justify-start flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
              <span className="w-2/5 mt-1 mr-2 flex flex-col">
                <label className={styles.labels}>Faculty ID</label>

                <input
                  required
                  onChange={this.facultyIdChanged}
                  value={facData.facultyUsername}
                  type="text"
                  placeholder="ENTER"
                  className={`${styles["filter-select"]} focus:ring-0 w-5/6 p-2`}
                />
              </span>
              <span className="w-2/5 mt-1 mr-1 flex flex-col">
                <label className={styles.labels}>Designation</label>
                <input
                  required
                  onChange={this.designationChanged}
                  value={facData.designation}
                  type="text"
                  placeholder="ENTER"
                  className={`${styles["filter-select"]} focus:ring-0 w-5/6 p-2`}
                />
              </span>
              <span className="w-2/5 mt-3 mr-2 flex flex-col">
                <label className={styles.labels}>Faculty Name</label>
                <input
                  required
                  onChange={this.facultyNameChanged}
                  value={facData.facultyName}
                  type="text"
                  placeholder="ENTER"
                  className={`${styles["filter-select"]} focus:ring-0 w-5/6 p-2`}
                />
              </span>
              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Faculty Department</label>
                <select
                  required
                  onChange={this.deptChanged}
                  value={facData.deptName}
                  className={`${styles["filter-txt"]} focus:ring-0 p-2`}
                >
                  <option selected disabled hidden value="">
                    SELECT
                  </option>
                  {this.renderBranchOptions()}
                </select>
              </span>

              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Login Password</label>
                <div className={styles["input-bg"]}>
                  <input
                    autoComplete="off"
                    required
                    onChange={this.loginPasswordChanged}
                    value={facData.loginPassword}
                    type={pwdHidden ? "password" : "text"}
                    placeholder="ENTER"
                    className={`${styles["input"]} focus:ring-0 w-5/6 p-2`}
                  />
                  <button
                    onClick={() => {
                      this.setState({ pwdHidden: !pwdHidden });
                    }}
                    type="button"
                  >
                    {pwdHidden ? (
                      <IoEyeSharp className="hide-icon" />
                    ) : (
                      <IoEyeOffSharp className="hide-icon" />
                    )}
                  </button>
                </div>
              </span>

              <span className="w-2/5 mt-3 mr-1 flex flex-col">
                <label className={styles.labels}>Email Id</label>
                <input
                  required
                  onChange={this.mailChanged}
                  value={facData.workEmail}
                  type="email"
                  placeholder="EMAIL"
                  className={`${styles["filter-select"]} focus:ring-0 w-5/6 p-2`}
                />
              </span>
            </div>

            <span className="flex flex-row justify-end items-end mt-10 mb-5">
              <div className={styles["container"]}>
                <div className={styles["button-wrap"]}>
                  <button
                    className={styles["button"]}
                    onClick={this.excelEntryBtn}
                  >
                    Excel Entry
                  </button>
                </div>
              </div>

              <button
                disabled={isLoading}
                className={`${styles["fac-button"]} ${
                  isLoading && "cursor-not-allowed"
                }`}
                type="submit"
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Create Faculty"
                )}
              </button>
            </span>
          </form>
        )}
      </>
    );
  }
}

export default CreateFaculty;
