import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const MissionPeoMatrix = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const renderMissionHeaders = () => {
    const missionHeaders = metricInfo.mission.map((each) => (
      <th id={each.mission_id}>Mission {each.mission_no}</th>
    ));

    return missionHeaders;
  };

  const renderPeoMission = () => {
    const peoMission = metricInfo.peo.map((each) => (
      <tr>
        <td className={styles["peo-statement"]}>{each.peo_statement}</td>

        {metricInfo.mission.map((item) => (
          <td>
            <select
              id={`peo${each.peo_id}Mission${item.mission_id}`}
              defaultValue={
                metricInfo["mission-peo-matrix-table"]?.[
                  `peo${each?.peo_id}`
                ]?.[`mission${item?.mission_id}`] ?? ""
              }
              className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
            >
              <option value="">-</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </td>
        ))}
      </tr>
    ));

    return peoMission;
  };

  const submitted = async () => {
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    metricInfo.peo.forEach((each) => {
      metricInfo.mission.forEach((item) => {
        let dataValue =
          document.getElementById(`peo${each.peo_id}Mission${item.mission_id}`)
            ?.value ?? "";

        reqBody.push({
          peoId: each.peo_id,
          missionId: item.mission_id,
          level: dataValue,
        });
      });
    });

    const options = {
      method:
        metricInfo["mission-peo-matrix-table"].length === 0 ? "POST" : "PUT",
      headers,
      body: JSON.stringify({ data: reqBody }),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderEmptyView = () => {
    const bothErr =
      "Mission Statements in metric 1.1.1 and PEO Statements in metric 1.1.2 needs to be uploaded to view or modify this metric.";

    const missionErr =
      "Mission Statements in metric 1.1.1 needs to be uploaded to view or modify this metric.";

    const peoErr =
      "PEO Statements in metric 1.1.2 needs to be uploaded to view or modify this metric.";

    return (
      <div className="flex justify-center items-center p-10 mt-20 mb-5">
        <p className={styles["empty-view"]}>
          {metricInfo["mission"].length === 0 && metricInfo["peo"].length === 0
            ? bothErr
            : metricInfo["mission"].length === 0
            ? missionErr
            : peoErr}
        </p>
      </div>
    );
  };

  return (
    <div className="p-2 pr-0 flex flex-col">
      {metricInfo["mission"].length === 0 || metricInfo["peo"].length === 0 ? (
        renderEmptyView()
      ) : (
        <>
          <table className={styles["peo-mission-table"]}>
            <tr>
              <th></th>
              {renderMissionHeaders()}
            </tr>

            {renderPeoMission()}
          </table>

          <button
            className={styles["submit-btn"]}
            type="button"
            onClick={submitted}
          >
            Submit
          </button>
        </>
      )}
    </div>
  );
};

export default MissionPeoMatrix;
