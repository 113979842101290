import { Component } from "react";
import FacultyList from "./FacultyList";
import { Link, withRouter } from "react-router-dom";
import styles from "./index.module.css";
import { RxCross2 } from "react-icons/rx";
import CreateFaculty from "./CreateFaculty";
import FacultyCourseMapping from "./FacultyCourseMapping";
import EditFaculty from "./EditFaculty";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import FacultyMappingDetails from "./FacultyMappingDetails";
import * as jose from "jose";
import SearchContext from "../../../../context/SearchContext";

class ManageFaculty extends Component {
  state = {
    isOpened: false,
    active: 1,
    facId: null,
    facNumId: null,
    user: "",
    facultyInfo: [],
    branch: "",
    branchOptions: [],
    semOptions: [],
    content: "loading",
    userType: "",
    loader: true,
  };

  static contextType = SearchContext;

  facDel = () => {
    this.getData();
  };

  getData = async () => {
    this.setState({ loader: true });
    const token = Cookies.get("jwt_token");
    try {
      const { branch } = this.state;

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_DETAILS +
          `?branch=${branch}&facultyType=`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          facultyInfo: response["data"]["result"],
          content: true,
        });
      } else {
        this.setState({ content: false, facultyInfo: [] });
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ loader: false });
  };

  componentDidMount() {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);

        if (claim["user_type"] === "HOD") {
          this.setState(
            {
              userType: claim["user_type"],
              branch: claim["dept_name"],
            },
            this.getData
          );
        } else {
          this.setState({ userType: claim["user_type"] }, this.getData);
        }
        this.getOptions();
      }
    }
  }

  branchChanged = (event) => {
    this.setState({ branch: event.target.value }, this.getData);
  };

  createFacultyBtn = () => {
    this.setState({ active: 1 });
  };

  // mappingBtn = () => {
  //   this.setState({ active: 2 });
  // };

  closeBtn = () => {
    this.setState({ isOpened: false, active: 1 });
  };

  openBtn = () => {
    this.setState({ isOpened: true, active: 1 });
  };

  openBtnMap = () => {
    // this.setState({ isOpened: true, active: 2 });
    const { history } = this.props;
    history.push("/obe/faculty/faculty-course");
  };

  editFaculty = (id) => {
    this.setState({ isOpened: true, active: 3, facId: id });
  };

  mappingDetails = (id, user, numId) => {
    this.setState({
      isOpened: true,
      active: 4,
      facId: id,
      user: user,
      facNumId: numId,
    });
  };

  preventBubbling = (event) => {
    event.stopPropagation();
  };

  renderView = () => {
    try {
      const { active, facId, facultyInfo, facNumId } = this.state;

      const ans = facultyInfo.filter((each) => each.facultyId === facId);

      switch (active) {
        case 1:
          return (
            <CreateFaculty reload={this.getData} closeBtn={this.closeBtn} />
          );
        case 2:
          return <FacultyCourseMapping reload={this.getData} />;
        case 3:
          return (
            <EditFaculty
              data={ans[0]}
              reload={this.getData}
              closeBtn={this.closeBtn}
            />
          );
        case 4:
          return (
            <FacultyMappingDetails
              closeButton={this.closeBtn}
              facNumId={facNumId}
              id={facId}
              fetchData={this.getData}
            />
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_FACULTY`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          branchOptions: response["data"]["result"],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getOptions = async () => {
    try {
      await this.getBranchOptions();
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderInitialView = () => {
    const { facultyInfo, content } = this.state;
    const { search } = this.context;
    try {
      switch (content) {
        case true:
          const filteredFaculty = facultyInfo.filter(
            (each) =>
              each.facultyName !== null &&
              each.facultyName.toLowerCase().includes(search.toLowerCase())
          );

          return filteredFaculty.map((item) => (
            <FacultyList
              key={item.id}
              data={item}
              facDel={this.facDel}
              edit={this.editFaculty}
              mapDetails={this.mappingDetails}
            />
          ));
        case false:
          return <p className="text-center mt-5 text-red-400">No Data Found</p>;

        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { isOpened, active, facultyInfo, branch, user, userType, loader } =
      this.state;
    const { match } = this.props;
    const { path } = match;

    return (
      <div className={styles["faculty-obe"]}>
        <div
          onClick={this.closeBtn}
          className={isOpened ? styles.overlay : "hidden"}
        >
          <div
            onClick={this.preventBubbling}
            className={styles["overlay-card"]}
          >
            <span className="flex items-center justify-between">
              <h1 className={`${styles.heading} capitalize`}>
                {active === 4
                  ? `${user}. Course Details`
                  : active === 3
                  ? "Edit Faculty Details"
                  : "Enter Faculty Details"}
              </h1>

              <RxCross2
                onClick={this.closeBtn}
                size={26}
                className="cursor-pointer text-[#456bf1]"
              />
            </span>

            <div
              className={`bg-[#FFFFFF] p-5 ${
                active === 4 ? "mt-0 pt-0" : "mt-5"
              }`}
            >
              {active !== 4 && active !== 3 && (
                <span className="flex flex-row">
                  <button
                    onClick={this.createFacultyBtn}
                    type="button"
                    className={`hover:text-[#3f4451] ${
                      active === 1
                        ? `${styles["label-active"]} underline mr-10`
                        : `mr-10 ${styles["label"]}`
                    } mb-5`}
                  >
                    <span
                      className={`${
                        active === 1
                          ? "bg-blue-600 text-white"
                          : "bg-transparent text-black border border-black"
                      } mr-3 px-2 py-0.5 rounded-3xl font-normal`}
                    >
                      1
                    </span>
                    Create Faculty
                  </button>
                  {/* <button
                    onClick={this.mappingBtn}
                    type="button"
                    className={`hover:text-[#3f4451] ${
                      active === 2
                        ? `${styles["label-active"]} underline mr-10`
                        : `mr-10 ${styles["label"]}`
                    } mb-5`}
                  >
                    <span
                      className={`${
                        active === 2
                          ? "bg-blue-600 text-white"
                          : "bg-transparent text-black border border-black"
                      } mr-3 px-2 py-0.5 rounded-3xl font-normal`}
                    >
                      2
                    </span>
                    Faculty-Course Mapping
                  </button> */}
                </span>
              )}
            </div>

            {this.renderView()}
          </div>
        </div>

        <div className="flex flex-row items-center mb-5 ml-8">
          <Link to="/obe/faculty/analysis">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/faculty/analysis"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-4 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              ANALYSIS
            </h1>
          </Link>
          <Link to="/obe/faculty">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/faculty" ? "font-bold underline" : "font-medium"
              } ml-10 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              MANAGE FACULTY
            </h1>
          </Link>

          <Link to="/obe/faculty/faculty-course">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/faculty/faculty-course"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-10 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              FACULTY-COURSE MAPPING
            </h1>
          </Link>
        </div>

        <div className={`${styles["dashboard-periodwisebar-bg"]} mb-10`}>
          <div className="flex flex-row justify-between  mb-5">
            <h1 className="ml-3 font-bold text-[#606981] mt-1.5">
              TOTAL FACULTY : {facultyInfo.length}
            </h1>
            <div className="hidden md:flex flex-row justify-center items-start font-medium">
              {userType !== "HOD" && (
                <select
                  value={branch}
                  onChange={this.branchChanged}
                  className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mr-5 p-2 pr-5`}
                >
                  <option value="">All Branches</option>
                  {this.renderBranchOptions()}
                </select>
              )}
              <span className="flex flex-row items-center bg-[#F4F9FF] rounded-3xl pr-2 pl-2">
                <button
                  onClick={this.openBtn}
                  type="button"
                  className="ml-2 mr-2 text-blue-600 flex flex-row items-center bg-[#F4F9FF] rounded-3xl"
                >
                  <span className="mr-2 pt-1">
                    <img
                      src="/assets/new.svg"
                      alt="new course"
                      width={20}
                      height={18}
                    />
                  </span>
                  Create New Faculty
                </button>

                <img
                  src="/assets/line.svg"
                  alt="new course"
                  width={1}
                  height={0.5}
                />

                <button
                  onClick={this.openBtnMap}
                  type="button"
                  className="ml-2 mr-3 text-blue-600 flex flex-row items-center bg-[#F4F9FF] rounded-3xl "
                >
                  <span className="mr-2 pt-1">
                    <img
                      src="/assets/faculty.svg"
                      alt="new course"
                      width={22}
                      height={22}
                    />
                  </span>
                  Faculty-Course Mapping
                </button>
              </span>
            </div>
          </div>

          <div className="font-Lato-normal font-bold text-base flex flex-row text-[#8F949F] mt-10 mb-5 pl-6">
            <p
              className={`${styles["table-labels"]} w-1/4 md:w-2/12 md:mr-4 flex flex-row justify-start`}
            >
              Faculty ID
            </p>
            <p
              className={`${styles["table-labels"]} w-1/4 md:w-1/12 md:mr-4 flex flex-row justify-start`}
            >
              Name
            </p>
            <p
              className={`${styles["table-labels"]} w-1/12 ml-16 flex flex-row justify-start`}
            >
              Designation
            </p>
            <p
              className={`${styles["table-labels"]} w-2/12 ml-8 flex flex-row justify-start`}
            >
              Contact Info
            </p>
            <p
              className={`${styles["table-labels"]} w-2/12 flex flex-row justify-start`}
            >
              Courses assigned
            </p>
          </div>

          {loader ? (
            <div className="flex justify-center mt-10">
              <Oval
                height={50}
                width={50}
                color="#3d65f4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3d65f4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <ul className={`${styles.list} list-none mb-1`}>
              {this.renderInitialView()}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ManageFaculty);
