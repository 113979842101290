import React from "react";

const FiltersContext = React.createContext({
  filtersBatch: [],
  filtersBranch: [],
  filtersSem: [],
  updateFiltersBatch: () => {},
  updateFiltersBranch: () => {},
  updateFiltersSem: () => {},
});

export default FiltersContext;
