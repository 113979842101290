import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiSolidUser } from "react-icons/bi";
import { HiLockClosed } from "react-icons/hi";
import CryptoJS from "crypto-js";
import apiClient from "../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as jose from "jose";
import { Oval } from "react-loader-spinner";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import "./index.css";

const userData = {
  email: "",
  password: "",
};

const LoginForm = (props) => {
  const [userInput, updateUserInput] = useState(userData);
  const [disable, updateDisable] = useState(true);
  const [credentials, updateCredentials] = useState(false);
  const [isLoading, updateLoading] = useState(false);
  const [pwdHidden, updatePwdHidden] = useState(true);

  useEffect(() => {
    validDetails();
  }, [userInput]);

  useEffect(() => {
    const token = Cookies.get("jwt_token");
    const { history } = props;
    if (token !== undefined) {
      history.replace("/obe/dashboard");
    }
  }, []);

  const validDetails = () => {
    if (userInput.email !== "" && userInput.password !== "") {
      updateDisable(false);
    } else {
      updateDisable(true);
    }
  };
  const valueChanged = (event) => {
    const data = event.target.value;
    const inputName = event.target.name;

    switch (inputName) {
      case "email":
        updateUserInput({ ...userInput, email: data });
        break;
      case "password":
        updateUserInput({ ...userInput, password: data });
        break;
      default:
        break;
    }
  };

  const formSubmitted = async (event) => {
    updateCredentials("");

    event.preventDefault();
    updateLoading(true);
    const { history } = props;

    const userCredentials = userInput.email + "," + userInput.password;

    const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;

    const encrypted = CryptoJS.AES.encrypt(userCredentials, key).toString();

    const headers = {
      Authorization: `Bearer ${encrypted}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.SIGN_IN_URL,
        headers
      );

      if (response.headers.authorization !== undefined) {
        updateCredentials("");

        const currentDate = new Date();
        const expiryDate = new Date(
          currentDate.getTime() + 23 * 60 * 60 * 1000
        ); // Added 23 hours to the current date
        const jwt_token = response.headers.authorization.split(" ")[1];

        const claims = jose.decodeJwt(jwt_token);
        claims["user_type"] !== "FACULTY"
          ? history.replace("/obe/courses/managecourses")
          : history.replace("/faculty/dashboard/managecourses");

        Cookies.set("jwt_token", jwt_token, { expires: expiryDate });
      } else {
        updateCredentials("Invalid user id or password");
      }
    } catch {
      updateCredentials("Invalid user id or password");
    }

    updateUserInput(userData);
    updateDisable(true);
    updateLoading(false);
  };

  const loginBtn = disable ? "login-btn-disabled" : "login-btn";

  return (
    <div className="login-page">
      <div className="span-container">
        <div className="form-container">
          <div className="logo">
            <img
              alt="logo"
              src="/assets/logo.svg"
              height={270}
              width={300}
              layout="intrinsic"
              priority
            />
          </div>
          <span className="text">
            <img
              src="/assets/fatcat-title.svg"
              width={200}
              height={45}
              alt=""
            />
            <p className="para">Empowering Educational Technology.</p>
            <button type="button" className="read-more-btn">
              Read More
            </button>
          </span>

          <span className="ellip">
            <img
              src="/assets/ellipse.svg"
              width={200}
              height={150}
              alt="ellipse"
            />
          </span>
        </div>

        <form className="login-form" onSubmit={formSubmitted}>
          <h1 className="greet-heading">Hello Again!</h1>
          <p className="paragraph">Welcome Back</p>

          <div className="input-bg">
            <BiSolidUser className="icons" size={30} />
            <input
              autoComplete="off"
              name="email"
              onChange={valueChanged}
              value={userInput.email}
              className="input focus:ring-0"
              type="text"
              placeholder="User Id"
            />
          </div>

          <div className="input-bg">
            <HiLockClosed className="icons" />
            <input
              autoComplete="off"
              name="password"
              value={userInput.password}
              onChange={valueChanged}
              className="input focus:ring-0"
              type={pwdHidden ? "password" : "text"}
              placeholder="Password"
            />
            <button
              onClick={() => {
                updatePwdHidden(!pwdHidden);
              }}
              type="button"
            >
              {pwdHidden ? (
                <IoEyeSharp className="hide-icon" />
              ) : (
                <IoEyeOffSharp className="hide-icon" />
              )}
            </button>
          </div>
          {credentials !== "" && (
            <p className="error text-red-500">{credentials}</p>
          )}
          <button
            disabled={disable || isLoading}
            type="submit"
            className={`${loginBtn} ${isLoading && "cursor-not-allowed"}`}
          >
            {isLoading ? (
              <Oval
                height={20}
                width={20}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#FFF"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Login"
            )}
          </button>
          <Link to="/login/forgot-password">
            <button type="button" className="forgot">
              Forgot password
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
